
import { Text2 } from 'components/design-system'
import { IFormData } from '../..'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { toast } from 'components/design-system/Toast/manager'
import { uploadFileClassPlan } from 'services/classPlan/classPlanService'
import { InputComponent } from 'components/common'

interface ISelectCloeMaterialPage {
  stepIndex: number
  formData: IFormData
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>
}

export const PersonalMaterialsPage = ({ stepIndex, formData, setFormData }: ISelectCloeMaterialPage) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleUploadFile = async (files: File[]) => {
    try {
      if (!files.length) return false

      const file = files[0]

      const response = await uploadFileClassPlan(file)

      if (!response.success) throw new Error()

      const fileId = response.data.fileId

      setFormData(old => ({
        ...old,
        personalMaterial: fileId,
        files: files
      }))

      toast.handler({
        content: t('Arquivo processado com sucesso!'),
        duration: 1000,
        severity: 'success'
      })
      return true
    } catch (err: any) {
      toast.handler({
        content: t('Não foi possivel enviar o arquivo.'),
        duration: 1000,
        severity: 'error'
      })
      return false
    }
  }

  const cleanSelectedFiles = (index: number) => {
    setFormData(old => ({
      ...old,
      personalMaterial: undefined,
      files: []
    }))
  }

  return (
    <div className={classes.container}>
      <Text2 fontSize='md' fontWeight='semibold' colorbrand='medium'>
        {t('Passo 4: Inclusão de materiais próprios (opcional)')}
      </Text2>

      <div className={classes.fieldsContainer}>
        <InputComponent
          initialFiles={formData?.files}
          handleUploadFiles={handleUploadFile}
          onRemoveFile={cleanSelectedFiles}
          availableArchivesTypes='.pdf,.doc,.docx'
          description={t('classPlanFileFormats', { joinArrays: '\n' })}
          maxFileSize={15}
          multiple={false}
          dataTestid={'cloe_super_create_lesson_plan_step4_select_file'}
        />
      </div>
    </div>
  )
}
