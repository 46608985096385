import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { addTransparencyToColor } from 'utils/colors'

export default makeStyles((theme: Theme) => createStyles({
  formInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.nano,
    width: '100%'
  },
  dropdownContainer: {
    minWidth: '50px',
    flex: 1,
    width: '100%',

    '& .selectedText': {
      fontSize: `${theme.font.fontSize.sm} !important`
    },
    [theme.breakpoints.down('md')]: {
      flex: 0
    }
  },
  selectedText: {
    fontWeight: `${theme.font.fontWeight.regular} !important`,
    color: `${theme.colors.neutral.darkBase} !important`
  },
  container: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    padding: `0 ${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,

    '& .currentPeriodsContainer': {
      color: theme.colorBrand.medium
    },
    '& .previousPeriods': {
      color: theme.colors.contentType.default
    }
  },
  optionContainer: {
    cursor: 'pointer',
    fontWeight: `${theme.font.fontWeight.medium} !important`,
    fontSize: theme.font.fontSize.sm,
    color: `${theme.colors.neutral.darkBase} !important`,

    margin: `${theme.spacingStack.nano} 0`,

    boxSizing: 'content-box',
    borderRadius: theme.borderRadius.sm,
    border: `2px solid ${theme.colors.neutral.light10}`,
    padding: `${theme.spacingInline.nano}`,
    backgroundColor: theme.colors.neutral.lightBase,

    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    wordWrap: 'break-word',

    '&.active': {
      border: `2px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);',
      color: `${theme.colorBrand.medium} !important`
    },
    '&.disabled': {
      color: `${theme.colors.neutral.dark40} !important`
    },
    '&.focused': {
      border: `2px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    },
    '&:hover': {
      transition: 'all ease-in-out 55ms',
      border: `2px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    }
  },
  inputField: {
    '& div': {
      width: '100%',
    },

    '&:not([class ~= "warning"])': {
      '& .MuiInputBase-input': {
        border: 'none  !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiFormHelperText-root': {
        color: theme.colors.neutral.dark40
      }
    },
    '& .MuiInputBase-input': {
      background: addTransparencyToColor(theme.colors.neutral.light10, 0.32),
      borderRadius: theme.borderRadius.sm,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '16px !important',
      marginRight: '16px !important',
      fontStyle: 'italic !important'
    }
  }
}))
