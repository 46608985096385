import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,
    alignItems: 'center',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      width: '712px'
    }
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm
  },
  inputFields: {
    '& .selectedContainer': {
      width: '100%'
    },

    // '&.gradeInputField .selectedContainer': {
    //   width: '50% !important'
    // },
    // '&.durationInputField .selectedContainer': {
    //   width: '49% !important'
    // },
    // '&.disciplineInputField .selectedContainer': {
    //   width: '49% !important'
    // },

    [theme.breakpoints.up('md')]: {
      '&.gradeInputField .selectedContainer': {
        width: '192px !important'
      },
      '&.durationInputField .selectedContainer': {
        width: '192px !important'
      },
      '&.disciplineInputField .selectedContainer': {
        width: '280px !important'
      }
    }
  },
  fieldsContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.xxs,

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }

  },
  halfSizeForm: {
    width: `calc(50% - ${theme.spacingInline.xxxs}) !important`
  }
}))
