// eslint-disable @typescript-eslint/prefer-nullish-coalescing
import React, { createRef, useEffect, useMemo, useState } from 'react'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Search, Text2 } from 'components/design-system'
import useStyle from './style'
import { EmptyStateComponent, Loading, StatusSelector } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { IClassesTaught } from 'services/types/taught-disciplines'
import { Grades, Segments } from 'components/store'
import { ReactComponent as CloeAvatar } from 'assets/cloe-avatar-with-files-stand-up.svg'
import { DownloadOutlined } from '@mui/icons-material'
import { CloeCardSkeleton, ProgramCloeCard } from '../ProgramCloeCard'
import { getProgramCloeContentsPdf, getProgramCloeContentUnit } from 'services/content-unit'
import { ICloeProgramResponse, IDisciplineResponse, IDisciplineStore } from 'services/types'
import { cloneObject } from 'utils/array'
import Analytics from 'utils/analytics'
import { useAtom } from 'jotai'
import { searchPhraseAtom } from './atomStore'
import { pdfjs } from 'react-pdf'
import { toast } from 'components/design-system/Toast/manager'
import { extractFilename } from 'utils/files'
import { IGrades, useGradeDisciplines } from 'services/segments'
import { Disciplines } from 'components/common/Disciplines'
import { useStore } from 'store'
import { IGradeTypeEnum } from 'services/types/grade'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

enum ComponentState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  READY = 'READY'
}

export const ProgramCloeView: React.FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { profile, session, schoolPeriod, subscription } = useStore()
  const searchRef = createRef<any>()
  const STATUS_OPTIONS = {
    ASC: {
      title: t('Ordem crescente')
    },
    DESC: {
      title: t('Ordem decrescente')
    }
  }
  const pedagogicMapLink = 'https://mapapedagogico.cloeapp.com/'

  const [segmentSelected, setSegmentSelected] = useState<number>()
  const [gradeSelected, setGradeSelected] = useState<IGrades>()
  const [disciplineSelected, setDisciplineSelected] = useState<IDisciplineResponse | IDisciplineStore>()

  const [contentUnit, setContentUnit] = useState<ICloeProgramResponse[]>([])
  const [originalContent, setOriginalContent] = useState<ICloeProgramResponse[]>([])
  const [stateMachine, setStateMachine] = useState<ComponentState>(ComponentState.LOADING)
  const [classesTaught, setClassesTaught] = useState<IClassesTaught | null>(null)
  const [sortOption, setSortOption] = useState('ASC')
  const [searchPhrase, setSearchPhrase] = useAtom(searchPhraseAtom)

  const isEM_IT = segmentSelected === IGradeTypeEnum.EM_IT

  const {
    segments,
    grades: allGrades,
    disciplines
  } = useGradeDisciplines({ grade: gradeSelected })

  const grades = useMemo(() => {
    return allGrades.filter(g => g.segment === segmentSelected)
  }, [allGrades, segments, segmentSelected])

  useEffect(() => {
    if (!segmentSelected && segments.length) {
      setSegmentSelected(segments[0].id)
    }
  }, [segments])

  useEffect(() => {
    if (segmentSelected && grades.length) {
      setGradeSelected({ ...grades[0] })
    }
  }, [segmentSelected])

  useEffect(() => {
    if (gradeSelected && disciplines.length) {
      setDisciplineSelected({ ...disciplines[0] })
    }
  }, [disciplines])

  useEffect(() => {
    if (gradeSelected && disciplineSelected && segmentSelected) {
      getContentUnit()
      findTaughtClasses()
    }
  }, [disciplineSelected])

  useEffect(() => {
    if (contentUnit?.length) {
      setStateMachine(ComponentState.READY)
    }
  }, [contentUnit, sortOption])

  useEffect(() => {
    setStateMachine(ComponentState.LOADING)

    const searchSubscriptionFromProfile = session?.subscriptions.find(cur => cur?.user_school_profile?.id && profile?.id && cur.user_school_profile.id === profile.id)

    if (!profile || !session || !schoolPeriod || !searchSubscriptionFromProfile) {
      setStateMachine(ComponentState.ERROR)
    }
  }, [profile, session, schoolPeriod])

  const getContentUnit = async () => {
    setStateMachine(ComponentState.LOADING)
    setSearchPhrase('')
    searchRef?.current?.clear()
    const currDiscipline = disciplineSelected?.code === 'ALL' ? 0 : disciplineSelected?.id
    const response = await getProgramCloeContentUnit(schoolPeriod?.school ?? 0, segmentSelected ?? 0, Number(gradeSelected?.id) ?? 0, Number(currDiscipline))
    if (response.success) {
      if (response.data.data.length) {
        if (sortOption === 'DESC') {
          const sortedOriginal = response.data.data.sort((a, b) => {
            return b.suggested_position - a.suggested_position
          })
          setContentUnit(sortedOriginal)
          setOriginalContent(sortedOriginal)
        } else {
          setContentUnit(response.data.data)
          setOriginalContent(response.data.data)
        }
      }
    } else {
      setStateMachine(ComponentState.ERROR)
    }
  }

  const findTaughtClasses = () => {
    const segment = segments.find(item => item.id === segmentSelected)
    if (segment) {
      const classes = session?.subscriptions.filter(s =>
        s.class.school_period === schoolPeriod?.id &&
        s.class.grades.find(g => g.id === gradeSelected?.id) &&
        s.taught_disciplines.find(t => t.id === disciplineSelected?.id))

      if (classes) {
        setClassesTaught({
          segmentId: segment.id,
          segmentCode: segment.code,
          gradeId: Number(gradeSelected?.id),
          gradeName: gradeSelected?.name ?? '',
          classes: classes.map(c => ({
            id: c.class.id,
            name: c.class.name
          }))
        })
      } else {
        setClassesTaught(null)
      }
    }
  }

  const handlePDFDownload = async () => {
    const currDiscipline = disciplineSelected?.code === 'ALL' ? 0 : disciplineSelected?.id
    const response = await getProgramCloeContentsPdf(schoolPeriod?.school ?? 0, segmentSelected ?? 0, Number(gradeSelected?.id) ?? 0, Number(currDiscipline))
    if (!response.success) {
      toast.handler({
        content: t('Não foi possivel baixar o PDF, tente novamente mais tarde.'),
        duration: 5000,
        severity: 'error'
      })

      Analytics.recordEventClick({
        name: 'program_cloe_download_error',
        attributes: {
          ...profile?.analytics,
          ...subscription?.analytics
        }
      })
      return
    }

    const contentDisposition = response.headers?.['content-disposition']
    const filename = extractFilename(contentDisposition)
    const blob = new Blob([response.data], { type: 'application/pdf' })

    const downloadUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.type = '_blank'
    link.href = downloadUrl
    link.download = filename === 'download.pdf' ? 'programa.pdf' : filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // Cleanup
    window.URL.revokeObjectURL(downloadUrl)

    Analytics.recordEventClick({
      name: 'program_cloe_download_success',
      attributes: {
        ...profile?.analytics,
        ...subscription?.analytics
      }
    })
  }

  const search = (pattern: string) => {
    if (!pattern) return

    let analyticsTerm = 'search_term_found'

    setStateMachine(ComponentState.LOADING)
    setSearchPhrase(pattern)

    const newContentsUnits = cloneObject(originalContent.length && originalContent).filter(
      (cur: ICloeProgramResponse) => {
        if (shoetest.test(pattern, cur.title)) return true

        if (
          (cur?.key_words ?? []).some(keyWord =>
            shoetest.test(pattern, keyWord)
          )
        ) {
          return true
        }

        if (
          (cur?.grouped_knowledge ?? []).some(knowledgeArea => {
            if (shoetest.test(pattern, knowledgeArea.knowledge_area)) return true

            if (
              (knowledgeArea.knowledge_subarea ?? []).some(subarea => {
                if (shoetest.test(pattern, subarea.name)) return true

                if (
                  (subarea.didactic_contents ?? []).some(content =>
                    shoetest.test(pattern, content.code) || shoetest.test(pattern, content.name)
                  )
                ) {
                  return true
                }

                return false
              })
            ) {
              return true
            }

            return false
          })
        ) {
          return true
        }

        if (
          (cur.triad ?? []).some(content =>
            shoetest.test(pattern, content.text) || shoetest.test(pattern, content.title)
          )
        ) {
          return true
        }

        if (
          (cur?.skillTaxonomies.bnccSkill ?? []).some(bnccSkill =>
            shoetest.test(pattern, bnccSkill.code) || shoetest.test(pattern, bnccSkill.name)
          )
        ) {
          return true
        }

        if (
          (cur?.skillTaxonomies.skills ?? []).some(skills =>
            shoetest.test(pattern, skills.code) || shoetest.test(pattern, skills.name)
          )
        ) {
          return true
        }

        return false
      }
    )

    setContentUnit(newContentsUnits)
    if (!newContentsUnits.length) {
      analyticsTerm = 'search_term_not_found'
      setStateMachine(ComponentState.NOT_FOUND)
    }

    Analytics.recordEventClick({
      name: analyticsTerm,
      attributes: {
        ...profile?.analytics,
        search_term: pattern
      }
    })
  }

  const clear = () => {
    if (!searchPhrase) return
    setStateMachine(ComponentState.LOADING)
    setSearchPhrase('')
    setContentUnit(originalContent)
  }

  const handleSortContent = (option: string) => {
    setSortOption(option)
    const sortedOriginal = originalContent.sort((a, b) => {
      if (option === 'DESC') {
        return b.suggested_position - a.suggested_position
      }
      return a.suggested_position - b.suggested_position
    })
    const sortedContent = contentUnit.sort((a, b) => {
      if (option === 'DESC') {
        return b.suggested_position - a.suggested_position
      }
      return a.suggested_position - b.suggested_position
    })
    setOriginalContent(sortedOriginal)
    if (!sortedContent.length) return
    setStateMachine(ComponentState.LOADING)
    setContentUnit(sortedContent)
  }

  const NotFoundState = () => (
    <>
      <Grid container alignItems='center' justifyContent='space-between' paddingBottom={theme.spacingStack.md}>
        <EmptyStateComponent
          ErrorAvatar={<CloeAvatar />}
        >
          <Grid container flexDirection='column' alignItems='center'>
            <Text2
              fontSize='sm'
              fontWeight='medium'
              lineHeight='xs'
              mobile='sm'
              customColor={theme.colors.neutral.dark30}
            >
              {t('O termo ')} <span style={{ color: theme.colorBrand.medium, fontWeight: theme.font.fontWeight.bold }}>{searchPhrase}</span> {t(' não foi encontrado.')}
            </Text2>
            <Text2
              fontSize='sm'
              fontWeight='medium'
              lineHeight='xs'
              mobile='sm'
              customColor={theme.colors.neutral.dark30}
            >
              {t('Você pode tentar outros termos ou verificar se houve algum erro de digitação.')}
            </Text2>
          </Grid>
        </EmptyStateComponent>
      </Grid>
    </>
  )

  const renderStates = {
    IDLE: <></>,
    LOADING: <> <CloeCardSkeleton /> <CloeCardSkeleton /> <CloeCardSkeleton /> <CloeCardSkeleton /> </>,
    ERROR: <Grid container alignItems='center' justifyContent='center' ><BusinessError error={t('Não foi possível exibir o conteúdo')?.toString()} /></Grid>,
    NOT_FOUND: <NotFoundState />,
    READY: contentUnit ? <ProgramCloeCard contentUnit={contentUnit} classesTaught={classesTaught} /> : <></>
  }

  if (!segments.length || !grades.length || !disciplines.length) {
    return (
      <Grid container className={classes.containerWrapper}>
        <div style={{ width: '100%' }}><Loading type='linear' /></div>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.containerWrapper}>
      <Grid container>
        <Grid container direction='column'>
          <Text2
            fontSize='xl'
            fontWeight='semibold'
            lineHeight='md'
            mobile='lg'
            customColor={theme.colorBrand.dark}
            sx={{ marginBottom: theme.spacingStack.nano }}
          >
            {t('Programa Cloe')}
          </Text2>
        </Grid>

        <Grid container direction='column' item xs={12}>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark10}
          >
            <Trans i18nKey='ProgramContentParagraph'>
              Selecione o segmento, ano/série e componente curricular para acessar o currículo das unidades.
              Para conhecer a proposta pedagógica completa, consulte o <a target='_blank' href={pedagogicMapLink} className={classes.externalLink}>Mapa Pedagógico da Cloe</a>.
            </Trans>
          </Text2>
        </Grid>

        <Grid container direction='column' marginTop={2.5} xs={12} className={classes.selectorContainer} >
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Segmento')}:
          </Text2>

          <Segments
            disabled={stateMachine === ComponentState.LOADING}
            segments={segments}
            selected={segmentSelected}
            onChange={setSegmentSelected}
            dataTestid='program_cloe_select_segment'
          />
        </Grid>

        <Grid container direction='column' xs={12} className={classes.selectorContainer} >
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {isEM_IT ? t('Área do conhecimento') : t('Ano/série')}:
          </Text2>
          <Grades
            disabled={stateMachine === ComponentState.LOADING}
            gradesList={grades as any}
            dataTestid='program_cloe_select_year_grade'
            selected={Number(gradeSelected?.id) ?? 0}
            onChange={(id) => {
              const _gradeSelected = grades.find(g => g.id === id)
              if (_gradeSelected) {
                setGradeSelected(_gradeSelected)
              }
            }}
          />
        </Grid>

        <Grid container direction='column' xs={12} className={classes.selectorContainer} >
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {isEM_IT ? t('Itinerários') : t('Componente curricular')}:
          </Text2>
          <Disciplines
            disciplines={disciplines}
            dataTestid='program_cloe_select_curricular_component'
            currentDiscipline={disciplineSelected ?? null}
            changeDiscipline={disc => {
              setDisciplineSelected(disc)
            }}
          />
        </Grid>

        <Grid container direction='row' xs={12} alignItems='center' gap={theme.spacingInline.xxxs} justifyContent='space-between' className={classes.selectorContainer} >
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='lg'
            customColor={theme.colorBrand.dark}
          >
            {t('Unidades de conteúdo')}
          </Text2>

          <Button
            fullWidth={isMobile}
            startIcon={<DownloadOutlined />}
            variant={'primary'}
            onClick={() => {
              handlePDFDownload()
            }}
            data-testid='program_cloe_select_download'
            className={classes.pdfDownloadButton}
            disabled={stateMachine === ComponentState.NOT_FOUND}
          >
            {t('Baixar PDF')}
          </Button>
        </Grid>
        <Grid container className={classes.searchContainer}>
          <Search
            placeholder='Localizar na página'
            onSubmit={search}
            onClear={clear}
            dataTestId={{
              clear: 'home_content_search_topic_result_found',
              submit: 'home_content_search_topic_localize'
            }}
            showSubmitButton={false}
            ref={searchRef}
          />
          <Box sx={{ minWidth: '286px' }}>
            <StatusSelector
              onChange={sortOption => {
                handleSortContent(sortOption)
              }}
              statusOptions={STATUS_OPTIONS}
              dataTestId='home_content_order_content_unit'
            />
          </Box>
        </Grid>
        <Grid container className={classes.contentWrapper} id='mainContentWrapper'>
          {renderStates[stateMachine]}
        </Grid>
      </Grid>
    </Grid>
  )
}
