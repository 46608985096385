import { IActivateClassPlanProps, IApiResponse, ICheckClassPlanCreationStatusResponse, IClassPlanItemPage, IClassPlanUploadResponse, IGenerateClassPlanProps, IGenerateClassPlanResponse, IGetAllClassPlanResponse, IGetClassPLanResponse, IUpdateClassPlanProps } from 'services/types'
import { request } from 'services/base'
import Storage from 'utils/storage'
import { ACTIVATE_CLASS_PLAN, CHECK_CLASS_PLAN_CREATION, EXPORT_CLASS_PLAN, GENERATE_CLASS_PLAN, GET_ALL_CLASS_PLANS, GET_CLASS_PLAN, GET_CLASS_PLAN_ID, UPDATE_CLASS_PLAN, UPLOAD_FILE_CLASS_PLAN } from './CONSTANTS'

export const checkClassPlanCreation = async (threadId: number | string, runId: number | string): Promise<IApiResponse<ICheckClassPlanCreationStatusResponse>> =>
  await request({ url: CHECK_CLASS_PLAN_CREATION(threadId, runId) })

export const getClassPlan = async (threadId: number | string): Promise<IApiResponse<IGetClassPLanResponse>> =>
  await request({ url: GET_CLASS_PLAN(threadId) })

export const getClassPlanItem = async (id: number | string): Promise<IApiResponse<IClassPlanItemPage>> =>
  await request({ url: GET_CLASS_PLAN_ID(id) })

export const getAllClassPlans = async (): Promise<IApiResponse<IGetAllClassPlanResponse>> =>
  await request({ url: GET_ALL_CLASS_PLANS({ pageNumber: 1, pageSize: 20 }) })

export const generateClassPlan = async (data: IGenerateClassPlanProps): Promise<IApiResponse<IGenerateClassPlanResponse>> => {
  return await request({
    url: GENERATE_CLASS_PLAN(),
    method: 'POST',
    data
  })
}

export const uploadFileClassPlan = async (file: File): Promise<IApiResponse<IClassPlanUploadResponse>> => {
  const formData = new FormData()
  formData.append('file', file)

  return await request({
    url: UPLOAD_FILE_CLASS_PLAN(),
    method: 'POST',
    data: formData,
    headers: {
      Authorization: `Bearer ${Storage.token.get()}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateClassPlan = async (data: IUpdateClassPlanProps): Promise<IApiResponse<any>> => {
  return await request({
    url: UPDATE_CLASS_PLAN(),
    method: 'POST',
    data
  })
}

export const activateClassPlan = async (data: IActivateClassPlanProps): Promise<IApiResponse<any>> => {
  return await request({
    url: ACTIVATE_CLASS_PLAN(),
    method: 'POST',
    data
  })
}

export const exportClassPlan = async (planId: number | string, formatId: 'Docx' | 'Pdf'): Promise<IApiResponse<any>> =>
  await request({ url: EXPORT_CLASS_PLAN(planId, formatId) })
