import { createRef, useEffect, useState } from 'react'
import { Provider } from 'jotai'
import { Upload } from './Upload'
import Preview from './Preview'
import { useTranslation } from 'react-i18next'
import { toast } from 'components/design-system/Toast/manager'

interface IInputFilePreviewProps {
  initialFiles?: File[]
  availableArchivesTypes?: string
  description?: string
  maxFileSize?: number
  multiple?: boolean
  dataTestid?: string | null
  metaData?: { [key: string]: string | number }
  handleUploadFiles: (uploadFiles: File[]) => Promise<boolean>
  onRemoveFile: (index: number) => void
}

const InputFilePreviewContent: React.FC<IInputFilePreviewProps> = (props: IInputFilePreviewProps) => {
  const {
    initialFiles,
    description,
    maxFileSize,
    multiple = true,
    dataTestid,
    handleUploadFiles,
    onRemoveFile
  } = props

  const [files, setFiles] = useState<File[]>([])
  const [disabled, setDisabled] = useState(false)
  const inputRef = createRef<HTMLDivElement>()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!initialFiles || (initialFiles ?? [])?.length < 1) {
      return
    }

    setFiles(initialFiles)
  }, [initialFiles])

  useEffect(() => {
    if (!multiple && files.length === 0) {
      setDisabled(false)
    } else if (!multiple && files.length > 0) {
      setDisabled(true)
    }
  }, [files])

  const handleRemoveFile = (index: number) => {
    console.log({ files, splice: files.splice(index, 1) })
    setFiles(old => old.splice(index, 1))
    onRemoveFile(index)
  }

  const processUpload = async (uploadedFiles: File[]) => {
    try {
      setIsLoading(true)

      await handleUploadFiles(uploadedFiles)

      setFiles(uploadedFiles)
    } catch (err: any) {
      toast.handler({
        content: t('Não foi possível fazer o upload.'),
        duration: 60000,
        severity: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDataTaging = () => {
    // solução para funcionar o tagueamento
    inputRef?.current?.click()
  }

  return (
    <div onClick={handleDataTaging}>
      <div ref={inputRef} data-testid={dataTestid} />
      <Upload
        description={description as string}
        onUpload={processUpload}
        availableArchivesTypes={props.availableArchivesTypes as string}
        maxFileSize={maxFileSize as number}
        multiple={multiple}
        disabled={disabled || isLoading}
        dataTestid={dataTestid}
        isLoading={isLoading}
      />
      {
        (files.length >= 1) &&
        <Preview files={files} handleRemoveFile={handleRemoveFile} />
      }
    </div>
  )
}

export const InputComponent: React.FC<IInputFilePreviewProps> = (props: IInputFilePreviewProps) => {
  return (
    <Provider>
      <InputFilePreviewContent {...props} />
    </Provider>
  )
}

export default InputComponent
