import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    height: 'auto',
    zIndex: 1,
    overflow: 'hidden',
    // desktop
    [theme.breakpoints.up('sm')]: {
      minHeight: '100vh'
    },
    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px'
      // paddingTop: '64px'
    }
  }
}))
