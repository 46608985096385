/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { createRef, useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { isBefore, isAfter } from 'date-fns'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { AddCommentOutlined, InfoOutlined, StarBorderOutlined } from '@mui/icons-material'

import useStyles from '../../style'
import { Button } from 'components/design-system'
import { ActivityControlStatusEnum, ILevelResponse, IStudentsAnswerByQuestionResponse } from 'services/types'
import { getActivityByClass, correctQuestionV2 } from 'services/activity'
import { activeQuestionAtom, currentAnswerCommentOpenAtom, getStudentsAnswerByQuestionAtom, schoolLevelsAtom } from 'pages/ContentUnit/components/Activity/teacher/components/AnswersView/atomStore'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface ICardStudentAnswerButtonContentEvaluationProps {
  studentAnswer?: IStudentsAnswerByQuestionResponse
}

export const CardStudentAnswerButtonContentEvaluation: React.FC<ICardStudentAnswerButtonContentEvaluationProps> = ({ studentAnswer }) => {
  const classes = useStyles()
  const inputRef = createRef<HTMLDivElement>()

  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  // atom
  const [activity, setActivity] = useAtom(activityCurrentAtom)
  const [activeQuestion] = useAtom(activeQuestionAtom)
  const [, getStudentsAnswerByQuestion] = useAtom(getStudentsAnswerByQuestionAtom)
  const [schoolLevels] = useAtom(schoolLevelsAtom)
  const [currentAnswerCommentOpen, setCurrentAnswerCommentOpen] = useAtom(currentAnswerCommentOpenAtom)

  // state
  const [isCorrectionLoading, setIsCorrectionLoading] = useState(false)
  const [questionLevels, setQuestionLevels] = useState<ILevelResponse[] | null>(null)

  const correctStudentAnswer = async (level: ILevelResponse) => {
    if (Number(activity?.activity_status?.status) === ActivityControlStatusEnum.RELEASED) return
    if (studentAnswer?.answer?.question?.input_type === 'multi_choice') return
    if (isCorrectionLoading) return
    setIsCorrectionLoading(true)
    await correctQuestionV2({ activityId: activity?.id ?? '', anwserId: studentAnswer?.answer?.id ?? '', classId: classId ?? '', questionId: activeQuestion, level })

    await getStudentsAnswerByQuestion({ classId, activityId: activityId ?? evaluationId, questionId: activeQuestion })

    const responseActivity = await getActivityByClass(classId, activityId ?? evaluationId)
    activity && setActivity({ ...activity, questions: responseActivity?.data?.questions })

    setIsCorrectionLoading(false)
  }

  const adjustLevels = async () => {
    const question = activity?.questions?.find(question => question.id === activeQuestion)

    if (question?.input_type === 'multi_choice') {
      if (schoolLevels) {
        setQuestionLevels(schoolLevels.filter(cur => [0, 100].includes(cur.value)))
      }
    } else {
      schoolLevels && setQuestionLevels(schoolLevels)
    }
  }

  useEffect(() => {
    void adjustLevels()
  }, [])

  if (studentAnswer?.answer && activity?.schedule?.endDate && isBefore(new Date(), new Date(activity?.schedule?.endDate))) {
    return (
      <Grid item display='flex' alignItems='center' gap={2}>
        <InfoOutlined />
        <span className={classes.cardStudentAnswerTextInfo}>A função de avaliação estará disponível após a data final de entrega.</span>
      </Grid>
    )
  }

  if (studentAnswer?.answer) {
    return (
      <Grid item display='flex' gap={2}>
        <Grid item display='flex' alignItems='center' gap='8px' flexWrap='wrap'>
          <span className={classes.cardStudentAnswerTextInfo}>Avaliar: </span>
          {isCorrectionLoading
            ? <CircularProgress size={18} />
            : questionLevels?.map(cur => (
              <Button
                data-testid='correction_assessment_menu_answers_select_note'
                key={cur.id}
                size='small'
                // @ts-expect-error
                variant={studentAnswer?.answer?.level?.id === cur.id ? 'primary' : 'outlined'}
                // @ts-expect-error
                startIcon={studentAnswer?.answer?.level?.id === cur.id ? <StarBorderOutlined style={{ color: '#fff' }} /> : null}
                // @ts-expect-error
                className={studentAnswer?.answer?.level?.id === cur.id ? classes.cardStudentAnswerContentOptionsButton : classes.cardStudentAnswerButtonNotActiveNotCorrect}
                onClick={() => correctStudentAnswer(cur)}
                disabled={studentAnswer?.answer?.question?.input_type === 'multi_choice'}
              >
                {`${(cur.value ?? 0) / 10}`.replace('.', ',')}
              </Button>
            ))}
        </Grid>

        <div hidden ref={inputRef} data-testid={currentAnswerCommentOpen === studentAnswer?.answer?.id ? 'correction_assessment_menu_answers_hide_comments' : 'correction_assessment_menu_answers_show_comments'} />
        <Button
          variant='ghost'
          size='small'
          startIcon={<AddCommentOutlined />}
          className={ currentAnswerCommentOpen === studentAnswer?.answer?.id ? classes.cardStudentAnswerButtonCommentsActivated : '' }
          onClick={() => {
            inputRef?.current?.click()
            if (currentAnswerCommentOpen === studentAnswer?.answer?.id) {
              setCurrentAnswerCommentOpen(null)
              return
            }
            setCurrentAnswerCommentOpen(studentAnswer?.answer?.id ?? null)
          }}
        >
          Comentar
        </Button>
      </Grid>
    )
  }

  // não respondeu
  if (activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate))) {
    return (
      <Grid item display='flex' gap={2}>
        <Grid item display='flex' alignItems='center' gap='8px' flexWrap='wrap'>
          <span className={classes.cardStudentAnswerTextInfo}>Avaliar: </span>
          {questionLevels?.map(cur => (
            <Button
              key={cur.id}
              size='small'
              variant={cur.value === 0 ? 'primary' : 'outlined'}
              className={classes.cardStudentAnswerButtonNotActiveNotCorrect}
              disabled
            >
              {`${(cur.value ?? 0) / 10}`.replace('.', ',')}
            </Button>
          ))}
        </Grid>

        <Grid></Grid>
      </Grid>
    )
  }

  return <></>
}

export default CardStudentAnswerButtonContentEvaluation
