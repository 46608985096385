/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { createRef, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { isBefore } from 'date-fns'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { AddCommentOutlined, InfoOutlined, StarBorderOutlined } from '@mui/icons-material'

import useStyles from '../../style'
import { Button } from 'components/design-system'
import { IStudentsAnswerByQuestionResponse } from 'services/types'
import { getActivityByClass, revisionQuestionV2 } from 'services/activity'
import { activeQuestionAtom, currentAnswerCommentOpenAtom, getStudentsAnswerByQuestionAtom } from 'pages/ContentUnit/components/Activity/teacher/components/AnswersView/atomStore'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface ICardStudentAnswerButtonContentActivityProps {
  studentAnswer?: IStudentsAnswerByQuestionResponse
}

export const CardStudentAnswerButtonContentActivity: React.FC<ICardStudentAnswerButtonContentActivityProps> = ({ studentAnswer }) => {
  const classes = useStyles()
  const inputRef = createRef<HTMLDivElement>()

  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  // atom
  const [activity, setActivity] = useAtom(activityCurrentAtom)
  const [activeQuestion] = useAtom(activeQuestionAtom)
  const [, getStudentsAnswerByQuestion] = useAtom(getStudentsAnswerByQuestionAtom)
  const [currentAnswerCommentOpen, setCurrentAnswerCommentOpen] = useAtom(currentAnswerCommentOpenAtom)

  // state
  const [isRevisionLoading, setIsRevisionLoading] = useState(false)

  const revisionStudentAnswer = async () => {
    if (studentAnswer?.answer?.revision || studentAnswer?.answer?.level) return
    if (isRevisionLoading) return
    setIsRevisionLoading(true)
    await revisionQuestionV2({ activityId: activity?.id ?? '', anwserId: studentAnswer?.answer?.id ?? '', classId: classId ?? '', questionId: activeQuestion })

    await getStudentsAnswerByQuestion({ classId, activityId: activityId ?? evaluationId, questionId: activeQuestion })

    const responseActivity = await getActivityByClass(classId, activityId ?? evaluationId)
    activity && setActivity({ ...activity, questions: responseActivity?.data?.questions })

    setIsRevisionLoading(false)
  }

  if (studentAnswer?.answer && activity?.schedule?.endDate && isBefore(new Date(), new Date(activity?.schedule?.endDate)) && studentAnswer?.answer?.question?.input_type !== 'multi_choice') {
    return (
      <Grid item display='flex' alignItems='center' gap={2}>
        <InfoOutlined />
        <span className={classes.cardStudentAnswerTextInfo}>A função de visto estará disponível após a data final de entrega.</span>
      </Grid>
    )
  }

  if (studentAnswer?.answer) {
    return (
      <Grid item display='flex' gap={2}>
        <div hidden ref={inputRef} data-testid={currentAnswerCommentOpen === studentAnswer?.answer?.id ? 'correction_activity_menu_answers_hide_comments' : 'correction_activity_menu_answers_show_comments'} />
        <Button disabled={(studentAnswer?.answer?.revision || studentAnswer?.answer?.level) ? true : false} className={(studentAnswer?.answer?.revision || studentAnswer?.answer?.level) ? classes.cardStudentAnswerButtonRevised : ''} data-testid='correction_activity_menu_answers_check_question' onClick={revisionStudentAnswer} variant={(studentAnswer?.answer?.revision || studentAnswer?.answer?.level) ? 'primary' : 'outlined'} size='small' startIcon={<StarBorderOutlined />}>{isRevisionLoading ? <CircularProgress size={20} /> : 'Visto'}</Button>
        <Button
          variant='ghost'
          size='small'
          startIcon={<AddCommentOutlined />}
          className={ currentAnswerCommentOpen === studentAnswer?.answer?.id ? classes.cardStudentAnswerButtonCommentsActivated : '' }
          onClick={() => {
            inputRef?.current?.click()
            if (currentAnswerCommentOpen === studentAnswer?.answer?.id) {
              setCurrentAnswerCommentOpen(null)
              return
            }
            setCurrentAnswerCommentOpen(studentAnswer?.answer?.id ?? null)
          }}
        >
          Comentar
        </Button>
      </Grid>
    )
  }

  return <></>
}

export default CardStudentAnswerButtonContentActivity
