import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IScheduleCardProps } from '.'
import scheduleCardBackgroundImage from 'assets/schedule-card-background.svg'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    alignItems: 'space-between',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.neutral.light30}`,
    width: '303px',
    position: 'relative',
    height: '352px',
    borderRadius: theme.borderRadius.sm,
    overflow: 'hidden',
    '&:hover': {
      boxShadow: theme.shadowLevels.level3
    },
    '&:active': {
      boxShadow: theme.shadowLevels.level3
    },
    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tag: {
    display: 'flex !important',
    alignItems: 'center !important',
    height: '26px',
    padding: '3px 6px',
    fontSize: theme.font.fontSize.xxs

  },
  boxCover: {
    position: 'relative',
    background: theme.colors.support.colorSupport01,
    backgroundImage: ({ coverUrl }: IScheduleCardProps) => coverUrl ? `url(${coverUrl})` : `url(${scheduleCardBackgroundImage})`,
    backgroundSize: 'cover',
    height: '144px',
    padding: theme.spacingInset.xxs,
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'flex-end',
    display: 'flex',
    // mobile
    [theme.breakpoints.down('sm')]: {
      padding: '104px 16px 10px 10px',
      borderRadius: '0px'
    }
  },
  boxFade: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(20, 20, 20, 0.7) 0%, rgba(20, 20, 20, 0) 76.25%)',
    transform: 'rotate(180deg)',
    zIndex: 0
  },
  disciplineContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacingStack.xs,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px'
    },

    '& label': {
      marginRight: theme.spacingInline.nano
    }
  },
  itineraryContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '85%',
    marginTop: theme.spacingStack.nano,
    zIndex: 1,
    top: 0,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px'
    }
  },
  expeditionContainer: {
    // lineHeight: theme.font.lineHeight.xs,
    color: theme.colorBrand.medium,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,

    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm,
      lineHeight: '100%'
    }
  },
  titleContainer: {
    lineHeight: theme.font.lineHeight.xxs,
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    fontSize: theme.font.fontSize.sm,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm,
      lineHeight: '100%',
      marginTop: theme.spacingStack.xxxs
    }
  },
  textIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    marginBottom: theme.spacingStack.quarck,

    '& svg': {
      marginRight: '8px'
    }
  },
  textContainer: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xs,
    lineHeight: '17px',
    letterSpacing: '0.01em',
    color: theme.colors.neutral.dark20,
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    marginTop: theme.spacingStack.quarck,
    marginBottom: theme.spacingStack.quarck,
    textTransform: 'uppercase'
  },
  classNumber: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colors.neutral.dark30
  },
  buttonClass: {
    '&.primary': {
      fontSize: theme.font.fontSize.xxs,
      lineHeight: theme.font.lineHeight.xxs,
      maxHeight: '32px',
      position: 'absolute',
      bottom: '0',
      marginBottom: theme.spacingInline.xxs,
      textTransform: 'none',
      width: '90%'
    }
  },
  dataTag: {
    backgroundColor: 'rgba(67, 67, 67, 0.64)',
    color: theme.colors.neutral.lightBase
  },
  contentWrapper: {
    '& > div': {
      border: 'none'
    }
  },
  tagIsEdited: {
    cursor: 'pointer',
    border: `1px solid ${theme.colorBrand.light} !important`
  },
  hasUpdate: {
    height: '12px',
    paddingTop: theme.spacingStack.quarck,
    fontStyle: 'italic',
    color: theme.colors.neutral.dark40,
    fontSize: theme.font.fontSize.xxxs,
    fontWeight: theme.font.fontWeight.medium,
    display: ({ has_updated }: IScheduleCardProps) => has_updated ? 'block' : 'none'
  }
}))
