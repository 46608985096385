// src/components/Editor/utils/editor.utils.ts
import { OutputData } from '@editorjs/editorjs'

export const convertBlocksToHtml = (blocks: OutputData['blocks']): string => {
  return blocks
    .map(block => {
      let string

      switch (block.type) {
        case 'header':
          string = `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
          break
        case 'paragraph':
          string = `<p>${block.data.text}</p>`
          break
        case 'list': {
          const tag = block.data.style === 'ordered' ? 'ol' : 'ul'
          const items = block.data.items as string[]
          string = `<${tag}>${items.map(item => `<li>${item}</li>`).join('')}</${tag}>`
          break
        }
        default:
          string = ''
          break
      }

      string = string.replace("\n", "<br />")

      return string
    })
    .join('')
}

export const createInitialBlock = (html: string) => ({
  blocks: [
    {
      type: 'paragraph',
      data: {
        text: html
      }
    }
  ]
})
