import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    minHeight: '100vh',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    flexDirection: 'column',

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px'
      // paddingTop: '64px'
    }
  }
}))
