import { StepConnector } from '@mui/material'
import useStyle from './style'

export const ColorlibConnector = () => {
  const classes = useStyle()

  return (
    <StepConnector className={classes.colorlibConnector} />
  )
}
