import { createRef } from 'react'
import { Tag } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { IGradeTypesResponse } from 'services/types/taught-disciplines'

import useStyle from './style'

interface IGradesSelector {
  dataTestid?: string
  gradesList: IGradeTypesResponse[] | undefined
  disabled?: boolean
  onChange?: (gradeId: number) => void
  selected?: number
}

export const Grades: React.FC<IGradesSelector> = (props: IGradesSelector) => {
  const { gradesList, dataTestid, disabled, onChange, selected } = props
  const classes = useStyle()
  const { t } = useTranslation()
  const inputRef = createRef<HTMLDivElement>()
  const theme = useTheme()

  const handleGradeChange = (gradeId: number): void => {
    void onChange?.(gradeId)
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestid} hidden />

      <div className={classes.tagsContainer}>
        {
          gradesList?.map(grade =>
            <Tag
              key={grade?.id}
              className={`${classes.tag}`}
              color={grade?.id === selected ? theme.colors.neutral.lightBase : undefined}
              background={grade?.id === selected ? theme.colorBrand.medium : undefined}
              disabled={disabled}
              onClick={() => {
                inputRef?.current?.click()
                handleGradeChange(grade?.id)
              }}
            >
              {t(grade?.name ?? '')}
            </Tag>
          )
        }
      </div>
    </>
  )
}
