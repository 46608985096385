import { BreadCrumb, Text2 } from 'components/design-system'
import useStyle from './style'
import { LIBRARY_HOME } from 'navigation/CONSTANTS'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { ICollectionResponse } from 'services/types/library'
import { useAtom } from 'jotai'
import { selectedArticlesAtom, selectedResourcesAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'
import LibraryCard from 'components/common/LibraryCard'
import { OnlyProfile } from 'components/common'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { MyCloe } from 'navigation/components'
import { LibraryContentSchedule } from 'navigation/components/MyCloe/components'
import { ResourceThumb } from 'components/common/ResourceThumb'
import { RenderWhenCondition } from 'utils/wrappers'
import { Grid, useTheme } from '@mui/material'
import { useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

interface IProps extends ICollectionResponse {
  madeByCloe: boolean
}

export const CollectionsTeacherDetails: React.FC<IProps> = (props) => {
  const classes = useStyle()
  const theme = useTheme()
  const { t } = useTranslation()
  const [openDescription, setOpenDescription] = useState(true)
  const { articles, collection, resources, thumbnails, madeByCloe } = props
  const { title, description, createdAt, author } = collection
  const createdDate = dayjs(createdAt).format('DD.MM.YYYY')

  const authorName = madeByCloe ? 'Curadoria Cloe' : author.name

  // atoms
  const [selectedArticles] = useAtom(selectedArticlesAtom)
  const [selectedResources] = useAtom(selectedResourcesAtom)

  return (
    <main className={classes.collectionsContainer}>
      <BreadCrumb
        current={title}
        crumbs={[{ title: 'Biblioteca', link: LIBRARY_HOME }]}
      />
      <div className={classes.header}>
        <div className={classes.headerBackgroundLeft} />
        <div className={classes.headerBackgroundRight} />
        <div className={classes.headerThumbs}>
          {
            thumbnails?.length
              ? <div className={classes.cardImage}>
                {thumbnails.filter((t, i) => i < 3).map(thumb =>
                  <ResourceThumb
                    thumbnail={thumb}
                    type='gallery'
                    size='medium'
                    originalCloe={false}
                  />)}
              </div>
              : <ResourceThumb
                type='article'
                size='medium'
                originalCloe={false}
              />
          }
        </div>
        <div className={classes.headerInfo}>
          <Text2 fontSize='lg' fontWeight='bold' colorbrand='dark' className={classes.headerInfoTitle}>
            {title}
          </Text2>
          <Text2 fontSize='xs' fontWeight='regular' colorbrand='dark'>
            <Trans i18nKey='Criada em {{ createdDate }} por <1>{{ creatorName }}</1>'>
              Criada em {createdDate} por <b>{authorName}</b>
            </Trans>
          </Text2>
        </div>
      </div>
      <Grid container>
        <RenderWhenCondition condition={madeByCloe}>
          <Grid
            item
            xs={12}
            md={3}
            display='flex'
            flexDirection='column'
            gap={theme.spacingStack.xxxs}
            padding={{ xs: `${theme.spacingStack.xxxs} 0`, md: theme.spacingStack.xxxs }}
          >
            <Text2
              fontSize='sm'
              fontWeight='bold'
              colorbrand='medium'
              className={classes.descriptionTitle}
              onClick={() => setOpenDescription(!openDescription)}
            >
              {t('Sobre esta coleção')}
              {
                openDescription
                  ? <KeyboardArrowUp />
                  : <KeyboardArrowDown />
              }
            </Text2>
            <Text2
              fontSize='sm'
              fontWeight='regular'
              neutral='dark20'
              className={`${classes.descriptionMobile} ${openDescription ? 'open' : ''}`}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Grid>
        </RenderWhenCondition>
        <Grid item xs={12} md={madeByCloe ? 9 : 12} className={classes.cardsContainer}>
          {
            articles.map((article, index) => {
              const isSelected = selectedArticles.map(el => el.articleId).includes(article.articleId)
              return (
                <LibraryCard
                  key={index}
                  article={article}
                  isSelected={isSelected}
                  page='collection'
                />)
            })
          }
          {
            resources?.map((resource, index) => {
              const isSelected = selectedResources.map(el => el.resourceId).includes(resource.resourceId)
              return <LibraryCard
                key={index}
                resource={resource}
                isSelected={isSelected}
                page='collection'
              />
            })
          }
        </Grid>
      </Grid>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <MyCloe>
          <LibraryContentSchedule />
        </MyCloe>
      </OnlyProfile>
    </main >
  )
}
