import React, { useEffect } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export interface IToastProps {
  id: string
  destroy: () => void
  severity: 'error' | 'info' | 'success' | 'warning' | undefined
  content: string | React.Component
  duration?: number
}

/// trocar por esse ???? >>>> https://github.com/iamhosseindhv/notistack
export const Toast: React.FC<IToastProps> = (props) => {
  const { destroy, content, severity, duration = 0, id } = props

  useEffect(() => {
    if (!duration) return

    const timer = setTimeout(() => {
      destroy()
    }, duration)

    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <>
      <div>
        <Alert id={id} sx={{ width: '100%' }} onClose={() => destroy()} severity={severity}>
          {content}
        </Alert>
      </div>
    </>
  )
}

// previne re-render
const shouldRerender = (prevProps: IToastProps, nextProps: IToastProps) => {
  return prevProps.id === nextProps.id
}
export default React.memo(Toast, shouldRerender)
