import { Grid, useTheme } from '@mui/material'
import { Text, BreadCrumb } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import { useEffect, useState } from 'react'
import { useQuery } from 'utils/query'
import { LIBRARY_HOME } from 'navigation/CONSTANTS'
import { Redirect } from 'react-router-dom'
import { MyCloe } from 'navigation/components'
import { LibraryContentSchedule } from 'navigation/components/MyCloe/components'
import { CloeMaterialSearch } from 'components/common/CloeMaterialSearch'

export const LibrarySearch = () => {
  // props
  const { t } = useTranslation()
  const classes = useStyle({ useWorm: false })
  const theme = useTheme()
  const searchQuery = useQuery()

  // states
  const [searchTerm, setSearchTerm] = useState(searchQuery.get('search') ?? '')

  if (searchTerm === '') {
    return <Redirect to={LIBRARY_HOME} />
  }

  useEffect(() => {
    window.history.replaceState(null, '', `?search=${searchTerm}`)
  }, [searchTerm])

  return (
    <Grid container sx={{ p: { md: 2, lg: 3 } }} className={classes.libraryContainer}>
      <Grid item xs={12}>
        <BreadCrumb
          current='Pesquisar na biblioteca'
          crumbs={[{ title: 'Biblioteca', link: LIBRARY_HOME }]}
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <Text type='heading' size='medium' mobileSize='small' color='medium' style={{ fontWeight: theme.font.fontWeight.semibold }}>
          {t('Pesquisar na biblioteca')}
        </Text>
      </Grid>
      <CloeMaterialSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        dataTestId={{
          clear: 'cloe_super_library_clear_search',
          submit: 'cloe_super_library_submit_search'
        }}
        page='library'
      />
      <MyCloe>
        <LibraryContentSchedule />
      </MyCloe>
    </Grid>
  )
}
