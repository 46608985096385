import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IProps {
  thumbnail?: string | null
  iconColor: string
  size: 'small' | 'medium'
}

export default makeStyles((theme: Theme) => createStyles({
  cardImage: ({ iconColor, size }: IProps) => ({
    position: 'relative',
    width: size === 'small' ? '72px' : '128px',
    height: size === 'small' ? '72px' : '128px',
    background: iconColor,
    borderRadius: theme.borderRadius.circular,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  cardThumbnail: ({ thumbnail }: IProps) => ({
    width: '100%',
    height: '100%',
    borderRadius: theme.borderRadius.circular,
    background: `url(${thumbnail}) no-repeat center center`,
    backgroundSize: 'cover'
  }),
  originalCloeContent: ({ size }: IProps) => ({
    position: 'absolute',
    top: size === 'small' ? '-1px' : '-3px',
    left: size === 'small' ? '-2px' : '-3px',
    bottom: size === 'small' ? '-3px' : '-5px',
    right: size === 'small' ? '-4px' : '-6px',
    display: 'flex',
    '& svg': {
      width: '100%',
      height: '100%'
    }
  })
}))
