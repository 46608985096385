import { CheckOutlined } from '@mui/icons-material'

import { Button } from 'components/design-system'
import { IQuestionResponse } from 'services/types'
import { useAtom } from 'jotai'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { createRef } from 'react'
import { activeQuestionAtom } from '../../../atomStore'

import useStyles from '../style'

interface IButtonQuestionSelectorProps {
  index: number
  question: IQuestionResponse
}

const ButtonQuestionSelector: React.FC<IButtonQuestionSelectorProps> = ({ index, question }) => {
  const classes = useStyles()
  const inputRef = createRef<HTMLDivElement>()

  // atom
  const [activity] = useAtom(activityCurrentAtom)
  const [activeQuestion, setActiveQuestion] = useAtom(activeQuestionAtom)

  const renderInputRef = () => (
    <div ref={inputRef} hidden data-testid={activity?.suggested_application_type === 'activity'
      ? 'correction_activity_menu_questions_question_number'
      : 'correction_assessment_menu_assessments_question_number'} />
  )

  if (question.id === activeQuestion) {
    if (question.all_revised_or_corrected) {
      return (
        <>
          {renderInputRef()}
          <Button
            key={index}
            size='small'
            variant='outlined'
            startIcon={<CheckOutlined style={{ color: '#fff' }} />}
            className={classes.questionSelectorContentOptionsButton}
            onClick={() => {
              inputRef?.current?.click()
              setActiveQuestion(question.id)
            }}
          >
            {index + 1}
          </Button>
        </>
      )
    }

    return (
      <>
        {renderInputRef()}
        <Button
          key={index}
          size='small'
          variant='outlined'
          startIcon={<CheckOutlined style={{ color: '#47739d' }} />}
          className={classes.questionSelectorContentOptionsButton}
          onClick={() => {
            inputRef?.current?.click()
            setActiveQuestion(question.id)
          }}
        >
          {index + 1}
        </Button>
      </>
    )
  }

  if (question.all_revised_or_corrected) {
    return (
      <>
        {renderInputRef()}
        <Button
          key={index}
          size='small'
          variant='outlined'
          startIcon={<CheckOutlined />}
          className={classes.questionSelectorContentOptionsButtonNotActive}
          onClick={() => {
            inputRef?.current?.click()
            setActiveQuestion(question.id)
          }}
        >
          {index + 1}
        </Button>
      </>
    )
  }

  return (
    <>
      {renderInputRef()}
      <Button
        key={index}
        size='small'
        variant='outlined'
        className={classes.questionSelectorContentOptionsButtonNotActiveNotCorrect}
        onClick={() => {
          inputRef?.current?.click()
          setActiveQuestion(question.id)
        }}
      >
        {index + 1}
      </Button>
    </>
  )
}

export default ButtonQuestionSelector
