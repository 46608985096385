import { Redirect } from 'react-router-dom'
// constants and types
import {
  CONTENT_UNIT_TEACHER,
  DASHBOARD_TEACHER,
  ROOT,
  TAUGHT_DISCIPLINES,
  EDIT_SINGLE_RECORD,
  SINGLE_RECORD,
  MILESTONE,
  MILESTONES_BY_ID,
  PROGRAM_CONTENT,
  PENDING_ACTIVITIES,
  LIBRARY_HOME,
  LIBRARY_SEARCH,
  MY_CLASSES,
  COLLECTIONS,
  NO_CLOE_SUPER_APPROVED,
  CLASS_PLAN_CREATE,
  DASHBOARD_TEACHER_LIBRARY_CONTENT,
  CLASS_PLAN_ITEM
} from 'navigation/CONSTANTS'
import { IUserSchoolProfileTypeEnum } from 'services/types'

// pages imports
import {
  Dashboard,
  SingleRecord,
  EditSingleRecord,
  Milestone,
  TaughtDisciplines,
  MyClasses,
  ProgramContent,
  PendingActivities,
  Home,
  Collections,
  LibraryContentDetails,
  ClassPlanItem
} from 'pages'
import ContentUnit from 'pages/ContentUnit'

// local imports
import { IRoutesGroupProps } from '..'
import { ClassMiddleware, OnlyCurrentSchoolPeriod, ShowCloeProMiddleware, HideCloeProMiddleware } from 'navigation/middlewares'
import { LibraryHome, LibrarySearch } from 'pages/Library'
import { cloeSuperFeatureOn } from 'navigation/middlewares/cloeSuperHomeFeatureFlag/cloeSuperHomeFeatureFlagOn'
import { cloeSuperHomeFeatureFlagOff } from 'navigation/middlewares/cloeSuperHomeFeatureFlag/cloeSuperHomeFeatureFlagOff'
import { NoCloeSuperApproved } from 'pages/NoCloeSuperApproved'
import { ClassPlan } from 'pages/ClassPlan'

const teacherPrivateRoutes: IRoutesGroupProps = {
  profileTypes: [IUserSchoolProfileTypeEnum.teacher],
  routes: [
    {
      path: SINGLE_RECORD(':gradeTypeCode', ':gradeCode'),
      component: SingleRecord,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: EDIT_SINGLE_RECORD(':gradeTypeCode', ':gradeCode'),
      component: EditSingleRecord,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: MILESTONE(':gradeTypeCode', ':gradeCode'),
      component: Milestone,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: MILESTONES_BY_ID(':gradeTypeCode', ':gradeCode'),
      component: Milestone,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: TAUGHT_DISCIPLINES,
      component: TaughtDisciplines,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: DASHBOARD_TEACHER(':gradeTypeCode', ':gradeCode'),
      component: Dashboard,
      isPrivate: true,
      exact: true,
      middlewares: [ShowCloeProMiddleware]
    },
    {
      path: DASHBOARD_TEACHER_LIBRARY_CONTENT(),
      component: LibraryContentDetails,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: CONTENT_UNIT_TEACHER(':gradeTypeCode', ':gradeCode'),
      component: ContentUnit,
      isPrivate: true,
      middlewares: [ShowCloeProMiddleware]
    },
    // FeatureFlags.cloeSuperHome INICIO
    // ao remover a feature flag, remova os componentes de off e tire os middlewares da flag
    // FeatureFlags.cloeSuperHome ON
    {
      path: ROOT,
      component: Home,
      exact: true,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    {
      path: MY_CLASSES,
      component: MyClasses,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    // FeatureFlags.cloeSuperHome OFF
    {
      path: ROOT,
      component: MyClasses,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware, cloeSuperHomeFeatureFlagOff]
    },
    // FeatureFlags.cloeSuperHome FIM
    {
      path: PROGRAM_CONTENT,
      component: ProgramContent,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: COLLECTIONS(),
      component: Collections,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    {
      path: CLASS_PLAN_CREATE(),
      component: ClassPlan,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    {
      path: CLASS_PLAN_ITEM(),
      component: ClassPlanItem,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    {
      path: PENDING_ACTIVITIES(),
      component: PendingActivities,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: LIBRARY_HOME,
      component: () => <LibraryHome />,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    {
      path: LIBRARY_SEARCH(''),
      component: () => <LibrarySearch />,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware, cloeSuperFeatureOn]
    },
    {
      path: NO_CLOE_SUPER_APPROVED,
      component: NoCloeSuperApproved,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: '/class/:classId/',
      component: () => <Redirect to={{ pathname: '/' }} />,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    }
  ]
}

const defaultMiddlewares = [ClassMiddleware]

teacherPrivateRoutes.routes = teacherPrivateRoutes.routes.map(route => ({
  ...route,
  middlewares: [...(route?.middlewares ?? []), ...defaultMiddlewares]
}))

export {
  teacherPrivateRoutes
}
