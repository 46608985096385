import { Collapse, Grid, useTheme } from '@mui/material'
import { useState, createRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Title } from './style'
import { SxProps } from '@mui/system'

export interface ISimpleCollapseProps {
  title: string
  isInitialExpanded?: boolean
  titleSx?: SxProps
  dataTestid?: string
}

export const SimpleCollapse: React.FC<ISimpleCollapseProps> = ({ title, isInitialExpanded = false, children, titleSx, dataTestid }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isInitialExpanded)
  const theme = useTheme()

  const inputRef = createRef<HTMLDivElement>()

  const handleDataTagging = () => {
    inputRef?.current?.click()
  }

  return (
    <Grid item xs={12} ref={inputRef} data-testid={dataTestid}>
      <Grid
        item
        display='flex'
        // justifyContent='space-between'
        xs={12}
        sx={{
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
          },
          maxWidth: '600px',
          cursor: 'pointer',
          ...titleSx
        }}
        onClick={() => [setIsExpanded(!isExpanded), handleDataTagging()]}
      >
        <Title>{title}</Title>
        <ExpandMoreIcon
          style={{
            fontSize: '24px',
            color: theme.colors.neutral.dark10
          }}
          sx={{
            transform: !isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
            fontSize: theme.font.fontSize.lg,
            height: 'fit-content'
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={isExpanded} timeout='auto' unmountOnExit>
          {children}
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default SimpleCollapse
