import { Text2 } from 'components/design-system'
import useStyle from './style'

interface IFieldFilledProps {
  title: string
  value?: string
  values?: string[]
}

export const FieldFilled = ({ title, value, values }: IFieldFilledProps) => {
  const classes = useStyle()

  return (
    <div className={classes.formInputContainer}>
      <Text2 fontSize='xs' fontWeight='bold' neutral='dark20'>
        {title}
      </Text2>
      <Text2 fontSize='sm' fontWeight='regular' neutral='darkBase' lineHeight='xs'>
        {values?.join(', ') ?? value ?? ''}
      </Text2>
    </div>
  )
}
