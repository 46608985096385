/* eslint-disable no-unreachable */
import { ActivityControlStatusEnum, IActivityResponse, IContentUnitResponse } from 'services/types'
import {
  getSubscription,
  contentCachePipe,
  CacheManagerException,
  removeAllUrlsFromCache,
  cacheEstimate,
  CacheSizeLimitException,
  MAX_PERCENT_REMAINING_TO_CACHE,
  addSuccessEmbeddedToExpeditionCached,
  addSuccessRequestToExpeditionCached,
  OfflineTableExpeditionCachedStatus,
  setExpeditionCachedData,
  isInternalImageUrl,
  ExpeditionCachedTable,
  removeCurrentWorking
} from '../'

export const expeditionCachePipe = async (expeditionId: number, expeditionIndexed: ExpeditionCachedTable) => {
  const cachedUrls: string[] = []
  const contentsSizes: Array<{ content: number, files: number }> = []
  const makeOnCompletedFile = (file: string) => {
    addSuccessEmbeddedToExpeditionCached(file, expeditionId)
    cachedUrls.push(file)
  }

  const makeOnCompleted = (url: string) => {
    addSuccessRequestToExpeditionCached(url, expeditionId)
    cachedUrls.push(url)
  }

  const makeOnCompletedEach = (url: string) => {
    addSuccessRequestToExpeditionCached(url, expeditionId)
    cachedUrls.push(url)
  }

  try {
    const { percentUsage } = await cacheEstimate()
    if ((100 - Number(percentUsage)) < MAX_PERCENT_REMAINING_TO_CACHE) {
      throw new CacheSizeLimitException()
    }
    const { class: studentClass } = getSubscription()

    const { fetchUnique: fetchUniqueExpedition, contentSize: contentSizeExpedition } = contentCachePipe<IContentUnitResponse>({
      name: 'content-units',
      url: `${process.env.REACT_APP_BACKEND_URL as string}/v2/content-units/:expeditionId`,
      params: { expeditionId },
      hasEmbeddedContent: true,
      onCompletedFile: (file) => {
        makeOnCompletedFile(file)
      },
      onCompleted: () => {
        makeOnCompleted(`${process.env.REACT_APP_BACKEND_URL as string}/v2/content-units/${expeditionId}`)
        setExpeditionCachedData({
          status: OfflineTableExpeditionCachedStatus.PARTIAL_DOWNLOADED
        }, expeditionId)
      },
      onError: (error) => {
        if (error instanceof CacheManagerException) {
          console.error(error.request)
        }
      }
    })

    const contentUnit = await fetchUniqueExpedition()
    contentsSizes.push(contentSizeExpedition)

    const { fetchUnique: fetchUniqueExpeditionDidacticContent, contentSize: contentSizeExpeditionDidacticContent } = contentCachePipe<IActivityResponse[]>({
      name: 'content_unit_didactic_content',
      url: `${process.env.REACT_APP_BACKEND_URL as string}/class/:classId/content-unit/:expeditionId/didactic-content`,
      hasEmbeddedContent: true,
      params: {
        classId: studentClass.id,
        expeditionId
      },
      onCompletedFile: (file) => {
        makeOnCompletedFile(file)
      },
      onCompleted: () => {
        makeOnCompleted(`${process.env.REACT_APP_BACKEND_URL as string}/class/${studentClass.id}/content-unit/${expeditionId}/didactic-content`)
      }
    })

    await fetchUniqueExpeditionDidacticContent()
    contentsSizes.push(contentSizeExpeditionDidacticContent)

    const disciplinesIds = contentUnit.cachedResponse?.disciplines.map(discipline => discipline.id) ?? []

    if (disciplinesIds.length) {
      const { fetchEach: fetchEachContents, contentSize: contentSizeContents } = contentCachePipe<IActivityResponse[]>({
        name: 'activity_list',
        url: `${process.env.REACT_APP_BACKEND_URL as string}/classes/:classId/disciplines/:disciplineId/contents`,
        hasEmbeddedContent: true,
        params: {
          classId: disciplinesIds.map(_ => studentClass.id),
          disciplineId: disciplinesIds
        },
        onCompletedFile: (file) => {
          makeOnCompletedFile(file)
        },
        onCompletedEach: (_, __, request) => {
          makeOnCompletedEach(request.url)
        }
      })

      await fetchEachContents()
      contentsSizes.push(contentSizeContents)
    }

    const { fetchUnique: fetchUniqueActivityEvaluationList, contentSize: contentSizeActivityEvaluationList } = contentCachePipe<IActivityResponse[]>({
      name: 'activity_evaluation_list',
      url: `${process.env.REACT_APP_BACKEND_URL as string}/activities/:classId/content/:expeditionId`,
      hasEmbeddedContent: true,
      params: {
        classId: studentClass.id,
        expeditionId
      },
      onCompletedFile: (file) => {
        makeOnCompletedFile(file)
      },
      onCompleted: () => {
        makeOnCompleted(`${process.env.REACT_APP_BACKEND_URL as string}/activities/${studentClass.id}/content/${expeditionId}`)
      }
    })
    const AllActivities = await fetchUniqueActivityEvaluationList()
    contentsSizes.push(contentSizeActivityEvaluationList)

    const { fetchUnique: fetchUniqueActivityList, contentSize: contentSizeActivityList } = contentCachePipe<IActivityResponse[]>({
      name: 'activity_list',
      url: `${process.env.REACT_APP_BACKEND_URL as string}/activities/:classId/content/:expeditionId?suggested_application_type=activity`,
      hasEmbeddedContent: true,
      params: {
        classId: studentClass.id,
        expeditionId
      },
      onCompletedFile: (file) => {
        makeOnCompletedFile(file)
      },
      onCompleted: () => {
        makeOnCompleted(`${process.env.REACT_APP_BACKEND_URL as string}/activities/${studentClass.id}/content/${expeditionId}?suggested_application_type=activity`)
      }
    })

    await fetchUniqueActivityList()
    contentsSizes.push(contentSizeActivityList)

    const { fetchUnique: fetchUniqueLevels, contentSize: contentSizeLevels } = contentCachePipe<IActivityResponse[]>({
      name: 'levels',
      url: `${process.env.REACT_APP_BACKEND_URL as string}/levels`,
      onCompleted: () => {
        makeOnCompleted(`${process.env.REACT_APP_BACKEND_URL as string}/levels`)
      }
    })

    await fetchUniqueLevels()
    contentsSizes.push(contentSizeLevels)

    // const openedActivities = AllActivities.cachedResponse?.filter(activity => activity.activity_status?.status === 3)

    const openedActivitiesIds = AllActivities.cachedResponse
      ?.filter(activity => activity.suggested_application_type === 'activity' || activity.activity_status?.status !== ActivityControlStatusEnum.AVAILABLE)
      .map(activity => activity.id) ?? []

    if (openedActivitiesIds.length) {
      const { fetchEach: fetchEachActivities, contentSize: contentSizeActivities } = contentCachePipe<IActivityResponse>({
        name: 'activity_item',
        url: `${process.env.REACT_APP_BACKEND_URL as string}/activities/:classId/:activityId`,
        hasEmbeddedContent: true,
        params: {
          classId: openedActivitiesIds.map(_ => studentClass.id),
          activityId: openedActivitiesIds
        },
        onCompletedFile: (file) => {
          makeOnCompletedFile(file)
        },
        onCompletedEach: (_, __, request) => {
          makeOnCompletedEach(request.url)
        }
      })
      await fetchEachActivities()
      contentsSizes.push(contentSizeActivities)
      const { fetchEach: fetchEachAnswers, contentSize: contentSizeAnswers } = contentCachePipe<any>({
        name: 'activity_answers',
        url: `${process.env.REACT_APP_BACKEND_URL as string}/answers/class/:classId/activity/:activityId`,
        hasEmbeddedContent: true,
        params: {
          classId: openedActivitiesIds.map(_ => studentClass.id),
          activityId: openedActivitiesIds
        },
        onCompletedFile: (file) => {
          makeOnCompletedFile(file)
        },
        onCompletedEach: (_, __, request) => {
          makeOnCompletedEach(request.url)
        }
      })
      await fetchEachAnswers()
      contentsSizes.push(contentSizeAnswers)

      const { fetchEach: fetchContentUnitExpeditions, contentSize: contentSizeContentUnitExpeditions } = contentCachePipe<IActivityResponse[]>({
        name: 'content_unit_expeditions',
        url: `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/:classId/activities/:activityId`,
        hasEmbeddedContent: true,
        params: {
          classId: openedActivitiesIds.map(_ => studentClass.id),
          activityId: openedActivitiesIds
        },
        onCompletedFile: (file) => {
          makeOnCompletedFile(file)
        },
        onCompletedEach: (_, __, request) => {
          makeOnCompletedEach(request.url)
        }
      })

      await fetchContentUnitExpeditions()
      contentsSizes.push(contentSizeContentUnitExpeditions)
    }

    const { fetchUnique: fetchUniqueEvaluation, contentSize: contentSizeEvaluation } = contentCachePipe<IActivityResponse[]>({
      name: 'evaluation',
      url: `${process.env.REACT_APP_BACKEND_URL as string}/activities/:classId/content/:expeditionId?suggested_application_type=evaluation`,
      hasEmbeddedContent: true,
      params: {
        classId: studentClass.id,
        expeditionId
      },
      onCompletedFile: (file) => {
        makeOnCompletedFile(file)
      },
      onCompleted: () => {
        makeOnCompleted(`${process.env.REACT_APP_BACKEND_URL as string}/activities/${studentClass.id}/content/${expeditionId}?suggested_application_type=evaluation`)
      }
    })
    await fetchUniqueEvaluation()
    contentsSizes.push(contentSizeEvaluation)

    const allExpeditionSize = contentsSizes.reduce((acc, next) => ({
      content: acc.content + next.content,
      files: acc.files + next.files
    }), {
      content: 0,
      files: 0
    })

    addSuccessRequestToExpeditionCached(cachedUrls, expeditionIndexed)

    await setExpeditionCachedData({
      status: OfflineTableExpeditionCachedStatus.FULL_DOWNLOADED,
      cachedAt: new Date(),
      size: allExpeditionSize,
      successUrls: cachedUrls,
      embeddedUrls: cachedUrls.filter(url => isInternalImageUrl(url))
    }, expeditionId)

    const updateEvent = new Event('expeditionInCacheUpdated')
    window.dispatchEvent(updateEvent)
    removeCurrentWorking('expeditionId')
    return true
  } catch (error) {
    removeCurrentWorking('expeditionId')
    console.error('expeditionCacheRunner: error ao baixar conteúdo na raiz', error)
    removeAllUrlsFromCache(cachedUrls)
    setExpeditionCachedData({
      status: OfflineTableExpeditionCachedStatus.ERROR
    }, expeditionId)
    return false
  }
}
