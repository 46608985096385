import { Alert, Avatar, BreadCrumb, Button, Text2 } from 'components/design-system'
import useStyle from './style'
import { LIBRARY_HOME } from 'navigation/CONSTANTS'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { toast } from 'components/design-system/Toast/manager'

import { Loading } from 'components/common'
import { getClassPlanItem } from 'services/classPlan/classPlanService'
import { useParams } from 'react-router-dom'
import { IClassPlanItemPage } from 'services/types'
import { useAuth } from 'navigation/ProvideAuth'
import { ExportClassPlanModal } from './components'

export enum STATES {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export const ClassPlanItem: React.FC = () => {
  const classes = useStyle()
  const [stateMachine, setStateMachine] = useState<STATES>(STATES.IDLE)
  const { planId } = useParams<{ planId: string }>()
  const [classPlan, setClassPlan] = useState<IClassPlanItemPage>()
  const [isExportingClassPlan, setIsExportingClassPlan] = useState(false)
  const auth = useAuth()

  const { t } = useTranslation()

  useEffect(() => {
    fetchClassPlan()
  }, [planId])

  const fetchClassPlan = async () => {
    setClassPlan(undefined)

    if (!planId) return

    try {
      setStateMachine(STATES.LOADING)

      const response = await getClassPlanItem(planId)

      if (!response.success) throw new Error()

      setClassPlan(response.data)

      setStateMachine(STATES.READY)
    } catch (err: any) {
      setStateMachine(STATES.ERROR)

      toast.handler({
        content: t('Não conseguimos localizar o plano de aula. Tente novamente mais tarde!'),
        duration: 1000,
        severity: 'error'
      })
    }
  }

  const ActionButton = () => (
    <Button
      className='actionButton'
      variant='ghost'
      size='small'
      data-testid='followup_reload'
      onClick={fetchClassPlan}
    >
      {t('Recarregar')}
    </Button>
  )

  const handleExportClassPlan = async () => {
    if (!classPlan) return

    setIsExportingClassPlan(true)
  }

  const createdDate = dayjs((classPlan?.createdAt ?? '').toLocaleString()).format('DD.MM.YYYY')
  const creatorName = auth?.user?.username as string

  const ReadyState = () => {
    if (!classPlan) return <></>

    return (
      <>
        <BreadCrumb
          current={`Plano de aula: ${classPlan.title}`}
          crumbs={[{ title: 'Biblioteca', link: LIBRARY_HOME }]}
        />
        <div className={classes.header}>
          <div>
            <Avatar userName={creatorName} className={classes.cardImage} size='large' />
          </div>
          <div className={classes.headerInfo}>
            <Text2 fontSize='lg' fontWeight='bold' colorbrand='dark'>
              {classPlan.title}
            </Text2>
            <Text2 fontSize='xs' fontWeight='regular' colorbrand='dark'>
              <Trans i18nKey='Criada em {{ createdDate }} por <1>{{ creatorName }}</1>'>
                Criada em {{ createdDate }} por <b>{{ creatorName }}</b>
              </Trans>
            </Text2>
          </div>
        </div>
        <section className={classes.body}>
          <div dangerouslySetInnerHTML={{
            __html: classPlan.html ?? ''
          }} className={classes.dangerouslyContent} />
        </section>
        <Button
          variant='primary'
          className={classes.backNextPreviousButton}
          data-testid='handleExportClassPlan'
          onClick={handleExportClassPlan}
        >
          {t('Exportar')}
        </Button>
      </>
    )
  }

  const stateRender = {
    [STATES.IDLE]: <></>,
    [STATES.LOADING]: <Loading type='linear' className={classes.loading} />,
    [STATES.READY]: <ReadyState />,
    [STATES.ERROR]: <Alert content='Não foi possivel buscar seus planos de aula.' outlined={true} severity='error' actionButton={<ActionButton />} />
  }

  return (
    <main className={classes.collectionsContainer}>

      {stateRender[stateMachine]}
      <ExportClassPlanModal
        isOpen={isExportingClassPlan}
        setIsOpen={setIsExportingClassPlan}
        classPlan={classPlan}
      />
    </main >
  )
}
