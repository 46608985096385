import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, useTheme } from '@mui/material'
import { DropdownSelector, OpenDirectionType } from 'components/common'
import useStyle from './styles'

interface IStatusOption {
  title: string
  disableOption?: boolean
}

interface IStatusSelector {
  onChange: (sortOption: any) => void
  statusOptions?: {
    [statusCode: string]: IStatusOption
  }
  dataTestId?: string
  customOptionClassName?: string
  customContainerClassName?: string
  customAuxPlaceholder?: string
  initialStatus?: string
  openDirection?: OpenDirectionType
}

export const StatusSelector = ({
  onChange,
  statusOptions: statusOptionsProps,
  dataTestId,
  customOptionClassName,
  customContainerClassName,
  customAuxPlaceholder,
  initialStatus,
  openDirection = 'top-down'
}: IStatusSelector) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const DEFAULT_STATUS_OPTIONS = {
    ASC: {
      title: t('Ordem crescente')
    },
    DESC: {
      title: t('Ordem decrescente')
    },
    IDLE: {
      title: t('Não iniciadas primeiro')
    },
    INPROGRESS: {
      title: t('Em andamento primeiro')
    },
    DONE: {
      title: t('Concluídas primeiro')
    }
  }

  const statusOptions = statusOptionsProps ?? DEFAULT_STATUS_OPTIONS

  const starter_code =
    initialStatus && (initialStatus in statusOptions)
      ? initialStatus
      : Object.keys(statusOptions)[0]

  const [selected, setSelected] = useState(starter_code)
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)

  const classes = useStyle({ openDirection })

  useEffect(() => {
    setSelected(starter_code)
  }, [initialStatus])

  const handleSelect = (selected: any) => {
    setSelected(selected)
    onChange(selected)
    setIsSelectorOpen(false)
  }

  const handleBackSelector = () => {
    setIsSelectorOpen(oldIsSelectorOpen => !oldIsSelectorOpen)
  }

  const handleKeyDown = (event: React.KeyboardEvent, action: string, disableOption?: boolean) => {
    if (disableOption) return
    if (event.key === 'Enter') {
      if (isSelectorOpen) {
        handleSelect(action)
      } else {
        setIsSelectorOpen(true)
      }
    }
  }

  const renderOptions = () => {
    return Object.entries(statusOptions).map(([action, { title, disableOption }]: [string, IStatusOption], index) => {
      const isActive = action === selected
      return (
        <a
          key={index}
          className={`${classes.optionContainer} ${customOptionClassName} ${isActive && 'active'} ${disableOption && 'disabled'}`}
          onClick={() => handleSelect(action)}
          onKeyDown={(e) => handleKeyDown(e, action, disableOption)}
          style={{ pointerEvents: disableOption ? 'none' : 'auto' }}
          tabIndex={disableOption ? -1 : 0}
          role='button'
          aria-disabled={disableOption}
          aria-selected={isActive}
        >
          {title}
        </a>
      )
    })
  }

  return (
    <DropdownSelector
      auxPlaceholder={customAuxPlaceholder ?? t('Ordenar por')}
      placeholder={(statusOptions as any)[selected].title}
      colorTheme={theme.colorBrand.medium}
      isOpen={isSelectorOpen}
      className={`${classes.dropdownContainer} ${customContainerClassName}`}
      setIsOpen={setIsSelectorOpen}
      backButtonLabel={t('Voltar')}
      handleSelectorClick={handleBackSelector}
      selectedTextClassName={classes.selectedText}
      dataTestId={dataTestId}
      openDirection={openDirection}
    >
      <Grid className={classes.container}>
        {renderOptions()}
      </Grid>
    </DropdownSelector>
  )
}
