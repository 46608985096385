import { IGradeResponse } from './content-unit'
import { IDisciplineResponse } from './discipline'

export interface IUserSessionResponse {
  id: number
  username: string
  email: string
  provider: string
  confirmed: boolean
  blocked: boolean
  created_at: string
  updated_at: string
  name: string
  surname: string
  birth_date: string
  cpf: string | null
  phone: string | null
  parent_phone: string | null
  enrollment: string | null
  profiles: IProfilesSessionResponse[]
  subscriptions: ISubscriptionsSessionResponse[]
  url: string
  accept_terms: boolean
}
export interface IClassSessionResponse {
  id: number
  name: string
  school: string | null
  created_at: string | null
  updated_at: string | null
  school_id: number
  grades: IGradeResponse[]
  school_period: number
  student_limit: number
  active?: boolean
}

export interface IUserSchoolProfileSchoolGroup {
  id: number
  name: string
}

export interface IUserSchoolProfileSchool {
  id: number
  name: string
  school_group: IUserSchoolProfileSchoolGroup
}

export interface IUserSchoolProfileResponse {
  id: number
  user: string
  school: IUserSchoolProfileSchool
  active: boolean
  type: IUserSchoolProfileTypeEnum
}

export enum IUserSchoolProfileTypeEnum {
  student = 'student',
  teacher = 'teacher',
  coordinator = 'coordinator',
  admin = 'admin'
}

export interface ISubscriptionsAnalyticsSessionResponse {
  user_id: number
  profile_id: number
  school_id: number
  school_name: string
  group_id: number
  group_name: string
  grade_id?: string
  grade_name?: string
  grade_code?: string
  segment_id?: string
  segment_name?: string
  segment_code?: string
}

export interface ISubscriptionsSessionResponse {
  id: number
  class: IClassSessionResponse
  taught_disciplines: IDisciplineResponse[]
  grade_year: number
  enrollment: string
  user_school_profile: IUserSchoolProfileResponse
  analytics?: ISubscriptionsAnalyticsSessionResponse
}

export interface ISchoolPeriodResponse {
  created_at: Date
  current: boolean
  end_date: Date
  id: number
  school: number
  start_date: Date
  title: string
  updated_at: Date
  next?: boolean
}

export interface IClassSubscriptionProfile extends ISubscriptionsSessionResponse {
  schoolPeriod: ISchoolPeriodResponse
  actualProfile: IProfilesSessionResponse
}

export interface ISchoolProfileResponse {
  id: number
  name: string | null
  inep: string | null
  created_at: string | null
  updated_at: string | null
  organization: string | null
  slug: string | null
  county_code: number
  enabled: boolean
  cloe_school_id: string
  school_group: string | null
  school_periods: ISchoolPeriodResponse[]
  has_approved_cloe_super?: boolean
  has_cloe_super?: boolean
  is_new_client?: boolean
}

export interface IProfilesSessionResponse {
  id: number
  school: ISchoolProfileResponse
  year: number
  user: number
  type: IUserSchoolProfileTypeEnum
  analytics?: IProfileAnalitycsResponse
  lastUsed: string
}

export interface IProfileAnalitycsResponse {
  user_id?: number
  profile_id?: number
  profile_type?: number
  school_id?: number
  school_name?: string
}
