import { IApiResponse, IMessageErrors } from 'services/types/response'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import Storage from 'utils/storage'
import { toast } from 'components/design-system/Toast/manager'
import { LOGIN } from 'navigation/CONSTANTS'

const client = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL as string })
interface IRequestOptions extends AxiosRequestConfig {
  isLogin?: boolean
  disableToast?: boolean
  params?: any | {
    stopServiceWorking?: boolean | undefined
  }
}

interface IBusinessRules {
  [index: number | string]: {
    message?: string
    action?: () => void
  }
}

const BusinessRules: IBusinessRules = {
  400: {}, // Esse código deve ter mensagem vinda do backend
  403: {
    message: 'Ação não permitida.'
  },
  401: {
    message: 'Sessão Expirada. Redirecionando ao login...',
    action: () => {
      setTimeout(() => {
        Storage.token.remove()
        Storage.props.remove()
        Storage.disciplines.remove()
        window.location.href = LOGIN
      }, (3000))
    }
  },
  500: {
    message: 'Ops... Algo deu errado, tente novamente!'
  }
}

const arrayErrors = (message: any) => {
  if (Array.isArray(message)) {
    const errorMessages: IMessageErrors[] = [].concat.apply([], message?.map(
      (m: any) => m?.messages ?? m
    ))
    if (Array.isArray(errorMessages)) {
      if (errorMessages[0]) {
        // @ts-expect-error
        return errorMessages.reduce((acc, cur) => `${acc}` + cur.message ?? cur, '')
      } else return
    }
    return errorMessages
  }
  return message
}

export const request = async <T>(options: IRequestOptions): Promise<IApiResponse<T>> => {
  const { isLogin, disableToast } = options
  try {
    delete client.defaults.headers.common.Authorization
    delete client.defaults.headers.common.UserSchoolProfile
  } catch (e) {
    console.log('failed to delete client default headers')
  }

  if (!isLogin && Storage.token.get()) {
    client.defaults.headers.common.Authorization = `Bearer ${Storage.token.get()}`

    if (Storage.props.getProfile()) {
      client.defaults.headers.common.UserSchoolProfile = Storage.props.getProfile()?.id
    }
  }

  const onSuccess = (response: AxiosResponse<T>) => {
    return {
      success: true,
      data: response.data,
      status: response.status,
      headers: response.headers
    }
  }

  const onError = (error: any) => {
    if (error.response) {
      if (error.response.status in BusinessRules) {
        const _businessRules = BusinessRules[error.response.status]
        const msg = arrayErrors(_businessRules?.message ?? error.response.data?.message?.message ?? error.response?.data?.message ?? error.response.data.errorMessage)

        if (msg && !disableToast) {
          toast.handler({
            content: msg,
            duration: 10000,
            severity: 'error'
          })
        }

        if (_businessRules.action) {
          _businessRules.action()
        }
      }
    } else if (error.request) {
      console.warn('request', error.request)
    } else {
      console.warn('Error', error.message)
    }

    if (Array.isArray(error?.response?.data?.message)) {
      const errorMessages: IMessageErrors[] = [].concat.apply([], error?.response?.data?.message?.map(
        (m: any) => m?.messages
      ))
      return {
        success: false,
        data: error.data,
        errors: errorMessages
      }
    }

    return {
      success: false,
      data: error.data ?? error.message,
      message: error?.response?.data?.message?.message ?? error?.response?.data?.message ?? error.message,
      status: error?.response?.status
    }
  }

  return await client(options).then(onSuccess).catch(onError)
}
