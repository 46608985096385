import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'

import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import { useTheme } from '@mui/material'
import { Icon } from 'components/design-system'
import { toast } from 'components/design-system/Toast/manager'
import { LoadingWrapper } from 'components/common'

import { mbtoBytes } from 'utils/conversor'

import useStyles, { Text, Description, Center, Box, IconWrapper, DropArea } from './style'
export interface IUploadProps {
  onUpload: <T extends File>(files: T[], event: DropEvent) => void
  availableArchivesTypes: string
  description: string
  maxFileSize: number
  multiple: boolean
  disabled: boolean
  dataTestid?: string | null
  isLoading: boolean
}

export const Upload = (props: IUploadProps) => {
  const {
    onUpload,
    availableArchivesTypes,
    description,
    maxFileSize,
    multiple,
    disabled,
    dataTestid,
    isLoading
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(props)
  const [warningText, setWarningText] = useState(false)
  const [errorsCode, setErrorsCode] = useState<string[]>([])

  const defaultMaxFileSize = 20

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setWarningText(true)
      }, 60000)
      return () => clearTimeout(timer)
    }
  }, [isLoading])

  useEffect(() => {
    if (errorsCode?.length) {
      for (const code of errorsCode) {
        if (code === 'file-too-large') {
          toast.handler({
            content: `${t('O arquivo enviado excedeu o tamanho máximo permitido')} ${maxFileSize ?? defaultMaxFileSize}mb`,
            duration: 60000,
            severity: 'error'
          })
        }
        if (code === 'file-invalid-type') {
          toast.handler({
            content: t('Arquivo em formato não suportado!'),
            duration: 60000,
            severity: 'error'
          })
        }
      }

      setErrorsCode([])
    }
  }, [errorsCode])

  const rejectedFiles = (rejectedFiles: FileRejection[]) => {
    const aggregateErrorCode = rejectedFiles.map((file: FileRejection) => file.errors.map((erro: any) => erro)).reduce((acc: any, erros: any) => {
      erros.map((erro: any) => {
        if (!acc.includes(erro.code)) acc.push(erro.code)
        return null
      })
      return acc
    }, [])

    setErrorsCode(aggregateErrorCode)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: availableArchivesTypes ?? '.jpeg,.jpg,.png,.gif,.webp,.mp4,.mov,.pdf',
    onDropAccepted: onUpload,
    onDropRejected: rejectedFiles,
    maxSize: mbtoBytes(maxFileSize ?? defaultMaxFileSize),
    multiple,
    disabled
  })

  return (
    <DropArea {...getRootProps({ className: 'dropzone' })} data-testid={dataTestid}>
      <input className='upload-input' name='files' type='file' {...getInputProps()} />
      <Box className={classes.paddingInLoading}>
        <LoadingWrapper type='circular' loading={isLoading}>
          <IconWrapper>
            <Icon size='large' iconColor={theme.colorBrand.medium}>
              <UploadOutlinedIcon />
            </Icon>
          </IconWrapper>
          <div>
            <div>
              <Text>
                {multiple ? t('Arraste seus arquivos para cá ou ') : t('Arraste o arquivo para cá ou ')}
                <Text
                  sx={{
                    fontWeight: theme.font.fontWeight.bold,
                    fontSize: theme.font.fontSize.sm,
                    color: theme.colorBrand.medium
                  }}
                >
                  {t('clique aqui')}
                </Text>{' '}
                {t('para escolher')}
              </Text>
            </div>
            <Center>
              <Description>{`${t('Tamanho máximo por arquivo:')} ${maxFileSize ?? defaultMaxFileSize}mb`}</Description>
              <Description>
                {description ??
                  `Formatos de imagem suportados: JPG, PNG, GIF, WEBP Formatos de vídeo suportados:
              MP4 e MOV`}
              </Description>
            </Center>
          </div>
        </LoadingWrapper>
        {isLoading &&
          (!warningText
            ? t(`Anexando arquivos${'...'}`)
            : t('O processo está demorando além do normal. Por favor aguarde.'))
        }
      </Box>
    </DropArea>
  )
}
