import React, { useState } from 'react'
import { CloeCard } from './components/CloeCard'
import { CloeCardContent } from './components/CloeCardContent'
import { Box, Skeleton, useTheme } from '@mui/material'
import useStyle from './style'
import { ICloeProgramResponse } from 'services/types'
import { IClassesTaught } from 'services/types/taught-disciplines'

export const CloeCardSkeleton: React.FC = () => {
  const theme = useTheme()
  return (
    <>
      <Box>
        <Skeleton variant='rectangular' width={304} height={356} sx={{ borderRadius: theme.borderRadius.md }} animation='wave' />
      </Box>
    </>
  )
}

export const ProgramCloeCard = ({ contentUnit, classesTaught }: { contentUnit: ICloeProgramResponse[], classesTaught: IClassesTaught | null }) => {
  const [isOpenContent, setIsOpenContent] = useState(false)
  const [cardContent, setCardContent] = useState<ICloeProgramResponse>(contentUnit?.[0])

  const classes = useStyle()

  const handleOpenContent = (content: ICloeProgramResponse) => {
    setCardContent(content)
    setIsOpenContent(true)
    scrollToCardContent(content.id)
  }

  const handleCloseContent = (contentId: number) => {
    setIsOpenContent(false)
    scrollToCardContent(contentId)
  }

  const scrollToCardContent = (contentId: number) => {
    // DOC: esse timeout precisa estar alinhado com o tempo de transição de abertura do card
    setTimeout(() => {
      const cardElement = document.getElementById(`content-unit-card-${contentId}`)
      if (cardElement) {
        cardElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 400)
  }

  return (
    <>
      <Box className={classes.mainContainer}>
        {
          contentUnit.map(item => {
            return (
              <CloeCard
                key={item.id}
                contentUnit={item}
                onClick={() => !item.wasDeleted && handleOpenContent(item)}
              />
            )
          })
        }
      </Box>
      <CloeCardContent
        cardContent={cardContent}
        isOpenContent={isOpenContent}
        onCloseContent={handleCloseContent}
        classesTaught={classesTaught}
      />
    </>
  )
}
