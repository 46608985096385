import { useEffect, useLayoutEffect, useState } from 'react'
import useStyles from './style'
import { useAtom } from 'jotai'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useTranslation } from 'react-i18next'
import { MY_CLOE_CONTEXT, myCloeContextAtom, useToggleOpenMyCloeAtom } from './atomStore'
import useMyCloeSchedule from 'navigation/hooks/useMyCloeSchedule'
import { CurrentClass, StatusClassHandle } from 'components/common/StatusClassHandle'
import Appcues from 'utils/appcues'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { useMediaQuery, useTheme } from '@mui/material'

export const MyCloe: React.FC = ({ children }) => {
  const { toggleOpenMyCloeAtom } = useToggleOpenMyCloeAtom()
  const [openMyCloe, setIsOpenMyCloe] = useAtom(toggleOpenMyCloeAtom)

  // mediaQuery
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()
  const { openMyCloeHandler, cleanSchedule } = useMyCloeSchedule()
  const [myCloeContext] = useAtom(myCloeContextAtom)

  // state to fix open function not updating screen
  const [showMyCloe, setShowMyCloe] = useState(false)
  const [, setActivityCurrent] = useAtom(activityCurrentAtom)

  const classes = useStyles({ openMyCloe: showMyCloe })

  const handleOpenCloe = (open: boolean) => {
    if (!open && myCloeContext === MY_CLOE_CONTEXT.HOME) {
      setActivityCurrent(null)
      cleanSchedule()
    }

    setShowMyCloe(open)
  }

  useLayoutEffect(() => {
    handleOpenCloe(openMyCloe)

    return () => {
      setIsOpenMyCloe(false)
    }
  }, [])

  useEffect(() => {
    handleOpenCloe(openMyCloe)
  }, [openMyCloe])

  return (
    <StatusClassHandle>
      <CurrentClass>
        <div className={classes.content}>
          <div className={classes.buttonWrapper}>
            <div className={classes.top}></div>
            <button
              className={classes.button}
              onClick={() => {
                openMyCloeHandler()
                Appcues.track('prof_minha_cloe')
              }}
              data-testid='schedule_my_cloe'
            >
              {
                !openMyCloe
                  ? <ArrowBackIosNewIcon
                    style={{ fontSize: '18px', position: 'relative' }}
                  />
                  : <ArrowForwardIosIcon
                    style={{ fontSize: '18px', position: 'relative' }}
                  />
              }
              <span style={{ writingMode: 'vertical-rl' }}>
                {!isMobile && t('Minha Cloe')}
              </span>
            </button>
          </div>
          <div className={classes.childrenWrapper}>
            {children}
          </div>
        </div>
      </CurrentClass>
    </StatusClassHandle>
  )
}
