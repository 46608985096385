import React, { useEffect } from 'react'
import { useRouter } from 'navigation/hooks/useRouter'
import { IGradeTypeEnum } from 'services/types/grade'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { useOnlineStatus } from 'contentCacheManager'
import { useCurricularStore, useStore } from 'store'
import { FeatureFlags, hasFeatureFlag } from 'components/feature-toggle'
import { useParams } from 'react-router-dom'

/*
  TODO: Quando houver a necessidade de incluir o cloe pro como funcionalidade exclusiva, é interessante mudar a forma de validação de exibição. Tirando de store e adicionando outra camada para evitar hacking
*/

export const ShowCloeProMiddleware: React.FC = ({ children }) => {
  const { setShowCloePro, schoolPeriod, profile, subscription } = useStore()
  const { pathname } = useRouter()
  const isOnline = useOnlineStatus()
  const { classId, currentSegment } = useCurricularStore()
  const { gradeTypeCode } = useParams<{ gradeTypeCode: string }>()
  const { isFeatureFlagEnabled } = hasFeatureFlag()

  /**
   * DISABLED DUE TO CLOE SUPER
   */
  const allowedSegments: number[] = [
    // IGradeTypeEnum.EF2
  ]
  const allowedSchools: number[] = [
    // 694, 662,
    // 633, // Paraíso das Águas - Mater Dei/3316
    // 156, // Ibens/3105
    // 977, // Luiz Rosa/Z2NnwwzH8Roh,
    // 674, // Verde que te quero Verde/3179
    // 148, // Centro Educacional de Junqueirópolis/2953
    // 562 // Jim Wilson/3363
  ]

  const disableCloePro = () => {
    setShowCloePro(false)
  }

  const enableCloePro = () => {
    setShowCloePro(true)
  }

  const verifyStatus = () => {
    const actualSegment = gradeTypeCode ? IGradeTypeEnum[gradeTypeCode as keyof typeof IGradeTypeEnum] : currentSegment?.id ? currentSegment?.id : subscription?.class?.grades[0]?.grade_type
    const isAllowedSegment = actualSegment && allowedSegments.includes(actualSegment as IGradeTypeEnum)
    const isAllowedSchool = schoolPeriod?.school && allowedSchools.includes(schoolPeriod.school) && schoolPeriod.current

    // feature toggle
    if (profile?.type === IUserSchoolProfileTypeEnum.student && !isFeatureFlagEnabled(FeatureFlags.cloePro)) {
      disableCloePro()
      return
    }

    if (
      !isOnline ||
      !isAllowedSegment ||
      !isAllowedSchool ||
      !classId
    ) {
      disableCloePro()
      return
    }

    enableCloePro()
  }

  useEffect(() => {
    verifyStatus()
  }, [pathname, isOnline, gradeTypeCode, currentSegment, schoolPeriod, classId, profile])

  return <>{children}</>
}
