export const CHECK_CLASS_PLAN_CREATION = (threadId: number | string, runId: number | string) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/check?threadId=${threadId}&runId=${runId}`

export const GET_CLASS_PLAN = (threadId: number | string) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/get/thread/${threadId}`

export const GET_CLASS_PLAN_ID = (id: number | string) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/get/${id}`

interface IGET_ALL_CLASS_PLANS_Props {
  pageNumber?: number
  pageSize?: number
}
export const GET_ALL_CLASS_PLANS = ({ pageNumber = 1, pageSize = 20 }: IGET_ALL_CLASS_PLANS_Props) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/all?pageNumber=${pageNumber}&pageSize=${pageSize}`

export const GENERATE_CLASS_PLAN = () =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/generate`

export const UPLOAD_FILE_CLASS_PLAN = () =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/upload`

export const UPDATE_CLASS_PLAN = () =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/update`

export const ACTIVATE_CLASS_PLAN = () =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/active`

export const EXPORT_CLASS_PLAN = (id: string | number, formatId: 'Docx' | 'Pdf') =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/plan/export?planId=${id}&formatId=${formatId}`
