
import { Text2 } from 'components/design-system'
import { IFormData } from '../..'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { FormInput } from 'components/common'
import { useEffect } from 'react'
import { IGradeResponse } from 'services/types'
import { LoadingGeneralInfoPage } from './components'
import { useGradeDisciplines } from 'services/segments'
import { useValidateFields } from '../../validators'

interface IGeneralInfoPage {
  stepIndex: number
  formData: IFormData
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>
}

export interface IGrades extends IGradeResponse {
  title: string
  segment: number
}

export const GeneralInfoPage = ({ stepIndex, formData, setFormData }: IGeneralInfoPage) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { isFieldHighlighted } = useValidateFields()

  const handleUpdateSelectedDuration = () => {
    if (formData.duration) return

    handleUpdateField('duration', options.duration[0])
  }

  useEffect(() => {
    handleUpdateSelectedDuration()
  }, [formData.duration])

  const {
    grades,
    disciplines,
    stateMachine
  } = useGradeDisciplines({ grade: formData.grade, onFetch: handleUpdateSelectedDuration })

  useEffect(() => {
    if (!formData.grade && grades.length) {
      handleUpdateField('grade', grades[0])
    }
  }, [grades])

  useEffect(() => {
    if (!formData.discipline && disciplines.length) {
      handleUpdateField('discipline', disciplines[0])
    }
  }, [disciplines, grades])

  const durationOptions = [50, 100, 150, 200]

  const options = {
    grade: grades,
    duration: durationOptions.map(duration => ({ title: `${duration} minutos` })),
    discipline: disciplines.filter(item => item.name !== 'Cloe Expand')
  }

  const selectedIndex = (field: any, placeholderField?: string) => {
    // @ts-expect-error
    const index = options[field].findIndex((el: any) => el[placeholderField ?? 'title'] === formData[field]?.[placeholderField ?? 'title'])
    return index === -1 ? 0 : index
  }

  const handleUpdateField = (field: keyof IFormData, value: any) => {
    setFormData(old => ({
      ...old,
      [field]: value
    }))
  }

  const isThemeFieldHighlighted = isFieldHighlighted('classTheme')
  const isTitleFieldHighlighted = isFieldHighlighted('title')

  const Ready = () => (
    <div className={classes.fieldsContainer}>
      <div className={classes.fieldsContainerRow}>
        <FormInput
          type='selector'
          title={t('Ano/Série')}
          options={options.grade}
          selectedOptionIndex={selectedIndex('grade', 'name')}
          onChange={(value) => handleUpdateField('grade', value)}
          customClassName={`${classes.inputFields} gradeInputField`}
          customFormContainerClass={classes.halfSizeForm}
          placeholderField='name'
          dataTestId='cloe_super_create_lesson_plan_step1_select_year_grade'
        />
        <FormInput
          type='selector'
          title={t('Duração')}
          options={options.duration}
          selectedOptionIndex={selectedIndex('duration')}
          onChange={(value) => handleUpdateField('duration', value)}
          customClassName={`${classes.inputFields} durationInputField`}
          customFormContainerClass={classes.halfSizeForm}
          dataTestId='cloe_super_create_lesson_plan_step1_select_duration'
        />
        <FormInput
          type='selector'
          title={t('Componente curricular')}
          options={options.discipline}
          selectedOptionIndex={selectedIndex('discipline', 'name')}
          placeholderField='name'
          onChange={(value) => handleUpdateField('discipline', value)}
          customClassName={`${classes.inputFields} disciplineInputField`}
          dataTestId='cloe_super_create_lesson_plan_step1_select_curricular_component'
        />
      </div>
      <div className={classes.fieldsContainerRow}>
        <FormInput
          type='text'
          title={t('Tema da aula')}
          onChange={(event) => handleUpdateField('classTheme', event.target.value)}
          customClassName={classes.inputFields}
          text={formData.classTheme}
          maxLength={255}
          mandatory
          warning={isThemeFieldHighlighted}
        />
      </div>
      <div className={classes.fieldsContainerRow}>
        <FormInput
          type='text'
          title={t('Título')}
          onChange={(event) => handleUpdateField('title', event.target.value)}
          customClassName={classes.inputFields}
          text={formData.title}
          maxLength={70}
          mandatory
          warning={isTitleFieldHighlighted}
        />
      </div>
    </div>
  )

  const states = {
    IDLE: <></>,
    ERROR: <></>,
    LOADING: <LoadingGeneralInfoPage />,
    READY: <>{Ready()}</>
  }

  return (
    <div className={classes.container}>
      <Text2 fontSize='md' fontWeight='semibold' colorbrand='medium'>
        {t('Passo 1: Informações gerais')}
      </Text2>

      {states[stateMachine]}
    </div>
  )
}
