import useStyle from './style'
import { Skeleton } from '@mui/material'
import { LibrarySearchSkeleton } from 'components/common/CloeMaterialSearch/components'

export const CollectionsTeacherLoading: React.FC = () => {
  const classes = useStyle()
  return (
    <main className={classes.collectionsContainer}>
      <Skeleton
        variant='text'
        sx={{ width: 248, height: 24 }}
      />

      <div className={classes.header}>
        <div>
          <Skeleton
            variant='circular'
            sx={{ width: 128, height: 128 }}
          />
        </div>
        <div className={classes.headerInfo}>
          <Skeleton
            variant='text'
            sx={{ width: 200 }}
          />
        </div>
      </div>
      <section className={classes.body}>
        <div className={classes.aboutCollection}>
          <Skeleton
            variant='rectangular'
            sx={{ width: '100%', height: 500 }}
          />
        </div>
        <div className={classes.cardsContainer}>
          <LibrarySearchSkeleton mockCount={5} />
        </div>
      </section>
    </main >
  )
}
