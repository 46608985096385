import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  cardContainer: {
    width: '266px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.borderRadius.md,
    border: `1px solid ${theme.colors.neutral.light20}`,
    background: theme.colors.neutral.lightBase,
    padding: theme.spacingInset.xs,
    gap: theme.spacingStack.nano,
    textDecoration: 'none !important',
    height: '170px',
  },
  iconContainer: {
    color: theme.colorBrand.medium,
    '& svg': {
      fontSize: theme.font.fontSize.xl
    }
  },
  text: {
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    height: '100%',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  },
  customAvatar: {
    width: `${theme.font.fontSize.xl} !important`,
    height: `${theme.font.fontSize.xl} !important`,
    fontSize: `${theme.font.fontSize.xxs} !important`
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }

}))
