import { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useStore } from 'store'

import { Grid } from '@mui/material'
import { Text2 } from 'components/design-system'
import ShortcutCard from './components/ShortcutCard'

import CloeAvatar from 'assets/cloe-avatar-sentada-background-incolor.svg'
import CloeWorm1 from 'assets/cloe-worm-home-student1.svg'
import CloeWorm2 from 'assets/cloe-worm-home-student2.svg'
import CloeWorm3 from 'assets/cloe-worm-home-student3.svg'
import CloeWorm4 from 'assets/cloe-worm-home-student4.svg'
import CalendarWithTimer from 'assets/calendar-with-timer.svg'
import StackOfBooks from 'assets/stack-of-books.svg'
import RecordsFolder from 'assets/records-folder.svg'

import { STUDENT_SCHEDULE, CONTENTS_STUDENT, STUDENT_TIMELINE } from 'navigation/CONSTANTS'

import useStyles from './styles'

export const HomeStudent: React.FC = () => {
  const { session } = useStore()

  const styles = useStyles()

  const { t } = useTranslation()

  const WormsBackground = () => (
    [
      { src: CloeWorm1, className: styles.wormBackground1 },
      { src: CloeWorm2, className: styles.wormBackground2 },
      { src: CloeWorm3, className: styles.wormBackground3 },
      { src: CloeWorm4, className: styles.wormBackground4 }
    ].map((cur, i) => (
      <img key={i} src={cur.src} className={cur.className} />
    ))
  )

  const ShortcutCardData = useMemo(() => {
    return [
      {
        title: t('Agenda'),
        imageSrc: CalendarWithTimer,
        description: t('Confira as tarefas planejadas pelo(a) professor(a) para a sua turma e prepare-se para as próximas aulas.'),
        titleBtn: t('Ir para Agenda'),
        goTo: STUDENT_SCHEDULE,
        dataTestId: 'home_page_student_go_to_schedule'
      },
      {
        title: t('Conteúdo'),
        imageSrc: StackOfBooks,
        description: t('Acompanhe o conteúdo aplicado em sala de aula e reforce o aprendizado do que já estudou.'),
        titleBtn: t('Ir para Conteúdo'),
        goTo: CONTENTS_STUDENT,
        dataTestId: 'home_page_student_go_to_content'
      },
      {
        title: t('Meus registros'),
        imageSrc: RecordsFolder,
        description: t('Veja registros da sua evolução durante o ano letivo e momentos importantes com a turma.'),
        titleBtn: t('Ir para Meus registros'),
        goTo: STUDENT_TIMELINE,
        dataTestId: 'home_page_student_go_to_my_records'
      }
    ].map((cur, index) =>
      <ShortcutCard
        key={`ShortcutCard-${cur.title}-${index}`}
        title={cur.title}
        imageSrc={cur.imageSrc}
        description={cur.description}
        titleBtn={cur.titleBtn}
        goTo={cur.goTo}
        dataTestId={cur.dataTestId}
      />
    )
  }, [])

  return (
    <Grid container padding={3} className={styles.container}>
      {WormsBackground()}

      <Grid container className={styles.cloeAvatarContent}>
        <img src={CloeAvatar} />
        <Grid item flexDirection='column' className={styles.welcomeTextContainer}>
          <Text2 fontSize='xl' fontWeight='medium' mobile='xl' colorbrand='medium' className={styles.welcomeText}>
            <Trans i18nKey='WelcomeTextStudent'>
              Olá, <b>{{ name: session?.name }}</b>
            </Trans>
          </Text2>
          <Text2 fontSize='md' fontWeight='medium' mobile='md' colorbrand='medium' className={styles.whatDoYouWant}>
            {t('O que você quer fazer hoje?')}
          </Text2>
        </Grid>
      </Grid>

      <Grid item className={styles.shortcutCardContainer}>
        {ShortcutCardData}
      </Grid>
    </Grid>
  )
}

export default HomeStudent
