import { Close } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { Button, Tag } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { IDisciplineResponse } from 'services/types'
import { ISearchRequest } from 'services/types/library'
import useStyle from '../styles'
import { IGradeTypesResponse } from 'services/types/taught-disciplines'

interface IProps {
  filters: {
    gradeTypes: IGradeTypesResponse[]
    types?: Array<{
      key: string
      name: any
    }>
    taughtDisciplines: IDisciplineResponse[]
  }
  request: ISearchRequest
  onClear: (filter: 'disciplines' | 'gradeTypes' | 'types', value: string | number) => void
  onClearAll: () => void
  page: 'resources' | 'articles' | 'collections'
}

export const LibrarySearchFilters = (props: IProps) => {
  const { request, filters, onClear, onClearAll } = props
  const { t } = useTranslation()
  const classes = useStyle()

  if (!request.disciplines.length && !request.gradeTypes.length && !request.types?.length) {
    return <></>
  }

  const dataTestIds = {
    resources: {
      curricularComponent: 'cloe_super_library_resource_clear_filter_curricular_component',
      resourceType: 'cloe_super_library_resource_clear_filter_resource_type',
      segment: 'cloe_super_library_resource_clear_filter_year_grade',
      clear: 'cloe_super_library_resource_clear_filter',
    },
    articles: {
      curricularComponent: 'cloe_super_library_chapter_clear_filter_curricular_component',
      resourceType: 'cloe_super_library_chapter_clear_filter_resource_type',
      segment: 'cloe_super_library_chapter_clear_filter_year_grade',
      clear: 'cloe_super_library_chapter_clear_filter',
    },
    collections: {
      curricularComponent: 'cloe_super_library_collection_clear_filter_curricular_component',
      resourceType: 'cloe_super_library_collection_clear_filter_resource_type',
      segment: 'cloe_super_library_collection_clear_filter_segment',
      clear: 'cloe_super_library_collection_clear_filter',
    }
  }

  const dataTest = dataTestIds[props.page]

  return (
    <Grid item xs={12} display='flex' flexWrap='wrap' gap={2}>
      {
        request.disciplines.map(dr =>
          <Tag className={classes.libraryFilterTag} dataTestid={dataTest.curricularComponent}>
            {filters.taughtDisciplines.find(df => dr === df.id)?.name}
            <Close
              className={classes.libraryFilterTagClose}
              onClick={() => onClear('disciplines', dr)}
            />
          </Tag>)
      }
      {
        request.gradeTypes?.map(gr =>
          <Tag className={classes.libraryFilterTag} dataTestid={dataTest.segment}>
            {filters.gradeTypes.find(gf => gr === gf.id)?.name}
            <Close
              className={classes.libraryFilterTagClose}
              onClick={() => onClear('gradeTypes', gr)}
            />
          </Tag>)
      }
      {
        request.types?.map(rr =>
          <Tag className={classes.libraryFilterTag} dataTestid={dataTest.resourceType}>
            {t(filters.types?.find(rf => rr === rf.key)?.name)}
            <Close
              className={classes.libraryFilterTagClose}
              onClick={() => onClear('types', rr)}
            />
          </Tag>)
      }
      <Button
        variant='ghost'
        className={classes.libraryClearFilterTag}
        onClick={onClearAll}
        data-testid={dataTest.clear}
      >
        <Close />
        {t('Limpar tudo')}
      </Button>
    </Grid>
  )
}
