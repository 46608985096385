import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Tab, Tabs, Search, TabPanel } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyle from './styles'
import { useMemo, useState } from 'react'
import { ReactComponent as ResourceIcon } from './assets/resource_icon.svg'
import { ReactComponent as ArtcileIcon } from './assets/article_icon.svg'
import { ReactComponent as CollectionIcon } from './assets/collection_icon.svg'
import { ReactComponent as FilterListIcon } from './assets/filter-list.svg'
import { LibrarySearchResources, LibrarySearchArticles, LibrarySearchCollections } from './components'
import { IDisciplineResponse, resourceTypesNames } from 'services/types'
import { useGradeDisciplines } from 'services/segments'
import { useAtom } from 'jotai'
import { selectedLibraryTabAtom } from './atomStore'
import { RenderWhenCondition } from 'utils/wrappers'

interface ICloeMaterialSearch {
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  selectMode?: boolean
  dataTestId?: {
    submit: string
    clear: string
  }
  page: 'class-plan' | 'library'
}

export enum TAB_INDEX_ENUM {
  RECURSOS = 0,
  CAPITULOS = 1,
  COLECOES = 2
}

export const CloeMaterialSearch = ({ searchTerm, setSearchTerm, selectMode = false, dataTestId, page }: ICloeMaterialSearch) => {
  // props
  const { t } = useTranslation()
  const classes = useStyle({ isMobile: false })
  // constants
  const _pageSize = 12
  // states
  const [tabIndex, setTabIndex] = useAtom(selectedLibraryTabAtom)
  const [isFilterSelected, setIsFilterSelected] = useState<boolean>(false)
  // mediaQuery
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // Filters options
  const resourceTypes = useMemo(() => Object.keys(resourceTypesNames).map(key => ({
    key: key,
    name: (resourceTypesNames as any)[key]
  })), [resourceTypesNames])

  const {
    segments,
    allAvailableDisciplines
  } = useGradeDisciplines({})

  const disciplines = useMemo(() => {
    const _disciplines = allAvailableDisciplines
      .reduce<IDisciplineResponse[]>((acum, sub) => {
      sub.disciplines.filter(d => d.type === 'discipline').forEach(disc => {
        if (!acum.some(i => i.id === disc.id)) {
          acum.push(disc)
        }
      })
      return acum
    }, [])
    return _disciplines?.sort((a, b) => a.name.localeCompare(b.name))
  }, [allAvailableDisciplines])

  const resourcesFilter = useMemo(() => ({
    types: resourceTypes ?? [],
    gradeTypes: segments ?? [],
    taughtDisciplines: disciplines ?? []
  }), [resourceTypes, segments, disciplines])

  const articlesFilter = useMemo(() => ({
    gradeTypes: segments ?? [],
    taughtDisciplines: disciplines ?? []
  }), [segments, disciplines])

  return (
    <>
      <Grid item xs={12} mt={3}>
        <Search
          initialPattern={searchTerm}
          initialHasSubmited
          placeholder={
            isMobile
              ? t('Pesquisar')
              : t('Pesquise conteúdos por palavras-chave. Ex: gêneros textuais')
          }
          onSubmit={(pattern) => setSearchTerm(pattern)}
          dataTestId={dataTestId}
          hideAssistiveText
        />
      </Grid>
      {
        searchTerm
          ? (
            <Grid item container mt={3}>
              <Grid item xs={12}>
                <Tabs className={classes.libraryTabs} value={tabIndex} onChange={(_, index) => setTabIndex(parseInt(index))} centered>
                  <Tab
                    className={classes.libraryTab}
                    icon={<ResourceIcon />}
                    iconPosition='start'
                    label={!isMobile || tabIndex === TAB_INDEX_ENUM.RECURSOS ? t('Recursos') : ''}
                    data-testid={page === 'library' ? 'cloe_super_library_menu_resource' : 'cloe_super_create_lesson_plan_step3_menu_resource'}
                  />
                  <Tab
                    className={classes.libraryTab}
                    icon={<ArtcileIcon />}
                    iconPosition='start'
                    label={!isMobile || tabIndex === TAB_INDEX_ENUM.CAPITULOS ? t('Capítulos') : ''}
                    data-testid={page === 'library' ? 'cloe_super_library_menu_chapter' : 'cloe_super_create_lesson_plan_step3_menu_chapter'}
                  />
                  {/* Comentando até o backend ficar pronto */}
                  {/* {
                    !selectMode
                      ? <Tab
                        className={classes.libraryTab}
                        icon={<CollectionIcon />}
                        iconPosition='start'
                        label={!isMobile || tabIndex === TAB_INDEX_ENUM.COLECOES ? t('Coleções') : ''}
                        data-testid={page === 'library' ? 'cloe_super_library_menu_collection' : 'cloe_super_create_lesson_plan_step3_menu_collections'}
                      />
                      : <></>
                  } */}
                  <RenderWhenCondition condition={isMobile}>
                    <div
                      className={classes.libraryFilterButton}
                      onClick={() => setIsFilterSelected(!isFilterSelected)}
                    >
                      <FilterListIcon />
                    </div>
                  </RenderWhenCondition>
                </Tabs>
              </Grid>
              <Grid item xs={12} mt={2}>
                <TabPanel value={tabIndex} index={TAB_INDEX_ENUM.RECURSOS}>
                  <LibrarySearchResources
                    pageSize={_pageSize}
                    searchQuery={searchTerm}
                    filters={resourcesFilter}
                    selectMode={selectMode}
                    isFilterSelected={isFilterSelected}
                    page={page}
                    setIsFilterSelected={() => setIsFilterSelected(!isFilterSelected)}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={TAB_INDEX_ENUM.CAPITULOS}>
                  <LibrarySearchArticles
                    pageSize={_pageSize}
                    searchQuery={searchTerm}
                    filters={articlesFilter}
                    selectMode={selectMode}
                    isFilterSelected={isFilterSelected}
                    setIsFilterSelected={() => setIsFilterSelected(!isFilterSelected)}
                  />
                </TabPanel>
                {
                  !selectMode
                    ? <TabPanel value={tabIndex} index={TAB_INDEX_ENUM.COLECOES}>
                      <LibrarySearchCollections
                        pageSize={_pageSize}
                        searchQuery={searchTerm}
                        filters={articlesFilter}
                        selectMode={selectMode}
                        isFilterSelected={isFilterSelected}
                        setIsFilterSelected={() => setIsFilterSelected(!isFilterSelected)}
                      />
                    </TabPanel>
                    : <></>
                }
              </Grid>
            </Grid>
          )
          : <></>
      }

    </>
  )
}
