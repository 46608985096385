import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { useTheme, TabsProps } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

const useStyles = makeStyles((_theme: CustomTheme) =>
  createStyles({
    tab: {
      width: '100%'
    },
    tabMaxContent: {
      width: 'max-content'
    }
  }))

export const Tabs: React.FC<TabsProps> = props => {
  const theme = useTheme()
  return (
    <MuiTabs
      sx={{
        width: '100%',
        '& .MuiTabs-indicator': {
          backgroundColor: theme.colorBrand.medium
        }
      }}
      {...props}
    />
  )
}

// TODO: removido props por conflito com alguma atualização do MUI/Typescript, ajuste isso no futuro
export const Tab: React.FC<any> = props => {
  const theme = useTheme()
  return (
    <MuiTab
      color={theme.colorBrand.medium}
      sx={{
        flexGrow: 1,
        fontFamily: theme.font.fontFamily,
        fontWeight: theme.font.fontWeight.semibold,
        fontSize: theme.font.fontSize.xs,
        color: theme.colorBrand.medium,
        padding: '16px',
        '&.Mui-selected': {
          color: theme.colorBrand.medium
        }
      }}
      disableRipple
      {...props}
      id={`cloe-tab-${props.id as string}`}
    />
  )
}

export interface TabPanelProps {
  value: number | string
  index: number | string
  tabMaxContent?: boolean
  className?: string
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, tabMaxContent, className }) => {
  const classes = useStyles()
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`cloe-tab-${index}`}
      className={`${className} ${tabMaxContent ? classes.tabMaxContent : classes.tab}`}
    >
      {
        value === index &&
          children
      }
    </div>
  )
}
