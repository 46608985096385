/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import { Grid } from '@mui/material'
import { format, parseISO, isAfter } from 'date-fns'
import { useAtom } from 'jotai'
import { AssignmentLateOutlined, AssignmentTurnedInOutlined, PendingActionsOutlined } from '@mui/icons-material'

import useStyles from './style'
import { Avatar } from 'components/design-system'
import { IStudentsAnswerByQuestionResponse } from 'services/types'
import StudentAnswer from './components/StudentAnswer'
import CardStudentAnswerButtonContent from './components/ButtonContent'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import CardStudentAnswerComments from './components/StudentAnswerComments'
import { currentAnswerCommentOpenAtom } from '../../atomStore'
interface ICardStudentAnswerProps {
  studentAnswer?: IStudentsAnswerByQuestionResponse
}

export const CardStudentAnswer: React.FC<ICardStudentAnswerProps> = ({ studentAnswer }) => {
  const classes = useStyles()

  // atom
  const [activity] = useAtom(activityCurrentAtom)
  const [currentAnswerCommentOpen] = useAtom(currentAnswerCommentOpenAtom)

  const renderIcon = () => {
    if (studentAnswer?.answer?.revision || studentAnswer?.answer?.level) {
      return <AssignmentTurnedInOutlined className={classes.cardStudentAnswerIconAnswer} />
    }

    if (activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate))) {
      return <AssignmentLateOutlined className={classes.cardStudentAnswerIconNoAnswer} />
    }

    return <PendingActionsOutlined className={classes.cardStudentAnswerIconAnswerPending} />
  }

  return (
    <Grid container display='flex' flexDirection='column' justifyContent='space-between' className={classes.cardStudentAnswerContainer} {...(!studentAnswer?.answer && { style: { backgroundColor: 'rgba(230, 230, 230, 0.32)' } })}>
      <Grid item display='flex' flexDirection='row' justifyContent='space-between' >
        <Grid item display='flex' gap={1}>
          <Avatar userName={`${studentAnswer?.user_name} ${studentAnswer?.user_surname || ''}`} sx={{ width: 24, height: 24 }} />

          <Grid item display='flex' flexDirection='column'>
            <span className={classes.cardStudentAnswerTextName}>{`${studentAnswer?.user_name} ${studentAnswer?.user_surname || ''}`}</span>
            <span className={classes.cardStudentAnswerTextDate}>{studentAnswer?.answer?.created_at ? `Enviada em ${format(parseISO(studentAnswer?.answer?.created_at), 'dd/MM/yyyy')}` : ''}</span>
          </Grid>
        </Grid>

        {renderIcon()}
      </Grid>

      <Grid item width='100%' display='flex'>
        <StudentAnswer answer={studentAnswer?.answer} />
      </Grid>

      <CardStudentAnswerButtonContent studentAnswer={studentAnswer} />

      {studentAnswer?.answer && currentAnswerCommentOpen === studentAnswer?.answer?.id && <CardStudentAnswerComments studentAnswer={studentAnswer} />}
    </Grid>
  )
}

export default CardStudentAnswer
