import { IAnswerRecordRequest } from 'services/types'
import { makeDatabaseInstanceOffline } from 'contentCacheManager'
import { getProfile } from 'contentCacheManager/utils'

export const getAnswerOfflineByContent = async (params: Partial<Omit<IAnswerRecordRequest, 'answers'>>) => {
  const database = makeDatabaseInstanceOffline()
  const { id: profileId } = getProfile()

  const found = await database.answersCached.toArray()

  return found.filter(item => (item.requestAnswer.activity === params.activity ||
    item.requestAnswer.class === params.class ||
    item.requestAnswer.content_unit === params.content_unit) &&
    item.userId && item.userId === String(profileId)
  )
}
