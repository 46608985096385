import { atom } from 'jotai'

import { IStudentsAnswerByActivityResponse } from 'services/types'
import { getStudentsAnswerByActivity } from 'services/answer'

export const activeQuestionAtom = atom<number | string>('')
export const studentsAnswerAtom = atom<IStudentsAnswerByActivityResponse[]>([])
export const isLoadingStudentsAnswerAtom = atom<boolean>(true)

export const getStudentsAnswerByActivityAtom = atom(
  null,
  async (get, set, data: { classId: string, activityId: string }) => {
    set(isLoadingStudentsAnswerAtom, true)

    try {
      const response = await getStudentsAnswerByActivity(data.classId, data.activityId)

      if (response.success) {
        set(studentsAnswerAtom, response.data)
      }
      set(isLoadingStudentsAnswerAtom, false)
    } catch (error) {
      set(studentsAnswerAtom, [])
      set(isLoadingStudentsAnswerAtom, false)
    }
  }
)
