import { request } from 'services/base'
import { IApiResponse, IContentUnitResponse } from 'services/types'
import {
  IContentUnit,
  IScheduleByDayResponse,
  IScheduleRequest,
  IScheduleResponse,
  IScheduleTotalByRangeResponse,
  IScheduleActivitiesPendentResponse,
  IClassScheduleResponse
} from 'services/types/schedule'
import {
  SCHEDULES_TOTALS_BY_RANGE,
  SCHEDULES_BY_DAY,
  SCHEDULE_BY_ID,
  CONTENT_UNIT_SCHEDULE,
  CONTENT_UNITS_SCHEDULE,
  CREATE_SCHEDULE_ORIGINAL_CONTENT,
  CREATE_SCHEDULE_EXISTING_CONTENT,
  UPDATE_SCHEDULE_ORIGINAL_CONTENT,
  UPDATE_SCHEDULE_EXISTING_CONTENT,
  GET_PENDENT_ACTIVITIES_STUDENT,
  GET_PENDENT_ACTIVITIES_TEACHER,
  GET_CLASS_SCHEDULES_BY_CONTENT_UNIT,
  CREATE_SCHEDULE_LIBRARY_CONTENT,
  UPDATE_SCHEDULE_LIBRARY_CONTENT,
  CLASS_SCHEDULE_BY_ID
} from './CONSTANTS'

export const getSchedule = async (classId: string, scheduleId: string) =>
  await request<IClassScheduleResponse>({
    url: `${CLASS_SCHEDULE_BY_ID(classId, scheduleId)}`,
    method: 'GET'
  })

export const getScheduleTotalsByRange = async (
  classId: string,
  startDate: string,
  endDate: string,
  disciplineId: string | number | null | undefined
): Promise<IApiResponse<IScheduleTotalByRangeResponse[]>> =>
  await request({
    url: SCHEDULES_TOTALS_BY_RANGE(classId, startDate, endDate, disciplineId)
  })

export const getSchedulesByDay = async (
  classId: string,
  date: string,
  disciplineId: string | number | null | undefined,
  utcOffset: string
): Promise<IApiResponse<IScheduleByDayResponse[]>> =>
  await request({
    url: SCHEDULES_BY_DAY(classId, date, disciplineId, utcOffset)
  })

export const getContentUnitSchedule = async (
  classId: string,
  disciplineId: string,
  title?: string
): Promise<IApiResponse<IContentUnit[]>> =>
  await request({ url: CONTENT_UNIT_SCHEDULE(classId, disciplineId, title) })

export const getContentsUnitsSchedule = async (
  classId: string,
  contentUnitId: string
): Promise<IApiResponse<IContentUnitResponse>> =>
  await request({ url: CONTENT_UNITS_SCHEDULE(classId, contentUnitId) })

export const deleteScheduleById = async (
  scheduleId: string
): Promise<IApiResponse<boolean>> =>
  await request({
    url: SCHEDULE_BY_ID(scheduleId),
    method: 'DELETE'
  })

export const createSchedule = async (
  data: IScheduleRequest
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
): Promise<IApiResponse<IScheduleResponse> | void> => {
  const scheduleTypes = {
    original_content: CREATE_SCHEDULE_ORIGINAL_CONTENT(),
    existing_content: CREATE_SCHEDULE_EXISTING_CONTENT(),
    library_content: CREATE_SCHEDULE_LIBRARY_CONTENT()
  }

  const requestUrl =
    data.type in scheduleTypes ? scheduleTypes[data.type] : false

  if (requestUrl) {
    return await request({
      url: requestUrl,
      method: 'POST',
      data: data
    })
  } else {
    throw new Error('Falha ao encontrar referencia para esse tipo de evento')
  }
}

export const updateSchedule = async (
  scheduleId: string,
  data: IScheduleRequest
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
): Promise<IApiResponse<IScheduleResponse> | void> => {
  const scheduleTypes = {
    original_content: UPDATE_SCHEDULE_ORIGINAL_CONTENT(scheduleId),
    existing_content: UPDATE_SCHEDULE_EXISTING_CONTENT(scheduleId),
    library_content: UPDATE_SCHEDULE_LIBRARY_CONTENT(scheduleId)
  }

  const requestUrl =
    data.type in scheduleTypes ? scheduleTypes[data.type] : false

  if (requestUrl) {
    return await request({
      url: requestUrl,
      method: 'PUT',
      data: data
    })
  } else {
    throw new Error('Falha ao encontrar referencia para esse tipo de evento')
  }
}

interface IGetClassSchedulesByContentUnit {
  classId: string | number
  contentUnitId: string | number
  type?: 'original_content' | 'existing_content'
  limit?: number
}

export const getClassSchedulesByContentUnit = async ({
  classId, contentUnitId, type, limit
}: IGetClassSchedulesByContentUnit): Promise<IApiResponse<IScheduleResponse[]>> =>
  await request({
    url: GET_CLASS_SCHEDULES_BY_CONTENT_UNIT(classId, contentUnitId, type, limit)
  })

interface IgetPendentActivities {
  classId: string | number
  disciplineId?: string | number
  offset?: string | number
  limit?: string | number
}

export const getPendentActivitiesTeacher = async ({
  classId,
  disciplineId,
  offset,
  limit
}: IgetPendentActivities): Promise<IApiResponse<IScheduleActivitiesPendentResponse[]>> =>
  await request({
    url: GET_PENDENT_ACTIVITIES_TEACHER(classId, disciplineId, offset, limit)
  })

export const getPendentActivitiesStudent = async ({
  classId,
  disciplineId,
  offset,
  limit
}: IgetPendentActivities): Promise<IApiResponse<IScheduleActivitiesPendentResponse[]>> =>
  await request({
    url: GET_PENDENT_ACTIVITIES_STUDENT(classId, disciplineId, offset, limit)
  })
