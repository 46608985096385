/* eslint-disable multiline-ternary */
import { useStore } from 'store'
import useStyle from './style'
import CloeAvatar from 'assets/cloe-new-greeting.png'
import { Button, Search2, Text2 } from 'components/design-system'
import { Trans, useTranslation } from 'react-i18next'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import { CLASS_PLAN_CREATE, LIBRARY_SEARCH } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'

export const Header: React.FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const { session } = useStore()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const userName = `${session?.name.split(' ', 1)}`

  const handleCreateClassPlan = () => {
    history.push(CLASS_PLAN_CREATE(0))
  }

  const handleSearch = (searchPhrase: string) => {
    history.push(LIBRARY_SEARCH(searchPhrase))
  }

  return (
    <header className={classes.header}>
      <Box className={classes.headerSeparator}>
        {!isMobile && <Box className={classes.buttonContainer}>
          <Button
            endIcon={<AutoAwesomeOutlinedIcon />}
            variant='primary'
            className={classes.createClassPlanButton}
            onClick={handleCreateClassPlan}
            data-testid={'cloe_super_home_create_class_plan'}
          >
            {t('Criar plano de aula')}
          </Button>
        </Box>}
        <Box className={classes.headerNavContainer}>
          <Box className={classes.greetingsContainer}>
            <Box className={classes.greetingsItem}>
              <Box className={classes.imageContainer}>
                <img src={CloeAvatar} className={classes.cloeAvatar} />
              </Box>
              <Box className={classes.textContainer}>
                {isMobile ? (
                  <Text2 fontSize='xxl' lineHeight='lg' fontWeight='bold' support='colorSupport02' className={classes.userGreetings}>
                    {t('Olá')}!
                  </Text2>
                ) : (
                  <>
                    <Text2 fontSize='xxl' lineHeight='lg' fontWeight='bold' support='colorSupport02' className={classes.userGreetings}>
                      <Trans i18nKey='WelcomeText'>
                        Olá, {{ name: userName }}!
                      </Trans>
                    </Text2>
                    {!isTablet && (
                      <Text2 fontSize='sm' fontWeight='medium' support='colorSupport02'>
                        {t('O que os estudantes vão aprender hoje?')}
                      </Text2>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Search2
              placeholder={isMobile ? t('Pesquise conteúdos') : t('Pesquise conteúdos por palavras-chave. Ex: gêneros textuais')}
              onSubmit={handleSearch}
              dataTestId={{
                clear: 'cloe_super_home_clear_search',
                submit: 'cloe_super_home_submit_search'
              }}
              showSubmitButton={false}
            />
          </Box>
        </Box>
      </Box>
    </header >
  )
}
