import { makeStyles, createStyles } from '@mui/styles'
import { stepConnectorClasses, Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  colorlibConnector: {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: `${theme.colorBrand.medium} !important`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: `${theme.colorBrand.medium} !important`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: `${theme.colorBrand.lightest} !important`,
      borderRadius: 1,
    },
  },
}))
