import OnlyProfile from 'components/common/OnlyProfile'
import { ClassPlanTeacher } from './teacher'
import useStyles from './style'
import { IUserSchoolProfileTypeEnum } from 'services/types'

export const ClassPlan: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <ClassPlanTeacher />
      </OnlyProfile>
    </div>
  )
}
