import {
  IAnswerCached,
  IAnswerCachedStatus,
  OfflineTableExpeditionCachedStatus,
  getExpeditionCached,
  makeDatabaseInstanceOffline
} from 'contentCacheManager'
import { IAnswerResponse } from 'services/types'
import { addAnswerOffline, getAnswerOffline } from '.'

type ICheckExistsAndUpdate = {
  answers: IAnswerResponse[]
}

export const checkExistsAnswerCachedAndUpdate = async (params: Pick<IAnswerCached['requestAnswer'], 'activity' | 'class' | 'content_unit'>, upDateData: ICheckExistsAndUpdate) => {
  const database = makeDatabaseInstanceOffline()
  const existis = await getAnswerOffline(params)

  // verifica se existe no cache
  if (existis) {
    await database.answersCached.update(existis, {
      ...existis,
      status: IAnswerCachedStatus.SENT_SUCCESS,
      requestAnswer: {
        ...existis?.requestAnswer,
        answers: upDateData?.answers
          ? upDateData.answers.map(answer => ({
            question_id: `${answer.question_id}`,
            records: answer.records?.map(record => ({
              id: record.id,
              source_type: Number(record.source_type),
              file_records: record.file_records,
              choice_record: record.choice_record,
              text_record: record.text_record
            })),
            id: answer.id
          }))
          : []
      }
    })
    return
  }

  const expeditionInCache = await getExpeditionCached(Number(params.content_unit))

  // verifica se tem download para essa expedição
  if (expeditionInCache?.status === OfflineTableExpeditionCachedStatus.FULL_DOWNLOADED) {
    const createdAnswersCache = await addAnswerOffline({
      ...params,
      isEditing: false,
      answers: upDateData?.answers
        ? upDateData.answers.map(answer => ({
          question_id: `${answer.question_id}`,
          records: answer.records?.map(record => ({
            id: record.id,
            source_type: Number(record.source_type),
            file_records: record.file_records,
            choice_record: record.choice_record,
            text_record: record.text_record
          })),
          id: answer.id
        }))
        : []
    })

    if (createdAnswersCache) {
      await database.answersCached.update(createdAnswersCache, {
        ...createdAnswersCache,
        status: IAnswerCachedStatus.SENT_SUCCESS
      })
    }
  }
}
