import CloeSuperToggle, { Disabled } from 'components/cloeSuperToggle'
import React from 'react'

export const cloeSuperHomeFeatureFlagOff: React.FC = ({ children }) => {
  return (
    <CloeSuperToggle>
      <Disabled>
        {children}
      </Disabled>
    </CloeSuperToggle>
  )
}
