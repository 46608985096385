import { IResourceResponse, resourceTypesDataTestId } from 'services/types'
import useStyles from './style'
import { resourceTypeResolver } from 'components/common/Resource'
import { Icon } from 'components/design-system/Icon'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Link, Tag } from 'components/design-system'

interface ILinks {
  link: string
  title: string
}

export const NonEmbeddedResource: React.FC<IResourceResponse> = resource => {
  const classes = useStyles()

  // verifica se tem o medias para pegar o link correto do botão
  const links: ILinks[] = []
  if (resource.medias?.length) {
    // se tiver só um media, adota o titulo do recurso, se tiver mais de um, adota o nome dado a cada media
    resource.medias.forEach(cur => links.push({ link: cur.url, title: resource.medias?.length === 1 ? resource.title : cur.name }))
  } else {
    // medias tem prioridade sobre o link direto, eles usavam como fallback no cadastro o link (Não tem o cenário que tem que aparecer o que tem em medias E link)
    if (resource.link) links.push({ link: resource.link, title: resource.title })
  }

  const handleResourceDataTestId = () => {
    if (resource.is_embeded && resource.type === 'image') {
      return resourceTypesDataTestId.image_embed
    } else if (resource.is_embeded && resource.type === 'video') {
      return resourceTypesDataTestId.video_embed
    }

    return resourceTypesDataTestId[resource.type]
  }

  //   {
  //     "id": 17062,
  //     "title": "Por que nós rimos",
  //     "code": "Por que nós rimos",
  //     "source": "CAROL SALLES/UOL",
  //     "type": "site",
  //     "is_embeded": false,
  //     "show_on_library": null,
  //     "description": "<p>Por que rimos e como isso é bom para o nosso corpo?</p>",
  //     "html_content": null,
  //     "link": "https://www.uol.com.br/vivabem/noticias/redacao/2018/07/25/por-que-nos-rimos-e-como-isso-e-bom-para-o-organismo.htm",
  // }

  const Component = ({ title, link }: { title: string, link: string }) => (
    <Link
      variant='primary'
      href={link}
      target='_blank'
      data-testid={handleResourceDataTestId()}
      className={classes.container}
    >
      <div className={classes.nonEmbeddedResource}>
        <div className={classes.type}>
          <Icon size='medium'>
            <OpenInNew />
          </Icon>
        </div>
        <div className={classes.title}>
          {title}
        </div>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: resource.description ?? ''
          }}
        />
        <div className={classes.source}>
          {resource.source}
        </div>
        <div className={classes.tag}>
          <Tag background='rgba(184, 48, 194, 1)' color='white'>{resourceTypeResolver(resource.type)}</Tag>
        </div>
      </div>
    </Link>
  )

  return (
    <>
      {links.map(l => <Component title={l.title} link={l.link} />)}
    </>
  )
}
