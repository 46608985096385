/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useRef, useState } from 'react'
import { CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material'
import { useAtom } from 'jotai'
import { isAfter, isBefore } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCurricularStore, useStore } from 'store'
import Appcues from 'utils/appcues'

import useStyles from './style'
import useIntersectionObserver from 'components/hooks/useIntersectionObserver'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { ActivityControlStatusEnum, IRecordStatusControlRequest } from 'services/types'
import { editStatusControl } from 'services/activity'
import { toast } from 'components/design-system/Toast/manager'
import { Button } from 'components/design-system'
import { CheckOutlined, InfoOutlined, VerifiedOutlined } from '@mui/icons-material'
import Analytics from 'utils/analytics'

export const InfoBar: React.FC = () => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // params
  const { classId } = useParams<{ classId: string }>()

  const entry = useIntersectionObserver(ref)
  const isVisible = !!entry?.isIntersecting

  // state
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)

  // store
  const { subscription } = useStore()

  // atom
  const [activity, setActivity] = useAtom(activityCurrentAtom)
  const { currentDiscipline } = useCurricularStore()

  const handleRecordEventClick = (eventName: string) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...subscription?.analytics,
        discipline_id: currentDiscipline?.id,
        discipline_title: currentDiscipline?.name
      }
    })
  }

  const changeActivityStatus = async () => {
    if (isLoadingRequest) return

    setIsLoadingRequest(true)

    Appcues.track('prof_correcao_avaliacao')

    const data: IRecordStatusControlRequest = {
      activityId: activity?.id ?? 0,
      activityStatus: ActivityControlStatusEnum.RELEASED
    }
    const result = await editStatusControl(classId, data)

    if (result.success) {
      activity && setActivity({ ...activity, activity_status: { has_answer: activity?.activity_status?.has_answer ?? false, status: ActivityControlStatusEnum.RELEASED } })
      toast.handler({
        content: t('Resultados liberados com sucesso.'),
        duration: 10000,
        severity: 'success'
      })
      setIsLoadingRequest(false)
      handleRecordEventClick('releasing_evaluations_results_success')
      return
    }

    toast.handler({
      content: t('Erro na liberação dos resultados. Tente novamente.'),
      duration: 10000,
      severity: 'error'
    })

    setIsLoadingRequest(false)
    handleRecordEventClick('releasing_evaluations_results_error')
  }

  const isExistsNotRevised = activity?.questions?.some(question => !question.all_revised_or_corrected)

  const renderReleaseButton = () => {
    if (activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate)) && !isExistsNotRevised && Number(activity?.activity_status?.status) !== ActivityControlStatusEnum.RELEASED) {
      return (
        <Button
          data-testid='correction_assessment_menu_results_release_correction'
          size='small'
          variant='primary'
          startIcon={<VerifiedOutlined />}
          fullWidth={isMobile}
          className={classes.infoBarButtonsHandles}
          onClick={changeActivityStatus}
        >
          {isLoadingRequest ? <CircularProgress size={20} /> : 'Liberar resultados'}
        </Button>
      )
    }

    if (Number(activity?.activity_status?.status) === ActivityControlStatusEnum.RELEASED) {
      return (
        <Button
          size='small'
          variant='outlined'
          startIcon={<VerifiedOutlined />}
          fullWidth={isMobile}
          className={classes.infoBarButtonsHandles}
          disabled
        >
          Resultados liberados
        </Button>
      )
    }

    return (
      <Button
        size='small'
        variant='outlined'
        startIcon={<VerifiedOutlined />}
        fullWidth={isMobile}
        className={classes.infoBarButtonsHandles}
        disabled
      >
        Liberar resultados
      </Button>
    )
  }

  const renderTextInfo = () => {
    if ((activity?.schedule?.endDate && isBefore(new Date(), new Date(activity?.schedule?.endDate))) || isExistsNotRevised) {
      return <><InfoOutlined /> <span className={classes.infoBarText}>Existem respostas pendentes de avaliação.</span></>
    }

    return <><CheckOutlined /><span className={classes.infoBarText}>Você avaliou todas as respostas.</span></>
  }

  return (
    <>
      <Grid container display='flex' ref={ref} />
      <Grid container className={`${classes.infoBarContainer} ${!isVisible && window.scrollY > 200 && 'bottom-fixed'}`} display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid item display='flex' alignItems='center' gap='14px'>
          {renderTextInfo()}
        </Grid>

        {renderReleaseButton()}
      </Grid>
    </>
  )
}

export default InfoBar
