import { LOGIN } from 'navigation/CONSTANTS'
import { Button, Text2 } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { useProvideAuth } from 'navigation/useProvideAuth'

export const NoCloeSuperApproved = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  const { signout } = useProvideAuth()

  const handleGoToLogin = async () => {
    await signout()
    history.push(LOGIN)
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.image404} />
        <Text2 className={classes.title} fontSize={'xl'} fontWeight={'medium'} colorbrand='medium'>{t('Os termos de aceite da cloe super ainda não foram aceitos.')}</Text2>
        <Text2 className={classes.title} fontSize={'lg'} fontWeight={'medium'} neutral='dark30'>{t('Solicite o aceite à coordenação da sua escola para acessar a plataforma.')}</Text2>
        <Button onClick={handleGoToLogin}>
          {t('Voltar para o login')}
        </Button>
      </div>
    </>
  )
}
