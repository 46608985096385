import { ISubscriptionsSessionResponse, IUserSchoolProps } from 'services/types'
import Storage from 'utils/storage'

export const getSubscription = () => {
  return Storage.props.getSubscription() as ISubscriptionsSessionResponse
}

export const getProfile = () => {
  return Storage.props.getProfile() as IUserSchoolProps
}
