import { useMediaQuery, useTheme } from '@mui/material'
import { ModalWrapper } from 'components/common'
import { Text2 } from 'components/design-system'

import useStyle from './style'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { CLASS_PLAN_CREATE } from 'navigation/CONSTANTS'

interface ICancelModal {
  isOpen: boolean
  handleCancel: () => void
  handleConfirm: () => void
}

export const CancelModal = ({ isOpen, handleCancel, handleConfirm }: ICancelModal) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyle()

  const [wantedPath, setWantedPath] = useState<string>('')

  const history = useHistory()

  const { t } = useTranslation()

  useEffect(() => {
    history.block((prompt) => {
      const wantedPath = prompt.pathname
      const isSamePage = wantedPath.includes(CLASS_PLAN_CREATE(''))
      if (isSamePage) {
        return
      }

      setWantedPath(wantedPath)
      return 'true'
    })

    return () => {
      history.block(() => {
        // This is intentional
      })
    }
  }, [history])

  const onLeave = () => {
    history.block(() => {
      // This is intentional
    })
    history.push(wantedPath)
    setWantedPath('')

    handleConfirm()
  }

  const onCancel = () => {
    setWantedPath('')
    handleCancel()
  }

  return (
    <ModalWrapper
      open={isOpen || !!wantedPath.length}
      onCancel={onCancel}
      onConfirm={onLeave}
      maxWidth='sm'
      footer={{
        background: theme.colors.neutral.light10
      }}
      customClassName={classes.modalContainer}
      fullscreen={isMobile}
      confirmDatatestId='cloe_super_create_lesson_plan_confirm_exit_select_confirm'
      cancelDatatestId='cloe_super_create_lesson_plan_confirm_exit_select_cancel'
      closeDatatestId='cloe_super_create_lesson_plan_confirm_exit_select_close'
    >
      <Text2
        fontSize='lg'
        fontWeight='semibold'
        colorbrand='medium'
      >
        {t('Tem certeza que deseja sair?')}
      </Text2>
      <Text2
        fontSize='sm'
        fontWeight='medium'
        neutral='dark10'
      >
        {t('As informações preenchidas serão descartadas.')}
      </Text2>
    </ModalWrapper>
  )
}
