import { Text, TextField } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import {
  ClassSelector,
  DateSelect,
  DisciplineSelect,
  LibraryContentSelected,
  SendSchedule
} from '../common'
import { ShareOutlined } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { disciplinesAtom, disciplineSelectedAtom, updateScheduleRequestAtom, selectedArticlesAtom, selectedResourcesAtom } from './atomStore'
import useStyle from '../style'
import { useAtomValue } from 'jotai/utils'

export const LibraryContentShare: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const selectedArticles = useAtomValue(selectedArticlesAtom)
  const selectedResources = useAtomValue(selectedResourcesAtom)
  const [disciplines] = useAtom(disciplinesAtom)
  const [disciplineSeleted, setDisciplineSeleted] = useAtom(disciplineSelectedAtom)
  const [scheduleRequest, setScheduleRequest] = useAtom(updateScheduleRequestAtom)

  const updateScheduleRequest = (key: string, value: any) => {
    setScheduleRequest({
      type: 'UPDATE',
      payload: {
        key: key,
        value: value
      }
    })
  }

  return (
    <div className={classes.resourceShareContainer}>
      <Text type='heading' size='small' color='darkest'>
        {t('Compartilhar')}
      </Text>
      <LibraryContentSelected
        selectedArticles={selectedArticles}
        selectedResources={selectedResources}
        emptyStateMessage={t('Use a barra de pesquisa para encontrar materiais e selecione-os clicando no botão Compartilhar')}
        icon={<ShareOutlined />}
        selectedFor='share'
      />
      <ClassSelector
        onCallback={classId => {
          updateScheduleRequest('class', classId)
        }}
        dataTestId='my_cloe_share_select_class'
      />
      <DisciplineSelect
        disciplines={disciplines ?? []}
        selectedDisciplineCode={disciplineSeleted?.code ?? ''}
        onCallback={(d) => {
          setDisciplineSeleted(d)
          updateScheduleRequest('discipline', d?.id)
        }}
        dataTestId='my_cloe_share_select_curricular_component'
      />
      <TextField
        sx={{
          '& .MuiInputBase-input': {
            background: 'rgba(191, 178, 211, 0.16)',
            border: 'none',
            borderRadius: '8px'
          },
          '&.MuiOutlinedInput-root': {
            padding: '0px'
          }
        }}
        multiline
        rows={4}
        value={scheduleRequest.content ?? ''}
        onChange={(e) => updateScheduleRequest('content', e.target.value)}
        variant='outlined'
        id='description'
        label={t('Digite a descrição')}
        assistiveText='Obrigatório'
        mandatory
      />
      <DateSelect
        dataTestId={{
          startDate: 'my_cloe_share_select_start_date',
          startTime: 'my_cloe_share_select_start_hour'
        }}
      />
      <SendSchedule
        onSuccess={() => {
          setScheduleRequest({ type: 'RESET' })
        }}
        onCancel={() => setScheduleRequest({ type: 'RESET' })}
        dataTestIdOnError='my_cloe_share_error'
        dataTestIdOnSuccess='my_cloe_share_success'
        dataTestId={{
          cancel_creation: 'my_cloe_share_select_cancel_share',
          create: 'my_cloe_share_select_send_share'
        }}
      />
    </div>
  )
}
