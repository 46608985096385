import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import LibraryWormBackground from 'assets/library-worm.svg'

export default makeStyles((theme: Theme) => createStyles({
  generalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    height: '100vh'
  },
  cloeAvatar: {
    display: 'flex',
    width: '150px',
    height: '180px',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '140px',
    },
    '& img': {
      height: '100%'
    }
  },
  classPlanContainer: {
    backgroundImage: `url(${LibraryWormBackground})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',

    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: 'calc(100vh - 96px) !important',
    overflowY: 'auto',

    gap: theme.spacingStack.xxs,

    overflowX: 'hidden',

    padding: theme.spacingInset.sm,

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacingStack.xl
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacingInline.xxs,
    maxWidth: '634px'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.md,
    alignItems: 'center',
    paddingBottom: '96px'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',

    padding: theme.spacingInset.sm,
    boxShadow: theme.shadowLevels.level4,
    gap: theme.spacingInline.xxxs,
    backgroundColor: theme.colors.neutral.lightBase,
    justifyContent: 'center',
    zIndex: 999,

    width: 'calc(100% - 40px - 70px)',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 70px - 16px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

  },
  cancelButton: {
    color: `${theme.colorBrand.medium} !important`,
    padding: `${theme.spacingSquish.xs}`,
    height: '48px !important',
    border: 'none !important'
  },
  backNextPreviousButton: {
    background: `${theme.colorBrand.medium} `,
    color: theme.colors.neutral.lightBase,
    padding: `${theme.spacingSquish.xs}`,
    height: '48px !important',
    border: 'none !important',

    '& .Mui-disabled': {
      background: `${theme.colors.neutral.light10} !important`,
    }
  },
  formMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '712px',
    width: '100%',
    flex: 1
  }
}))
