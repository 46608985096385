import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,

    [theme.breakpoints.up('md')]: {
      width: '712px'
    }

  },
  fieldsContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.xxs,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  formInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}))
