import { Grid, Divider, useMediaQuery, useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { EmptyOrErrorMessage } from 'components/common'
import AvatarWithCrossedArms from 'assets/avatar-with-crossed-arms.svg'
import dayjs from 'dayjs'
import { IEvaluationPerformance } from 'services/types'
import { getDeliveryStatus } from '../../helpers'
import { StarBorderOutlined } from '@mui/icons-material'
import { useRouter } from 'navigation/hooks/useRouter'

enum StateMachineEnum {
  EMPTY = 'EMPTY',
  IDLE = 'IDLE',
  MOBILE = 'MOBILE',
  READY = 'READY'
}

interface IDetailedPerformanceEvaluation {
  evaluations: IEvaluationPerformance[]
}

export const DetailedPerformanceEvaluation: React.FC<IDetailedPerformanceEvaluation> = ({ evaluations }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { pathname } = useRouter()

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  useEffect(() => {
    const state = isMobile
      ? StateMachineEnum.MOBILE
      : StateMachineEnum.READY

    setStateMachine(
      evaluations.length
        ? state
        : StateMachineEnum.EMPTY
    )
  }, [evaluations, isMobile])

  const states = {
    EMPTY: (
      <EmptyOrErrorMessage
        image={AvatarWithCrossedArms}
        message='Ainda não existem avaliações liberadas para a turma nesta unidade. As avaliações liberadas aparecerão aqui.'
        reverse
      />
    ),
    IDLE: <></>,
    MOBILE: evaluations.map((e, index) => (
      <Grid container marginTop={1} rowSpacing={3} key={e.id_activity}>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Data final')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {dayjs(e.dt_end_activity).format('DD/MM/YYYY')}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nome')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2
              data-testid='followup_individual_student_evaluation_open'
              fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20' cursorpointer
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                window.open(`${pathname}/content-unit/${e.id_contents}/evaluations/${e.id_activity}`, '_blank')
              }}
            >
              {e.title}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entrega')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {getDeliveryStatus(e.activity_submitted_status)}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nota')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Grid container item alignItems='center' justifyContent='flex-end' columnSpacing={1}>
              <Grid item xs='auto'>
                <StarBorderOutlined fontSize='small' sx={{ color: theme.colors.information.feedbackMedium, marginTop: '4px' }} />
              </Grid>
              <Grid item xs='auto'>
                <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='md' neutral='dark20'>
                  {e.dec_performance ? e.dec_performance?.toLocaleString('pt-BR') : '-'}
                </Text2>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark20'>
                {t('Turma')}: {e.dec_performance_class ? e.dec_performance_class?.toLocaleString('pt-BR') : '-'}
              </Text2>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Situação da atividade')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {e.activity_correction_status}
            </Text2>
          </Grid>
        </Grid>
        {((evaluations.length - 1) !== index) && (
          <Grid container item xs={12}>
            <Divider className={classes.divider} variant='fullWidth' />
          </Grid>
        )}
      </Grid>
    )),
    READY: (
      <Grid container className={classes.tabPanel} rowSpacing={2}>
        <Grid container item alignItems='center' justifyContent='space-around' columnSpacing={2}>
          <Grid item xs={2} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Data final')}
            </Text2>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nome')}
            </Text2>
          </Grid>
          <Grid item xs={2} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entrega')}
            </Text2>
          </Grid>
          <Grid item xs={2} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nota')}
            </Text2>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Situação da avaliação')}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Divider className={classes.divider} variant='fullWidth' />
        </Grid>
        {evaluations.map((e, index) => (
          <React.Fragment key={e.id_activity}>
            <Grid container item alignItems='center' justifyContent='space-around' columnSpacing={2}>
              <Grid item xs={2} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {dayjs(e.dt_end_activity).format('DD/MM/YYYY')}
                </Text2>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Text2
                  data-testid='followup_individual_student_evaluation_open'
                  fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20' cursorpointer
                  style={{ textDecoration: 'underline' }}
                  onClick={() => {
                    window.open(`${pathname}/content-unit/${e.id_contents}/evaluations/${e.id_activity}`, '_blank')
                  }}
                >
                  {e.title}
                </Text2>
              </Grid>
              <Grid item xs={2} className={classes.cell}>
                {getDeliveryStatus(e.activity_submitted_status)}
              </Grid>
              <Grid item xs={2} className={classes.cell}>
                <Grid container item className={classes.cell} alignItems='center' justifyContent='center' columnSpacing={1}>
                  <Grid item xs='auto'>
                    <StarBorderOutlined fontSize='small' sx={{ color: theme.colors.information.feedbackMedium, marginTop: '4px' }} />
                  </Grid>
                  <Grid item xs='auto'>
                    <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='md' neutral='dark20'>
                      {e.dec_performance !== null ? e.dec_performance?.toLocaleString('pt-BR') : '-'}
                    </Text2>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark20'>
                    {t('Turma')}: {e.dec_performance_class !== null ? e.dec_performance_class?.toLocaleString('pt-BR') : '-'}
                  </Text2>
                </Grid>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {e.activity_correction_status}
                </Text2>
              </Grid>
            </Grid>
            {((evaluations.length - 1) !== index) && (
              <Grid container item xs={12}>
                <Divider className={classes.divider} variant='fullWidth' />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
