import { useEffect, useState } from 'react'
import { IContentUnitStatus } from 'services/types'
import { useTranslation } from 'react-i18next'
import { getContentUnitProgress } from 'services/content-unit'

import useStyles from './style'

interface IContentUnitStatusProps {
  contentUnitId?: number | string
  classId?: number | string
  fetchData?: boolean
  initialStatus?: IContentUnitStatus | 'LOADING'
  className?: string
}

export const ContentUnitStatus: React.FC<IContentUnitStatusProps> = ({
  contentUnitId,
  classId,
  fetchData = true,
  initialStatus = 'IDLE',
  className
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [progress, setProgress] = useState<IContentUnitStatus | 'LOADING'>('LOADING')

  useEffect(() => {
    getData()
  }, [initialStatus])

  const getData = async () => {
    if (!fetchData) {
      setProgress(initialStatus)
      return
    }

    if (!contentUnitId || !classId) {
      throw new Error('Expected contentUnitId and classId')
    }

    const response = await getContentUnitProgress(Number(contentUnitId), Number(classId))
    setProgress(response.data?.status || 'IDLE')
  }

  const stateText = {
    LOADING: t('Carregando...'),
    IDLE: t('Não iniciada'),
    INPROGRESS: t('Em andamento'),
    DONE: t('Concluída')
  }

  return (
    <div className={`${classes.contentUnitStatus} ${progress} ${className}`}>{stateText[progress]}</div>
  )
}
