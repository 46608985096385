import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { addTransparencyToColor } from 'utils/colors'

export default makeStyles((theme: Theme) => createStyles({
  collectionsContainer: {
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm,

    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: 'auto',
    minHeight: '100%',

    gap: theme.spacingStack.xxs,

    overflowX: 'hidden',

    paddingTop: theme.spacingInset.sm,
    padding: theme.spacingInset.lg,
    paddingBottom: theme.spacingStack.lg
  },
  backNextPreviousButton: {
    background: `${theme.colorBrand.medium} `,
    color: theme.colors.neutral.lightBase,
    padding: `${theme.spacingSquish.xs}`,
    height: '48px !important',
    border: 'none !important',

    '& .Mui-disabled': {
      background: `${theme.colors.neutral.light10} !important`,
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    background: addTransparencyToColor(theme.colorBrand.lightest, 0.5),
    alignItems: 'center',

    gap: theme.spacingInline.xxs,

    width: '100%',
    height: '100px',
    borderRadius: theme.borderRadius.md,
    padding: `${theme.spacingInset.md} ${theme.spacingInset.sm}`

  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.nano
  },
  skelletonRounded: {
    borderRadius: `${theme.borderRadius.md}!important`
  },
  cardImage: {
    width: '128px',
    height: '128px',
    background: theme.colors.curricularComponents.FILO,
    borderRadius: theme.borderRadius.circular,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  body: {
    display: 'flex',
    flex: 1
  },
  aboutCollection: {
    gridArea: 'about',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxxs
  },
  cardsContainer: {
    gridArea: 'cards',
    display: 'grid'

  },
  loading: {
    width: '100%'
  },
  dangerouslyContent: {
    backgroundColor: addTransparencyToColor(theme.colorBrand.lightest, 0.5),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: `${theme.spacingInset.xs} ${theme.spacingInset.md}`,
    width: '100%',
    borderRadius: theme.borderRadius.sm,

    '& > p > span, > p': {
      marginBlock: 0,
      fontSize: '18px', // theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.bold,
      lineHeight: '32px',
      letterSpacing: '0.16px'
    },
    '& > ol': {
      paddingInlineStart: '20px',
      marginBlock: '8px'
    },
    '& > ol > li > span, & > ol > li': {
      fontSize: '18px', // theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: '32px',
      letterSpacing: '0.16px',
      color: theme.colors.neutral.dark10
    },
  }
}))
