import { useAtom } from 'jotai'
import { classSelectedAtom, disciplinesAtom } from '../LibraryContentShare/atomStore'
import { Text } from 'components/design-system'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SchoolOutlined } from '@mui/icons-material'
import { ClassSelectorModal } from './ClassSelectorModal'
import { useState } from 'react'

const useStyle = makeStyles((theme: Theme) => createStyles({
  classSelectorContainer: {
    padding: theme.spacingInset.xs,
    borderRadius: theme.borderRadius.sm,
    border: `2px solid ${theme.colorBrand.light}`,
    color: theme.colorBrand.light,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    letterSpacing: '0.16px',
    display: 'flex',
    gap: 16,
    cursor: 'pointer',
    '&.selected': {
      border: `2px solid ${theme.colorBrand.medium}`,
      color: theme.colorBrand.medium
    }
  }
}))

interface IProps {
  onCallback: (classId: number) => void
  dataTestId?: string
}

export const ClassSelector: React.FC<IProps> = ({ onCallback, dataTestId }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [classSelected, setClassSelected] = useAtom(classSelectedAtom)
  const [, setDisciplines] = useAtom(disciplinesAtom)

  return (
    <>
      <div
        className={`${classes.classSelectorContainer} ${classSelected && 'selected'}`}
        onClick={() => setShowModal(!showModal)}
        data-testid={dataTestId}
      >
        <SchoolOutlined />
        <Text>
          {
            !classSelected
              ? t('Selecionar turma')
              : classSelected.name
          }
        </Text>
      </div>
      {
        showModal &&
        <ClassSelectorModal
          onClose={() => setShowModal(!showModal)}
          onConfirm={(classSelected, disciplines) => {
            setShowModal(!showModal)
            setClassSelected(classSelected)
            setDisciplines(disciplines)
            onCallback(classSelected.id)
          }}
        />
      }
    </>
  )
}
