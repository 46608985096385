import { Dialog } from '@mui/material'
import useStyles from './style'
import { Button, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { Loading } from 'components/common'
import { useEffect, useState } from 'react'
import { classPlanAtom } from '../../atoms'
import { useAtom } from 'jotai'
import { EXPORT_CLASS_PLAN } from 'services/classPlan/CONSTANTS'
import Analytics from 'utils/analytics'
import { useStore } from 'store'

interface IExportClassPlanModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ExportClassPlanModal = ({ isOpen = false, setIsOpen }: IExportClassPlanModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [classPlan] = useAtom(classPlanAtom)
  const { profile, subscription } = useStore()

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const handleExportClassPlan = async (option: 'Docx' | 'Pdf') => {
    if (!classPlan) return

    setIsLoading(true)

    window.open(EXPORT_CLASS_PLAN(classPlan.id, option), "_blank")

    Analytics.recordEventClick({
      name: option === 'Docx' ? 'cloe_super_create_lesson_plan_step6_download_success_docx' : 'cloe_super_create_lesson_plan_step6_download_success_pdf',
      attributes: {
        ...profile?.analytics,
        ...subscription?.analytics
      }
    })

    setIsOpen(false)
    setIsLoading(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      scroll='paper'
      open={isOpen}
    >
      <div className={classes.modalBody}>
        <div className={classes.infoContainer}>
          <Text2 fontSize='lg' mobile='lg' fontWeight='medium' colorbrand='medium'>
            {t('Qual deve ser o formato de exportação do seu plano de aula?')}
          </Text2>
        </div>
        <div className={classes.loadingContainer}>

          {isLoading
            ? (
              <Loading type='linear' className={classes.loading} />
            )
            : (
              <>
                <Button
                  variant='primary'
                  className={classes.button}
                  onClick={async () => await handleExportClassPlan('Docx')}
                  data-testid='cloe_super_create_lesson_plan_step6_export_docx'
                >
                  {t('DOCX')}
                </Button>
                <Button
                  variant='primary'
                  className={classes.button}
                  onClick={async () => await handleExportClassPlan('Pdf')}
                  data-testid='cloe_super_create_lesson_plan_step6_export_pdf'
                >
                  {t('PDF')}
                </Button>
              </>
            )}
        </div>
      </div>
    </Dialog>
  )
}
