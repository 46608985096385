import { createTheme } from '@mui/material/styles'
import { ColorBrand } from './types/CustomTheme'

export interface ColorBrandProfiles {
  teacher: ColorBrand
  student: ColorBrand
  coordinator: ColorBrand
  admin: ColorBrand
  [key: string]: ColorBrand
}

const colorBrand: ColorBrandProfiles = {
  teacher: {
    darkest: '#3a2658',
    dark: '#4d3275',
    medium: '#603F92',
    light: '#9079b3',
    lightest: '#d4cce2'
  },
  student: {
    darkest: '#50134e',
    dark: '#6a1968',
    medium: '#851F82',
    light: '#c28fc1',
    lightest: '#e7d2e6'
  },
  coordinator: {
    darkest: '#2D3A66',
    dark: '#3C4E88',
    medium: '#4B61AA',
    light: '#93A0CC',
    lightest: '#DBDFEE'
  },
  admin: {
    darkest: '#1D3E52',
    dark: '#336C90',
    medium: '#509FD1',
    light: '#80B8DD',
    lightest: '#B6D7EB'
  }
}

export const defaultTheme = (userType: string) => ({
  colorBrand: colorBrand[userType],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  colors: {
    neutral: {
      darkBase: '#141414',
      dark10: '#2C2C2C',
      dark20: '#434343',
      dark30: '#5B5B5B',
      dark40: '#727272',
      lightBase: '#FFFFFF',
      light10: '#E6E6E6',
      light20: '#CCCCCC',
      light30: '#B3B3B3',
      light40: '#999999'
    },
    positive: {
      feedbackDarkest: 'rgba(0, 53, 40)',
      feedbackDark: 'rgba(0, 88, 67)',
      feedbackMedium: 'rgba(0, 176, 134)',
      feedbackLight: 'rgba(77, 200, 170)',
      feedbackLightest: 'rgba(153, 223, 207)'
    },
    warning: {
      feedbackDarkest: 'rgba(128, 92, 0)',
      feedbackDark: 'rgba(179, 129, 0)',
      feedbackMedium: 'rgba(255, 184, 0)',
      feedbackLight: 'rgba(255, 212, 102)',
      feedbackLightest: 'rgba(255, 227, 153)'
    },
    negative: {
      feedbackDarkest: 'rgb(121, 11, 11)',
      feedbackDark: 'rgb(161, 14, 14)',
      feedbackMedium: 'rgb(201, 18, 18)',
      feedbackLight: 'rgb(223, 113, 113)',
      feedbackLightest: 'rgb(233, 160, 160)'
    },
    information: {
      feedbackDarkest: '#1A4266',
      feedbackDark: '#24588A',
      feedbackMedium: '#42A4FF',
      feedbackLight: '#8EC8FF',
      feedbackLightest: '#C6E4FF'
    },
    support: {
      colorSupport01: '#8033AA',
      colorSupport02: '#B830C2',
      colorSupport03: '#3E1752',
      colorSupport04: '#3F2DAA'
    },
    contentType: {
      default: '#697691',
      evaluation: '#423DFD',
      activity: '#007E77'
    },
    curricularComponents: {
      MAT: '#B2BDE5',
      CIE: '#FFB394',
      EI: '#E1EA7C',
      ING: '#CD91EA',
      BI: '#64A6FF',
      BI_V2: '#CD91EA',
      IT_CUF: '#BFB2D3',
      IT: '#BCE39C',
      BIO: '#48BCD1',
      SOCIO: '#FCBF63',
      QUI: '#DD84DF',
      G21: '#98C6DA',
      LP: '#F9A2A4',
      FILO: '#55D6C2',
      GEO: '#FCB7D9',
      HIST: '#E5E5CA',
      FIS: '#0CD4F0',
      ART: '#FFE8A4',
      NA_SC: '#FFB394',
      SO_ST: '#FCB7D9',
      LANG: '#FFE8A4',
      MATH: '#B2BDE5',
      DEFAULT: 'rgba(191, 178, 211, 0.64)',
      ALL: colorBrand[userType].medium
    },
    bgCurricularComponents: {
      MAT: 'rgba(179, 190, 230, 0.32)',
      CIE: 'rgba(255, 178, 148, 0.32)',
      EI: 'rgba(224, 234, 123, 0.32)',
      ING: 'rgba(205, 145, 234, 0.32)',
      BI: 'rgba(100, 166, 255, 0.32)',
      BI_V2: 'rgba(205, 145, 234, 0.32)',
      IT_CUF: 'rgba(191, 178, 211, 0.64)',
      IT: 'rgba(188, 227, 156, 0.32)',
      BIO: 'rgba(72, 188, 209, 0.32)',
      SOCIO: 'rgba(252, 191, 99, 0.32)',
      QUI: 'rgba(221, 132, 223, 0.32)',
      G21: 'rgba(152, 198, 218, 0.32)',
      LP: 'rgba(249, 162, 164, 0.32)',
      FILO: 'rgba(85, 214, 194, 0.32)',
      GEO: 'rgba(252, 183, 217, 0.32)',
      HIST: 'rgba(229, 229, 202, 0.32)',
      FIS: 'rgba(12, 212, 240, 0.32)',
      ART: 'rgba(255, 232, 164, 0.32)',
      NA_SC: 'rgba(255, 179, 148, 0.32)',
      SO_ST: 'rgba(252, 183, 217, 0.32)',
      LANG: 'rgba(255, 232, 164, 0.32)',
      MATH: 'rgba(178, 189, 229, 0.32)',
      DEFAULT: 'rgba(191, 178, 211, 0.64)',
      ALL: colorBrand[userType].medium
    },
    timelineRecordsItems: {
      activity: '#007E77',
      evaluation: '#4E4AC9',
      classRecord: '#697691',
      marco: '#B830C2'
    }
  },
  borderRadius: {
    none: '0',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    pill: '999px',
    circular: '50%'
  },
  borderThickness: {
    none: '0',
    hairline: '1px',
    thin: '2px',
    thick: '4px',
    heavy: '8px'
  },
  spacingStack: {
    quarck: '4px',
    nano: '8px',
    xxxs: '16px',
    xxs: '24px',
    xs: '32px',
    sm: '40px',
    md: '48px',
    lg: '56px',
    xl: '64px',
    xxl: '80px',
    xxxl: '120px',
    huge: '160px',
    giant: '200px'
  },
  spacingInset: {
    quarck: '2px',
    nano: '4px',
    xxs: '8px',
    xs: '16px',
    sm: '24px',
    md: '32px',
    lg: '40px'
  },
  spacingSquish: {
    quarck: '4px 8px',
    nano: '8px 16px',
    xs: '16px 24px',
    sm: '16px 32px',
    md: '16px 40px'
  },
  spacingInline: {
    quarck: '4px',
    nano: '8px',
    xxxs: '16px',
    xxs: '24px',
    xs: '32px',
    sm: '40px',
    md: '48px',
    lg: '64px',
    xl: '80px'
  },
  opacityLevels: {
    semiOpaque: '0.8',
    intense: '0.64',
    medium: '0.32',
    light: '0.16',
    semiTransparent: '0.08'
  },
  shadowLevels: {
    level1: '0px 2px 16px rgba(20, 20, 20, 0.16)',
    level2: '0px 8px 16px rgba(20, 20, 20, 0.16)',
    level3: '0px 8px 24px rgba(20, 20, 20, 0.16)',
    level4: '0px 16px 48px rgba(20, 20, 20, 0.16)',
    level5: '0px 16px 56px rgba(20, 20, 20, 0.16)'
  },
  font: {
    spacing: {
      default: '0em',
      distant: '0.01em'
    },
    lineHeight: {
      xxs: '16px', // '1em', // 16px
      xs: '24px', // '1.5em', // 24px
      sm: '32px', // '2em', // 32px
      md: '40px', // '2.5em', // 40px
      lg: '48px', // '3em', // 48px
      xl: '56px', // '3.5em', // 56px
      xxl: '64px', // '4em', // 64px
      xxxl: '72px' // '4.5em' // 72px
    },
    fontFamily: 'Montserrat',
    fontWeight: {
      extralight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900
    },
    fontSize: {
      xxxs: '10px',
      xxs: '12px',
      xs: '14px',
      sm: '16px',
      md: '20px',
      lg: '24px',
      xl: '32px',
      xxl: '40px',
      xxxl: '48px',
      xxxxl: '56px',
      display: '64px'
    }
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: '12px',
      height: '12px'
    },
    '&::-webkit-scrollbar-button': {
      width: '12px',
      height: '12px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#cccccc',
      border: '0px none transparent',
      borderRadius: '8px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#cccccc'
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#cccccc'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      border: '0px none transparent',
      borderRadius: '8px'
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track:active': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent'
    }
  }

})

export const theme = (userType: string) => createTheme({
  ...defaultTheme(userType)
})
