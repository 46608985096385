
import { Alert, Text2 } from 'components/design-system'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { Editor } from 'components/common/Editor'
import { updateClassPlan } from 'services/classPlan/classPlanService'
import { toast } from 'components/design-system/Toast/manager'
import { classPlanAtom } from '../../atoms'
import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useUpdateAtom } from 'jotai/utils'
import { _selectedArticlesAtom, _selectedResourcesAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'

interface IGeneratedClassPlan {
  stepIndex: number
}

export const GeneratedClassPlanComponent = ({ stepIndex }: IGeneratedClassPlan) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [classPlan] = useAtom(classPlanAtom)

  const resetResources = useUpdateAtom(_selectedResourcesAtom)
  const resetArticles = useUpdateAtom(_selectedArticlesAtom)

  useEffect(() => {
    cleanSelectedResources()
  }, [])

  const cleanSelectedResources = () => {
    resetResources([])
    resetArticles([])
  }

  const handleUpdatePlan = async (html: string) => {
    try {
      if (!classPlan?.id || !html) return

      const response = await updateClassPlan({ html, id: classPlan.id })

      if (!response.success) throw new Error()

    } catch (err: any) {
      toast.handler({
        content: t('Não conseguimos sincronizar o plano de aula. Tente novamente mais tarde!'),
        duration: 1000,
        severity: 'error'
      })
    }
  }

  return (
    <div className={classes.container}>
      <Text2 fontSize='md' fontWeight='semibold' colorbrand='medium'>
        {t('Passo 6: Plano de aula')}
      </Text2>

      <div className={classes.fieldsContainer}>
        <Alert content={t('Atenção: o plano de aula foi gerado automaticamente por inteligência artificial. Embora o sistema tenha sido treinado, é possível que o conteúdo contenha erros. Revise e faça as modificações necessárias antes de utilizar esse plano.')} severity='info' />
      </div>

      <div className={classes.fieldsContainer}>
        <Editor
          onBlur={handleUpdatePlan}
          className={classes.text}
          initialHtml={classPlan?.html ?? ''}
        />
      </div>
    </div>
  )
}

export const GeneratedClassPlan = React.memo(GeneratedClassPlanComponent)
