import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  tag: {
    fontSize: theme.font.fontSize.xxs,
    background: theme.colorBrand.lightest,
    color: theme.colorBrand.dark,
    '&.disabled': {
      cursor: 'not-allowed'
    }
  },
  closeButton: {
    fontSize: theme.font.fontSize.xxs + '!important',
    padding: '0 !important',
    height: theme.spacingInset.md + '!important'
    // '& svg': {
    //   fontSize: theme.font.fontSize.xxs + '!important'
    // }
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '580px',
    boxShadow: theme.shadowLevels.level4,
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm,
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  modalHeader: {
    padding: theme.spacingInline.quarck,
    borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
    backgroundColor: '#E6E6E652',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  modalFooter: {
    padding: theme.spacingInline.xxxs,
    borderRadius: `0 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm}`,
    backgroundColor: '#E6E6E652',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacingInline.nano,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: '0',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacingInline.xxxs,
    margin: theme.spacingInline.xxxs,
    marginBottom: theme.spacingStack.lg,
    textAlign: 'center'
  },
  confirmModalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacingInline.xxxs,
    margin: theme.spacingInline.xxxs
  },
  tagsContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: theme.spacingInline.xxxs,
    padding: theme.spacingInline.nano,
    justifyContent: 'center',
    fontSize: theme.font.fontSize.sm,
    overflowX: 'auto',
    transition: 'all ease-in-out 0.2s',
    lineHeight: theme.font.lineHeight.xs,
    fontWeight: theme.font.fontWeight.semibold,
    '&.active': {
      flexFlow: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    }
  },
  classTag: {
    cursor: 'pointer',

    '&.disabled': {
      cursor: 'not-allowed'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },
  modalItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.neutral.light10}`,
    borderRadius: theme.borderRadius.sm,
    width: '100%',
    textAlign: 'center'
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.neutral.light10}`,
    borderRadius: theme.borderRadius.sm,
    width: '100%',
    padding: theme.spacingInline.xxs,
    gap: theme.spacingInline.xxxs,
    textAlign: 'center'
  },
  modalButton: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacingInset.lg + '!important',
      fontSize: theme.font.fontSize.xs + '!important',
      width: '100%'
    }
  }
}))
