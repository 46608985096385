import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  headerSeparator: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacingStack.xxxs
  },
  headerNavContainer: {
    display: 'flex',
    height: 'auto',
    flexGrow: 0,
    width: '100% !important',
    justifyContent: 'center',
  },
  greetingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '50%',
    '@media (max-width:1200px)': {
      maxWidth: '100%',
    }
  },
  userGreetings: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  createClassPlanButton: {
    background: `${theme.colors.support.colorSupport02} !important`,
    color: theme.colors.neutral.lightBase,
    padding: `${theme.spacingSquish.xs}`,
    height: '48px !important',
    border: 'none !important'
  },
  cloeAvatar: {
    width: '112px',
    height: '147px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '82px',
    [theme.breakpoints.down('md')]: {
      height: '100px',
      justifyContent: 'center',
      width: '55%'
    }
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    maxWidth: '47%',
    '@media (max-width:1200px)': {
      maxWidth: '90%',
    }
  },
  greetingsItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  }
}))
