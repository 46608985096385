import { Grid, useTheme } from '@mui/material'
import { TextField, Button } from 'components/design-system'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  scheduleAtom,
  activitySelectTitleAtom,
  activityRemovedQuestionsAtom,
  updateContentSelectAtom,
  openActivityQuestionsSelectModalAtom
} from '../atomStore'
import useStyle from './style'
import { useAtomValue } from 'jotai/utils'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

export const ActivityInfoSelect = () => {
  // atoms
  const [schedule, setSchedule] = useAtom(scheduleAtom)
  const [activitySelectTitle, setActivitySelectTitle] = useAtom(activitySelectTitleAtom)
  const [activitySelectDescription, setActivitySelectDescription] = useState<string | null>(schedule?.content ?? null)
  const [activityRemovedQuestions, setActivityRemovedQuestions] = useAtom(activityRemovedQuestionsAtom)
  const [, setOpenQuestionsModal] = useAtom(openActivityQuestionsSelectModalAtom)
  const activityCurrent = useAtomValue(activityCurrentAtom)
  const [updateContentSelect, setUpdateContentSelect] = useAtom(updateContentSelectAtom)

  const isUpdate = !!schedule?.id

  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyle()

  // not able to select multiple activities
  let availableQuestions = updateContentSelect?.length ? updateContentSelect[0]?.questions : []
  if (isUpdate) {
    availableQuestions = updateContentSelect?.length ? updateContentSelect[0]?.origin?.questions ?? availableQuestions : []
  }

  const showIncludedQuestions = !(isUpdate && !availableQuestions?.length)

  const selectedQuestionsText = activityRemovedQuestions?.length === 0 ? t('Todas') : availableQuestions?.map((question, index) => activityRemovedQuestions.includes(String(question.id)) ? null : index + 1).filter(el => !!el).join(', ')

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivitySelectTitle(event.target.value)
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newContent = event.target.value

    setSchedule(prev => {
      const newScheduleObjectAtom = {
        ...prev,
        content: newContent
      }

      return newScheduleObjectAtom
    })
    setActivitySelectDescription(newContent)
  }

  const cleanAtoms = () => {
    setActivitySelectTitle(null)
    setActivitySelectDescription(null)
    setActivityRemovedQuestions([])
  }

  const handleEditClick = () => {
    setOpenQuestionsModal(true)
  }

  useEffect(() => {
    if (schedule === null) {
      void cleanAtoms()
    }
  }, [schedule])

  useEffect(() => {
    if (activityCurrent) {
      const content = { ...activityCurrent, suggestedApplicationType: activityCurrent?.suggested_application_type, type: null, ...(updateContentSelect?.[0] ? { ...updateContentSelect?.[0] } : {}) }
      setUpdateContentSelect([content])
    }
  }, [activityCurrent])

  useEffect(() => {
    if (!isUpdate) return

    const originalQuestionsIds = updateContentSelect?.[0]?.origin?.questions?.map(({ id }) => String(id))
    const actualQuestionsIds = updateContentSelect?.[0]?.questions?.map(({ origin }) => String(origin))

    const removedQuestionsIds = originalQuestionsIds?.filter(id => !actualQuestionsIds?.includes(id))

    if (!removedQuestionsIds) return

    setActivityRemovedQuestions(removedQuestionsIds)
  }, [updateContentSelect])

  if (!showIncludedQuestions) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{
        padding: `${theme.spacingInline.xs} ${theme.spacingInline.xxxs} 0`,
        paddingTop: theme.spacingStack.xxxs,
        display: 'grid',
        gridTemplateColumns: '1fr',
        borderTop: `1px solid ${theme.colors.neutral.light10}`
      }}
      rowGap={theme.spacingStack.xxxs}
    >
      <TextField
        sx={{
          '& .MuiInputBase-input': {
            background: 'rgba(191, 178, 211, 0.16)',
            border: 'none',
            borderRadius: theme.borderRadius.sm
          }
        }}
        maxLength={50}
        onChange={handleChangeTitle}
        variant={'outlined'}
        id={'title'}
        label={t('Título')}
        value={activitySelectTitle ?? ''}
        assistiveText='Obrigatório'
        mandatory
        showLengthCounter
        disabled={isUpdate}
      />

      <TextField
        sx={{
          '& .MuiInputBase-input': {
            background: 'rgba(191, 178, 211, 0.16)',
            border: 'none',
            borderRadius: theme.borderRadius.sm
          },
          '&.MuiOutlinedInput-root': {
            padding: '0px'
          }
        }}
        multiline
        rows={4}
        value={activitySelectDescription ?? ''}
        onChange={handleChangeDescription}
        variant={'outlined'}
        id={'description'}
        label={t('Digite a descrição')}
        assistiveText=' '
        maxLength={500}
        showLengthCounter
        disabled={isUpdate}
        mandatory={false}
      />
      {
        showIncludedQuestions && (
          <>
            <span style={{ color: theme.colorBrand.medium, fontWeight: theme.font.fontWeight.semibold }} >{t('Questões incluídas')}</span>

            <Grid className={classes.selectedQuestionsField}>
              {selectedQuestionsText}
            </Grid>
            <Button
              variant='outlined'
              className={classes.editButton}
              onClick={handleEditClick}
              disabled={isUpdate}
            >
              {t('Editar')}
            </Button>
          </>
        )
      }

    </Grid>
  )
}
