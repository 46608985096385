import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { Grid, useTheme } from '@mui/material'
import { InputFilePreview } from 'components/common'
import { TextField, Alert } from 'components/design-system'

import { getFileExtension } from 'utils/files'

import {
  scheduleAtom,
  updateScheduleContentAtom,
  updateScheduleTitleAtom,
  updateScheduleFileRecordsAtom,
  inUploadingProcess
} from '../atomStore'

import useStyle from './style'

interface ICardInfoProps {
  type?: 'original_content' | 'existing_content'
  isMaterialVision?: boolean
}

export const CardInfo = ({ type, isMaterialVision = false }: ICardInfoProps) => {
  // atoms
  const [schedule] = useAtom(scheduleAtom)
  const [eventContent, updateScheduleContent] = useAtom(updateScheduleContentAtom)
  const [eventTitle, updateScheduleTitle] = useAtom(updateScheduleTitleAtom)
  const [, setInUploadingProcess] = useAtom(inUploadingProcess)
  const [files, setScheduleFileRecords] = useAtom(updateScheduleFileRecordsAtom)
  const [hasMOVFiles, setHasMOVFiles] = useState(false)

  const theme = useTheme()
  const classes = useStyle()

  const { t } = useTranslation()

  const defaultVisionDataTestIds = {
    upload: 'event_without_expedition_click_here_to_upload_event_files'
  }

  const materialVisionDataTestIds = {
    upload: 'teacher_materials_add_upload_files'
  }

  const dataTestIds = isMaterialVision ? materialVisionDataTestIds : defaultVisionDataTestIds

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    void updateScheduleTitle(event.target.value)
  }

  const handleChangeContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateScheduleContent(event.target.value)
  }

  const cleanAtoms = () => {
    void updateScheduleTitle('')
    updateScheduleContent('')
  }

  useLayoutEffect(() => {
    void cleanAtoms()
  }, [])

  useEffect(() => {
    if (schedule === null) {
      void cleanAtoms()
    }
  }, [schedule])

  useEffect(() => {
    const fileExtensions = files?.map(file => getFileExtension(file.ext))
    setHasMOVFiles(!!fileExtensions?.includes('mov'))
  }, [files])

  return (
    schedule?.content_unit && type !== 'original_content'
      ? <React.Fragment />
      : <Grid
        container
        sx={{
          padding: theme.spacingSquish.nano,
          paddingTop: '30px',
          display: 'grid',
          gridTemplateColumns: '1fr'
        }}
        rowGap={theme.spacingStack.xxxs}
      >
        <>
          <TextField
            sx={{
              '& .MuiInputBase-input': {
                background: 'rgba(191, 178, 211, 0.16)',
                border: 'none',
                borderRadius: theme.borderRadius.sm
              }
            }}
            onChange={handleChangeTitle}
            variant={'outlined'}
            id={'title'}
            label={t('Digite o título')}
            value={eventTitle ?? ''}
            assistiveText='Obrigatório'
            maxLength={50}
            mandatory
          />
          <p className={`${classes.helpText50} ${(eventTitle?.length === 50) && 'limit'}`}>{t('HelpTextTitle', { titleNumber: `${!eventTitle?.length ? 0 : eventTitle.length}` })}</p>
        </>
        <TextField
          sx={{
            '& .MuiInputBase-input': {
              background: 'rgba(191, 178, 211, 0.16)',
              border: 'none',
              borderRadius: theme.borderRadius.sm
            },
            '&.MuiOutlinedInput-root': {
              padding: '0px'
            }
          }}
          multiline
          rows={4}
          value={eventContent ?? ''}
          onChange={handleChangeContent}
          variant={'outlined'}
          id={'description'}
          label={t('Digite a descrição')}
          assistiveText='Obrigatório'
          mandatory
        />
        {hasMOVFiles &&
          <Alert
            content={t('Você anexou um arquivo .MOV, caso esteja usando o navegador Chrome e Firefox pode ser que o vídeo não reproduza. Neste caso, será necessário baixá-lo para conseguir reproduzir o vídeo.')}
            severity='warning'
          />
        }
        <Grid container sx={{
          padding: theme.spacingInline.xxxs,
          border: '1px solid #CCCCCC',
          borderRadius: theme.borderRadius.md,
          textAlign: 'center',
          display: 'flex',
          gap: '1rem',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          // mobile
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacingInline.xxxs
          }
        }}>
          <InputFilePreview
            availableArchivesTypes='.png,.jpeg,.jpg,.gif,.webp,.mp4,.mov,.pdf,.mp3,.wav,.m4a,.ogg'
            reset={files === null}
            onChange={files => setScheduleFileRecords(files)}
            onProgress={progress => {
              if (progress > 1) setInUploadingProcess(true)
            }}
            description={t('fileFormats', { joinArrays: '\n' })}
            maxFileSize={100}
            maxFileName={14}
            descriptionStyle={{
              color: theme.colors.neutral.dark20,
              fontWeight: theme.font.fontWeight.semibold
            }}
            titleStyle={{
              color: theme.colors.neutral.dark20,
              fontWeight: theme.font.fontWeight.medium
            }}
            dataTestid={dataTestIds.upload}
            initialPreviews={files?.map(file => ({
              transaction_id: file.transaction_id,
              id: file.id,
              url: file.url,
              name: file.url,
              thumb: file.url,
              ext: file.url
            }))}
          />
        </Grid>
      </Grid>
  )
}
