import { useRef } from 'react'

import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined'
import { Icon, Avatar } from 'components/design-system'

import { getFileExtension } from 'utils/files'

import { Wrapper, Title, Close, VolumeUpContainer } from './style'

interface IPreviewCardProps {
  title: string
  index: number
  handleRemoveFile: (index: number) => void
}

const PreviewCard = ({ title, index, handleRemoveFile }: IPreviewCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null)

  const deleteImage = async () => {
    try {
      handleRemoveFile(index)
    } catch (ex) {
      console.error(ex)
    }
  }

  const handleThumb = () => {
    const fileExtension = getFileExtension(title)

    switch (fileExtension) {
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
        return (
          <VolumeUpContainer>
            <Icon size='large' iconColor='#fff'>
              <VolumeUpOutlinedIcon />
            </Icon>
          </VolumeUpContainer>
        )
      default:
        return <Avatar userName={fileExtension} sx={{ width: 24, height: 24 }} />
    }
  }

  return (
    <Wrapper ref={cardRef}>
      {handleThumb()}
      <Title>{title}</Title>
      <Close>
        <IconButton sx={{ padding: '4px' }} onClick={deleteImage}>
          <Icon size='small' iconColor='#fff'>
            <CloseIcon />
          </Icon>
        </IconButton>
      </Close>
    </Wrapper>
  )
}

export default PreviewCard
