
import { Text2 } from 'components/design-system'
import { IFormData } from '../..'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { FieldFilled } from './components'
import LibraryCard from 'components/common/LibraryCard'
import { PersonalFileCard } from './components/PersonalFileCard'

interface IReviewPage {
  stepIndex: number
  formData: IFormData
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>
}

export const ReviewPage = ({ stepIndex, formData, setFormData }: IReviewPage) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const selectedResources = formData.resources
  const selectedArticles = formData.articles
  const personalMaterial = formData.files

  const learningStrategies = formData.learningStrategies?.map(el => el.title)

  return (
    <div className={classes.container}>
      <Text2 fontSize='md' fontWeight='semibold' colorbrand='medium'>
        {t('Passo 5: Revisão')}
      </Text2>

      <div className={classes.fieldsContainer}>
        <div className={classes.fieldsContainerRow}>
          <FieldFilled title={t('Ano/série')} value={formData.grade?.title ?? ''} />
          <FieldFilled title={t('Duração da aula')} value={formData.duration?.title ?? ''} />
          <FieldFilled title={t('Componente curricular')} value={formData.discipline?.title ?? ''} />
        </div>
        <div className={classes.fieldsContainerRow}>
          <FieldFilled title={t('Tema da aula')} value={formData.classTheme ?? ''} />
        </div>
        <div className={classes.fieldsContainerRow}>
          <FieldFilled title={t('Título')} value={formData.title ?? ''} />
        </div>
        <div className={classes.fieldsContainerRow}>
          <FieldFilled title={t('Objetivos')} value={formData.classGoals ?? ''} />
        </div>
        <div className={classes.fieldsContainerRow}>
          <FieldFilled title={t('Estratégia de aprendizagem')} values={learningStrategies} />
        </div>
        <div className={classes.resourcesRow}>
          <Text2 fontSize='xs' fontWeight='bold' neutral='dark20'>
            {t('Materiais utilizados:')}
          </Text2>

          <div className={classes.usedResources}>
            {
              selectedResources.map((resource, index) => (
                <LibraryCard
                  key={index}
                  resource={resource}
                  reviewMode
                  page='class-plan'
                />
              ))
            }
            {
              selectedArticles.map((article, index) => (
                <LibraryCard
                  key={index}
                  article={article}
                  reviewMode
                  page='class-plan'
                />
              ))
            }
            {
              personalMaterial?.map((file, index) => (
                <PersonalFileCard file={file} key={index} />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}
