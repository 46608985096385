import { useTranslation, Trans } from 'react-i18next'
import { useStore } from 'store'
import { useMediaQuery, useTheme, Grid } from '@mui/material'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

import { Text2, Icon } from 'components/design-system'

import CloeAvatar from 'assets/cloe-avatar-joinha.svg'

import useStyle from './styles'

interface IExpandedProps {
  handleHeaderExpand: () => void
}

export const Closed: React.FC<IExpandedProps> = ({ handleHeaderExpand }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const { session } = useStore()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container className={classes.quickAccessContainer}>
      <Grid md={10}>
        <img src={CloeAvatar} className={classes.cloeAvatar} />
        <Text2 customColor={theme.colors.neutral.lightBase} fontSize='xl' fontWeight='medium' mobile='xl' className={classes.welcomeText}>
          <Trans>
            Olá{!isMobile && (<>, <b>{{ name: session?.name ?? 'Professor(a)' }}</b></>)}!
          </Trans>
        </Text2>
      </Grid>
      <Grid
        className={classes.hideButton}
        onClick={handleHeaderExpand}
        data-testid={'shortcut_show'}
      >
        <Text2 customColor={theme.colors.neutral.lightBase} cursorpointer fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs'>
          {t('Mostrar')}
        </Text2>
        <Icon size='medium'>
          <KeyboardArrowDownOutlinedIcon />
        </Icon>
      </Grid>
    </Grid>
  )
}
