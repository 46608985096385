import { Tab, TabPanel, Tabs } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import { LibraryContentShare } from './LibraryContentShare'
import { LibraryContentSave } from './LibraryContentSave'
import { useAtom } from 'jotai'
import { myCloeLibraryContentTabIndexAtom } from './atomStore'

export const LibraryContentSchedule: React.FC = () => {
  // hooks
  const { t } = useTranslation()
  // states
  const [tabIndex, setTabIndex] = useAtom(myCloeLibraryContentTabIndexAtom)
  // style
  const classes = useStyle()

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(_, index) => setTabIndex(index)}
        centered
        className={classes.tabContainer}
      >
        <Tab
          label={t('Salvar')}
          className={`${classes.tab} ${tabIndex === 0 ? 'isSelected' : ''}`}
          value={0}
        />
        <Tab
          label={t('Compartilhar')}
          className={`${classes.tab} ${tabIndex === 1 ? 'isSelected' : ''}`}
          value={1}
        />
      </Tabs>
      <div className={`${classes.resourceScheduleContainer} ${tabIndex === 0 ? 'hideParentBorderRadius' : ''}`}>
        <div className={classes.tabPanelContainer}>
          <TabPanel value={tabIndex} index={0} data-testid='my_cloe_menu_save'>
            <LibraryContentSave />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} data-testid='my_cloe_menu_share'>
            <LibraryContentShare />
          </TabPanel>
        </div>
      </div>
    </>
  )
}
