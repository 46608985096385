import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,

    [theme.breakpoints.up('md')]: {
      width: '712px'
    }
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm
  },
  inputFields: {
    display: 'flex',
    width: 'auto !important'
  },
  fieldsContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.md
  },
  strategyContainer: {
    display: 'flex',
    fontSize: theme.font.fontSize.sm,
    marginTop: theme.spacingStack.nano,
    flexFlow: 'inherit',
    overflowX: 'auto',
    transition: 'all ease-in-out 0.2s',
    gap: theme.spacingInline.xxxs,
    lineHeight: theme.font.lineHeight.xs,
    fontWeight: theme.font.fontWeight.semibold,
    '&.active': {
      flexFlow: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    }
  },
  strategy: {
    // marginRight: theme.spacingInline.xxxs,
    // marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',

    '&.disabled': {
      cursor: 'not-allowed'
    },

    '&.selected': {
      background: theme.colorBrand.medium,
      color: theme.colors.neutral.lightBase
    },

    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },
  strategyBoxContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
}))
