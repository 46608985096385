import { IResourceTypes, IResourceTypesEnum } from 'services/types'
import {
  AppIcon,
  AudioIcon,
  GameIcon,
  SiteIcon,
  TextIcon,
  VideoIcon,
  DefaultIcon,
  InfographicIcon,
  MadeByCloeIcon,
  GalleryIcon,
  ImageIcon,
  InteractiveIcon,
  ToolIcon,
  AddIcon
} from './assets'
import { useTheme } from '@mui/material'
import { useMemo } from 'react'
import useStyle from './style'

interface IProps {
  thumbnail?: string | null
  size: 'small' | 'medium'
  type: 'add' | 'article' | IResourceTypes
  originalCloe: boolean
}
export const ResourceThumb = (props: IProps) => {
  const { thumbnail, size, type, originalCloe } = props
  const theme = useTheme()

  const defaultIcon = useMemo(() => {
    switch (type) {
      case IResourceTypesEnum.app:
        return { icon: <AppIcon />, color: theme.colors.curricularComponents.QUI }
      case IResourceTypesEnum.audio:
        return { icon: <AudioIcon />, color: theme.colors.curricularComponents.FIS }
      case IResourceTypesEnum.game:
        return { icon: <GameIcon />, color: theme.colors.curricularComponents.IT }
      case IResourceTypesEnum.site:
        return { icon: <SiteIcon />, color: theme.colors.curricularComponents.BI }
      case IResourceTypesEnum.text:
        return { icon: <TextIcon />, color: theme.colors.curricularComponents.GEO }
      case IResourceTypesEnum.video:
        return { icon: <VideoIcon />, color: theme.colors.curricularComponents.FIS }
      case IResourceTypesEnum.image:
        return { icon: <ImageIcon />, color: theme.colors.curricularComponents.CIE }
      case IResourceTypesEnum.infographic:
        return { icon: <InfographicIcon />, color: theme.colors.curricularComponents.BI }
      case IResourceTypesEnum.interactive:
        return { icon: <InteractiveIcon />, color: theme.colors.curricularComponents.CIE }
      case IResourceTypesEnum.tool:
        return { icon: <ToolIcon />, color: theme.colors.curricularComponents.FILO }
      case IResourceTypesEnum.gallery:
        return { icon: <GalleryIcon />, color: theme.colors.curricularComponents.QUI }
      case 'add':
        return { icon: <AddIcon />, color: theme.colorBrand.lightest }
      default:
        return { icon: <DefaultIcon />, color: theme.colors.curricularComponents.FILO }
    }
  }, [type])

  const classes = useStyle({
    thumbnail: thumbnail,
    iconColor: defaultIcon.color,
    size: size
  })

  return (
    <div className={classes.cardImage}>
      {
        thumbnail
          ? <div className={classes.cardThumbnail} />
          : defaultIcon.icon
      }
      {originalCloe && <div className={classes.originalCloeContent}><MadeByCloeIcon /></div>}
    </div>
  )
}
