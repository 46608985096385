import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Text2 } from 'components/design-system'

import useStyle from './styles'
import { BigNumber } from '..'
import { IClassPerformance } from 'services/types'

export interface IBigNumbersData {
  data: IClassPerformance | null
  error: string | null
}

interface IBigNumbersReady {
  bigNumbersData: IBigNumbersData
  tryAgainCallback: () => Promise<void>
}

enum StateMachineEnum {
  READY = 'READY',
  ERROR = 'ERROR',
}

export const BigNumbersReady: React.FC<IBigNumbersReady> = ({ bigNumbersData, tryAgainCallback }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()

  const currentState: StateMachineEnum = bigNumbersData.error ? StateMachineEnum.ERROR : StateMachineEnum.READY

  const classData = bigNumbersData.data

  const ActionButton = () => (
    <Button
      className='actionButton'
      variant='ghost'
      size='small'
      data-testid='followup_reload'
      onClick={tryAgainCallback}
    >
      {t('Recarregar')}
    </Button>
  )

  const ReadyState = () => (
    <>
      <BigNumber.Item
        title={t('Participação')}
        percentage={(classData?.per_participation ?? 0) * 100}
        valueText={classData?.per_participation === null || typeof classData?.per_participation === 'undefined' ? '-' : undefined}
      />
      <BigNumber.Item
        title={t('Atividades aplicadas')}
        valueText={String(classData?.nr_released_activity ?? '-')}
      />
      <BigNumber.Item
        title={t('Avaliações aplicadas')}
        valueText={String(classData?.nr_released_evaluation ?? '-')}
      />
      <BigNumber.Item
        percentage={10 * (classData?.dec_performance ?? 0)}
        valueText={`${classData?.dec_performance?.toLocaleString('pt-BR') ?? '-'}`}
        title={t('Média da turma')}
      />
    </>
  )

  const ErrorState = () => (
    <>
      <Grid container>
        <Alert content={bigNumbersData.error ?? ''} outlined={true} severity='error' actionButton={<ActionButton />} />
      </Grid>
      <BigNumber.Item
        title={t('Participação')}
        valueText={'!'}
      />
      <BigNumber.Item
        title={t('Atividades aplicadas')}
        valueText={'!'}
      />
      <BigNumber.Item
        title={t('Avaliações aplicadas')}
        valueText={'!'}
      />
      <BigNumber.Item
        valueText={'!'}
        title={t('Média da turma')}
      />
    </>
  )

  const states = {
    READY: <ReadyState />,
    ERROR: <ErrorState />
  }

  const renderStateMachine = () => states[currentState]

  return (
    <Grid container>
      <Text2
        fontSize='lg'
        fontWeight='medium'
        lineHeight='xs'
        mobile='lg'
        customColor={theme.colorBrand.medium}
      >
        {t('Visão geral')}
      </Text2>

      <Grid
        container
        spacing={8}
        alignItems='flex-start'
        justifyContent='space-between'
        marginTop={3.5}
        mx='auto'
        className={classes.bigNumbesReportsContainer}
      >
        {renderStateMachine()}
      </Grid>
    </Grid>
  )
}
