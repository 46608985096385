import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

import cloeWorm from 'assets/cloe-worm-collection-card.svg'

export default makeStyles((theme: Theme) => createStyles({
  cardContainer: {
    width: '248px',
    minWidth: '248px',
    height: '248px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.borderRadius.md,
    border: `1px solid ${theme.colors.neutral.light20}`,
    backgroundColor: theme.colors.neutral.lightBase,
    backgroundImage: `url(${cloeWorm})`,
    padding: theme.spacingInset.xs,
    gap: theme.spacingStack.nano,
    textDecoration: 'none !important',

    '&.isSelected': {
      border: `2px solid ${theme.colorBrand.medium}`
    }
  },
  containerBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: theme.spacingStack.nano

  },
  containerThumbnails: {
    minWidth: '246px',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    '& > div': {
      position: 'absolute'
    },
    '& > div:first-child': {
      left: '0px',
      zIndex: 3
    },
    '& > div:nth-child(2)': {
      left: '32px',
      zIndex: 2
    },
    '& > div:nth-child(3)': {
      left: '64px',
      zIndex: 1
    }
  },
  iconContainer: {
    color: theme.colors.support.colorSupport02
  },
  titleGroup: {
    justifyContent: 'space-between',
    // flexGrow: 1,
    // flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    flexWrap: 'wrap'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  mediaCounter: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorBrand.medium,
    gap: theme.spacingInline.quarck
  },
  svgIconContainer: {
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
