import MuiPagination from '@mui/material/Pagination'
import { PaginationProps } from '@mui/material'
import useStyles from './style'

export interface IPaginationProps extends Omit<PaginationProps, 'variant'> {
  variant?: 'outlined'
}

export const Pagination: React.FC<IPaginationProps> = ({ variant = 'outlined', ...props }) => {
  const classes = useStyles()
  return (
    <MuiPagination
      className={`${classes.root} ${variant} ${props.className as string ?? ''}`}
      {...props}
    />
  )
}
