import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  crumb: {
    color: `${theme.colorBrand.medium} !important`,
    textDecoration: 'none !important',
    fontSize: `${theme.font.fontSize.xxs} !important`,
    fontWeight: `${theme.font.fontWeight.semibold} !important`,
    lineHeight: '16px !important',
    letterSpacing: '0.12px !important'
  }
}))
