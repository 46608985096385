import { LOGIN } from 'navigation/CONSTANTS'
import { Button, Text2 } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStyles from './style'

export const NoSchoolPeriodFound = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <div className={classes.containerNoSchoolPeriod}>
        <Text2 className={classes.title} style={{ paddingBottom: '30px' }} fontSize={'xl'} fontWeight={'medium'} colorbrand='medium'>{t('O ano escolar de sua escola ainda não foi configurado.')}</Text2>
        <Text2 className={classes.title} fontSize={'lg'} fontWeight={'medium'} neutral='dark30'>{t('Solicite a configuração ao coordenador para acessar a Cloe')}</Text2>
        <div className={classes.image404} />
        <Button onClick={() => history.push(LOGIN)}>
          {t('Voltar para o login')}
        </Button>
      </div>
    </>
  )
}
