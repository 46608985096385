/* eslint-disable @typescript-eslint/no-unused-vars */
import { Switch, Route } from 'react-router-dom'
import { PROGRAM_CONTENT } from 'navigation/CONSTANTS'
import { ProgramCloeView } from './components'

export const ProgramContent: React.FC = () => {
  return (
    <Switch>
      <Route exact path={PROGRAM_CONTENT}>
        <ProgramCloeView />
      </Route>
    </Switch>
  )
}
