/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ClassNameMap } from '@mui/material/styles'
import { heading, subtitle, body, colors } from './styles'
import { useMediaQuery, useTheme } from '@mui/material'

const useStyles = (type: string): ClassNameMap<string> => {
  switch (type) {
    case 'subtitle':
      return subtitle()
    case 'heading':
      return heading()
    default:
      return body()
  }
}

export interface ITextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  type?: 'heading' | 'subtitle' | 'body'
  size?: 'display' | 'xLarge' | 'large' | 'medium' | 'small' | 'default'
  mobileSize?: 'display' | 'xLarge' | 'large' | 'medium' | 'small' | 'default'
  color?: 'darkest' | 'dark' | 'medium' | 'light' | 'lightest'
}

export const Text: React.FC<ITextProps> = ({ type = 'body', size = 'default', mobileSize, color, children, ...props }) => {
  const classes = useStyles(type)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const colorClass = color ? `${color as string}Color` : ''
  const _size = !isMobile ? size : mobileSize ?? size
  return (
    <span
      {...props}
      className={`${classes.root} ${_size} ${colors().root} ${colorClass} ${props.className as string ?? ''}`}
    >
      {children}
    </span>
  )
}
