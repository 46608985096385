import { styled } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const Title = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.font.fontSize.xs,
    lineHeight: theme.font.lineHeight.xs
  },
  color: theme.colors.neutral.dark10,
  fontSize: theme.font.fontSize.xs,
  fontWeight: theme.font.fontWeight.bold,
  lineHeight: '24px',
  letterSpacing: '0.14px',
  alignSelf: 'center'
}))

export default makeStyles((theme: Theme) => createStyles({
  colapseTitleArea: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxWidth: '600px'
  }
}))
