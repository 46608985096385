import { Grid } from '@mui/material'
import { useAtom } from 'jotai'
import { useState } from 'react'

import { IQuestionResponse } from 'services/types'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import QuestionResource from 'pages/ContentUnit/components/Activity/common/ActivityBody/components/QuestionList/components/Question/components/QuestionResource'

import useStyles from './style'

interface ISummaryQuestionProps {
  activeQuestion?: IQuestionResponse
}

export const SummaryQuestion: React.FC<ISummaryQuestionProps> = ({ activeQuestion }) => {
  const classes = useStyles()
  const [isShowResources, setIsShowResources] = useState(false)

  // atom
  const [activity] = useAtom(activityCurrentAtom)

  const validateDataTestId = () => {
    if (activity?.suggested_application_type === 'activity') {
      if (isShowResources) return 'correction_activity_menu_answers_hide_question'
      return 'correction_activity_menu_answers_show_question'
    }

    if (isShowResources) return 'correction_assessment_menu_answers_hide_question'
    return 'correction_assessment_menu_answers_show_question'
  }

  return (
    <Grid container className={classes.summaryQuestionContainer} display='flex' flexDirection='column'>
      {activeQuestion && (
        <>
          <Grid width={1} display='flex' flexDirection='column' className={`${classes.summaryQuestionContent} ${isShowResources ? '' : 'hide'}`}>
            <QuestionResource question={activeQuestion} isShowResources={isShowResources} />
          </Grid>
          <span
            className={classes.summaryQuestionButtonShowResources}
            onClick={() => setIsShowResources(!isShowResources)}
            data-testid={validateDataTestId()}
          >
            {isShowResources ? 'Ocultar questão' : 'Mostrar questão'}
          </span>
        </>
      )}
    </Grid>
  )
}

export default SummaryQuestion
