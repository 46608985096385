import React, { useEffect, useMemo, useState } from 'react'
import { emptyStateAtom, previewsAtom, IPreviewState } from '../atomStore'
import { useAtom } from 'jotai'
import { IQuestionResponse } from 'services/types'
import { AnswerCacheTable, deleteAnswerFile, getAnswerOffline, getSubscription, useOnlineStatus } from 'contentCacheManager'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PreviewCard from '../PreviewCard'

import { Wrapper, Title, PreviewArea } from './style'

type PreviewProps = {
  currentQuestion?: IQuestionResponse
  fileOfflineCount?: number
}
const Preview: React.FC<PreviewProps> = ({ currentQuestion, fileOfflineCount }) => {
  const [previews] = useAtom(previewsAtom)
  const [emptyState, setEmptyState] = useAtom(emptyStateAtom)
  const { contentId, activityId } = useParams<{ contentId: string, activityId: string, evaluationId: string }>()
  const isOnline = useOnlineStatus()
  const [currentAnswer, setCurrentAnswer] = useState<AnswerCacheTable | undefined>()
  const { t } = useTranslation()

  useEffect(() => {
    const loadCurrentAnswer = async () => {
      if (!contentId || !activityId) return
      const { class: studentClass } = getSubscription()
      const currentAnswer = await getAnswerOffline({
        activity: activityId,
        class: String(studentClass.id),
        content_unit: contentId
      })
      setCurrentAnswer(currentAnswer)
    }
    loadCurrentAnswer()
  }, [contentId, activityId, fileOfflineCount])

  const previewsList = useMemo(() => {
    if (!isOnline && currentQuestion && currentAnswer) {
      if (currentAnswer?.filesOffline.length) {
        return currentAnswer?.filesOffline
          .find(fileItem => fileItem.question_id === currentQuestion.id)?.files.map(fileFile => {
            const blobUrl = window.URL.createObjectURL(fileFile.file)

            return {
              id: String(fileFile.fileName),
              url: String(blobUrl),
              name: String(fileFile.fileName),
              title: String(fileFile.fileName),
              thumb: String(blobUrl),
              ext: String(fileFile.fileName)
            } as unknown as IPreviewState
          }) ?? []
      }
      return []
    }

    return previews
  }, [isOnline, contentId, activityId, previews, currentQuestion, currentAnswer])

  useEffect(() => {
    if (!previews.length) {
      setEmptyState(true)
    }
  }, [previews])

  if (emptyState && isOnline) {
    return <React.Fragment />
  }

  const handleDeleteOffline = (fileName: string) => {
    const { class: studentClass } = getSubscription()
    if (currentQuestion) {
      deleteAnswerFile({
        activity: activityId,
        class: String(studentClass.id),
        content_unit: contentId
      }, currentQuestion.id, fileName)
    }
  }

  return (
    <Wrapper>
      {isOnline
        ? (
          <Title>
            {t('Arquivos enviados')}
          </Title>
        )
        : !!currentAnswer?.filesOffline.length &&
          <Title>
            Assim que a internet retornar seus arquivos serão enviados
          </Title>
      }

      <PreviewArea>
        {
          previewsList.map((preview, index) =>
            <PreviewCard
              key={index}
              fileRecordId={Number(preview.fileRecordId)}
              id={preview.id}
              transactionId={preview.transaction_id}
              title={preview.name}
              ext={preview.ext}
              thumb={preview.thumb}
              maxFileName={preview.maxFileName}
              handleDeleteOffline={handleDeleteOffline}
            />)
        }
      </PreviewArea>
    </Wrapper>
  )
}

export default Preview
