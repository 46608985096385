import { IGetClassPLanResponse } from 'services/types';
import { IFormData, STATES } from '..';
import { useAtom } from 'jotai';
import { requiredFieldsInAlertAtom } from '../atoms';
import Analytics from 'utils/analytics'
import { useStore } from 'store';

type IClassPlan = IGetClassPLanResponse | undefined

export interface IProps {
  currentPage?: STATES
  formData: IFormData
  classPlan?: IClassPlan
  requiredStage?: STATES
}

export const useValidateFields = () => {
  const [requiredFieldsInAlert, setRequiredFieldsInAlert] = useAtom(requiredFieldsInAlertAtom)
  const { profile, subscription } = useStore()

  const isFieldHighlighted = (field: string) => {
    return requiredFieldsInAlert.includes(field)
  }

  const addField = (field: string) => {
    if (requiredFieldsInAlert.includes(field)) return

    setRequiredFieldsInAlert(old => [...old, field])
  }

  const removeField = (field: string) => {
    if (!requiredFieldsInAlert.includes(field)) return

    setRequiredFieldsInAlert(old => old.filter(el => el !== field))
  }

  const validateGeneralInfoPage = ({ formData }: IProps) => {
    let hasError = false

    if (!formData.grade?.id) {
      throw new Error("Preencha os campos obrigatórios")
    }

    if (!formData.discipline?.id) {
      throw new Error("Preencha os campos obrigatórios")
    }

    if (!formData.duration?.title) {
      throw new Error("Preencha os campos obrigatórios")
    }

    if (!formData.classTheme) {
      addField('classTheme')
      hasError = true
    } else {
      removeField('classTheme')
    }

    if (!formData.title) {
      addField('title')
      hasError = true
    } else {
      removeField('title')
    }

    if (hasError) {
      throw new Error("Preencha os campos obrigatórios")
    }
  }

  const validateDetailsPage = (props: IProps) => {
    validateGeneralInfoPage(props)

    const formData = props.formData
    let hasError = false

    if (!formData.classGoals) {
      hasError = true
      addField('classGoals')
    } else {
      removeField('classGoals')
    }

    if (!formData.learningStrategies?.length) {
      hasError = true
      addField('learningStrategies')
    } else {
      removeField('learningStrategies')
    }

    if (hasError) {
      throw new Error("Preencha os campos obrigatórios")
    }
  }

  const validateCloeMaterialsPage = (props: IProps) => {
    validateDetailsPage(props)

    const resources = props.formData?.resources?.length ?? 0
    const articles = props.formData?.articles?.length ?? 0

    const totalArticles = resources + articles

    if (totalArticles === 0) {
      throw new Error('Selecione no mínimo um material Cloe')
    }

    if (articles > 1) {
      Analytics.recordEventClick({
        name: 'cloe_super_create_lesson_plan_step3_alert_chapter',
        attributes: {
          ...profile?.analytics,
          ...subscription?.analytics
        }
      })
      throw new Error('Selecione no máximo 1 Capítulo')
    }

    if (resources > 3) {
      Analytics.recordEventClick({
        name: 'cloe_super_create_lesson_plan_step3_alert_resource',
        attributes: {
          ...profile?.analytics,
          ...subscription?.analytics
        }
      })
      throw new Error('Selecione no máximo 3 Recursos')
    }
  }

  const validatePersonalMaterialsPage = (props: IProps) => {
    validateCloeMaterialsPage(props)

  }
  const validateReviewPage = (props: IProps) => {
    validatePersonalMaterialsPage(props)

    const classPlan = props.classPlan

    if (!classPlan) {
      throw new Error('Para prosseguir, pressione o botão para gerar o plano de aula')
    }
  }
  const validateGeneratedClassPlanPage = (props: IProps) => {
    const isReturning = !props?.currentPage || !props?.requiredStage || (props.requiredStage < props.currentPage)
    if (isReturning) {
      return
    }

    validatePersonalMaterialsPage(props)

  }

  const validatePageMovement = (props: IProps) => {
    if (props.currentPage === undefined) throw new Error('Missing props.currentPage')
    if (props.requiredStage !== undefined && props.requiredStage < props.currentPage) return

    const pagesValidators = {
      [STATES.GENERAL_INFO]: validateGeneralInfoPage,
      [STATES.DETAILS]: validateDetailsPage,
      [STATES.CLOE_MATERIALS]: validateCloeMaterialsPage,
      [STATES.PERSONAL_MATERIALS]: validatePersonalMaterialsPage,
      [STATES.REVIEW]: validateReviewPage,
      [STATES.GENERATED_CLASS_PLAN]: validateGeneratedClassPlanPage,
    }

    pagesValidators[props.currentPage](props)
  }

  return {
    validateGeneralInfoPage,
    validateDetailsPage,
    validateCloeMaterialsPage,
    validatePersonalMaterialsPage,
    validateReviewPage,
    validateGeneratedClassPlanPage,
    validatePageMovement,
    isFieldHighlighted
  }
}
