// src/components/Editor/hooks/useEditor.ts
import { useEffect, useRef, useCallback } from 'react'
import EditorJS from '@editorjs/editorjs'
import { EditorProps, EditorRef } from '../types/editor.types'
import { EDITOR_TOOLS, ERROR_MESSAGES } from '../constants/editor.constants'
import { convertBlocksToHtml } from '../utils/editor.utils'

export const useEditor = ({
  initialHtml,
  onBlur,
  onError,
  readOnly,
  placeholder
}: EditorProps): [React.RefObject<HTMLDivElement>, EditorRef] => {
  const containerRef = useRef<HTMLDivElement>(null)
  const editorInstance = useRef<EditorJS | null>(null)
  const isInitialized = useRef(false);

  const getContent = useCallback(async (): Promise<string> => {
    try {
      if (!editorInstance.current) return ''
      const outputData = await editorInstance.current.save()
      return convertBlocksToHtml(outputData.blocks)
    } catch (error) {
      onError?.(error instanceof Error ? error : new Error(ERROR_MESSAGES.SAVE))
      return ''
    }
  }, [onError])

  const handleGetContent = async () => {
    const html = await getContent()
    if (html) {
      onBlur?.(html)
    }
  }

  const handleBlur = useCallback(() => {
    handleGetContent()
  }, [getContent, onBlur])

  useEffect(() => {
    const container = containerRef.current;
    if (!container || isInitialized.current) return;

    const initEditor = async () => {
      try {
        editorInstance.current = new EditorJS({
          holder: container,
          tools: EDITOR_TOOLS,
          inlineToolbar: true,
          autofocus: true,
          placeholder,
          data: {
            blocks: [] // Start with empty blocks
          },
          async onReady() {
            if (initialHtml && editorInstance.current) {
              try {

                const existingEditor = container.querySelector('.codex-editor');
                if (existingEditor) {
                  container.removeChild(existingEditor);
                }

                // Use renderFromHTML with the HTML string
                await editorInstance.current.blocks.renderFromHTML(initialHtml)
              } catch (error) {
                console.error('HTML parsing error:', error)
                onError?.(new Error('Failed to parse initial HTML'))
              }
            }
          }
        })

        await editorInstance.current.isReady

        // Add blur handlers
        container.addEventListener('blur', handleBlur, true)

        // Add blur handlers to all editable elements
        const editableElements = container.querySelectorAll('[contenteditable=true]')
        editableElements.forEach(element => {
          element.addEventListener('blur', handleBlur, true)
        })
      } catch (error) {
        onError?.(error instanceof Error ? error : new Error(ERROR_MESSAGES.INIT))
      }
    }

    void initEditor()

    return () => {
      const cleanup = async () => {
        try {
          // Remove blur handlers
          container.removeEventListener('blur', handleBlur, true)

          const editableElements = container.querySelectorAll('[contenteditable=true]')
          editableElements.forEach(element => {
            element.removeEventListener('blur', handleBlur, true)
          })

          if (editorInstance.current) {
            await editorInstance.current.destroy()
            editorInstance.current = null
          }
        } catch (error) {
          onError?.(error instanceof Error ? error : new Error(ERROR_MESSAGES.DESTROY))
        }
      }

      void cleanup()
    }
  }, [initialHtml, onError, placeholder, readOnly, handleBlur])

  const editorApi: EditorRef = {
    editor: editorInstance.current,
    getContent,
    clear: useCallback(() => {
      editorInstance.current?.clear()
    }, [])
  }

  return [containerRef, editorApi]
}
