import { IImageFormatResponse } from './content-unit'
import { IAlternativeResponse, IContentUnitResponse, ISkillTaxonomyResponse, IUserSessionResponse } from '.'
import { ContentResponse } from './content'
import { IDisciplineResponse } from './discipline'

export interface DailyActivitiesResponse {
  hour: string
  discipline: IDisciplineResponse
  contents: ContentResponse[]
}

export enum ActivityControlStatusEnum {
  AVAILABLE = 0,
  PENDING = 1,
  DONE = 2,
  CLOSED = 3,
  CORRECTED = 4, // deprecated
  RELEASED = 5
}

export interface IRecordStatusControlRequest {
  activityId: number
  activityStatus: ActivityControlStatusEnum | null
}

export interface IActivityResponse {
  id: number
  type: 'quiz' | 'record' | 'draw' | 'audio' | 'essay' | 'read' | 'reflect' | 'discuss_duo' | 'discuss' | 'listen' | 'watch' | 'guide'
  printable: boolean
  title: string
  suggested_application_type: 'activity' | 'evaluation'
  moment_type: 'home' | 'during_class'
  text: string | null
  has_answer_input: boolean
  use_type: 'expedition_quiz' | 'final' | 'diagnostic' | 'observation' | 'achievement' | 'procedural' | 'self_assessment' | 'article_quiz' | 'article_check' | 'quiz_v1' | 'drawing' | 'puzzle' | 'numbers' | 'writing' | 'words' | 'challenge'
  skill_taxonomy: string | null
  legacy_id: number | null
  locale: string
  published_at: string
  created_at: string
  updated_at: string
  activity_name: string
  content_unit: IContentUnitResponse | null
  questions: IQuestionResponse[]
  resources: IResourceResponse[]
  localizations: []
  activity_status_control?: ActivityControlStatusEnum
  has_answer?: boolean
  activity_status?: {
    status: ActivityControlStatusEnum
    has_answer: boolean
  }
  schedule?: {
    id: string
    startDate: string
    endDate: string
    ask_for_records: boolean
    has_updated: boolean
    discipline: IDisciplineResponse
    start_date?: string // TODO: retirar um os dois startDate/start_date
    end_date?: string// TODO: retirar um os dois endDate/end_date
    content?: string | null
    user_school_profile?: {
      id: string
      user?: {
        name: string
        surname: string
      }
    }
  }
  clonedTimes?: number
  hasNotification?: boolean
  position?: number
  custom_type?: 'clone' | 'template'
  origin?: {
    id: number
    title: string
    questions: IQuestionResponse[]
  }
  used_in: IActivityResponse[] | null
}

export const useTypeDicitonary: { [index: string]: string } = {
  expedition_quiz: 'Quiz',
  final: 'Final',
  diagnostic: 'Diagnóstica',
  observation: 'Observação',
  achievement: 'Conquista',
  procedural: 'Procedural',
  self_assessment: 'Autoavaliação',
  article_quiz: 'Quiz',
  article_check: 'Quiz',
  quiz_v1: 'Quiz',
  drawing: 'Atividade',
  puzzle: 'Atividade',
  numbers: 'Atividade',
  writing: 'Atividade',
  words: 'Atividade',
  challenge: 'Atividade'
}

export const ActivityTypeDescription = {
  quiz: 'Quiz',
  record: 'Registro',
  draw: 'Desenho',
  audio: 'Áudio',
  fill_in: 'Preencher',
  essay: 'Redação',
  read: 'Leitura',
  reflect: 'Reflexão',
  discuss_duo: 'Discussão em Dupla',
  discuss: 'Discussão',
  listen: 'Ouvir',
  watch: 'Assistir',
  evaluation: 'Avaliação',
  expedition_quiz: 'expedition_quiz',
  final: 'final',
  diagnostic: 'diagnostic',
  observation: 'observation',
  achievement: 'achievement',
  procedural: 'procedural',
  self_assessment: 'self_assessment',
  article_quiz: 'article_quiz',
  article_check: 'article_check',
  quiz_v1: 'quiz_v1',
  drawing: 'drawing',
  puzzle: 'puzzle',
  numbers: 'numbers',
  writing: 'Writing',
  words: 'words',
  challenge: 'challenge',
  guide: 'guide',
  speaking: 'Speaking',
  reading: 'Reading',
  listening: 'Listening',
  game: 'Game'
}

export enum ActivityUseTypeEnum {
  EXPEDITION_QUIZ = 'expedition_quiz',
  FINAL = 'final',
  DIAGNOSTIC = 'diagnostic',
  OBSERVATION = 'observation',
  ACHIEVEMENT = 'achievement',
  PROCEDURAL = 'procedural',
  SELF_ASSESSMENT = 'self_assessment',
  ARTICLE_QUIZ = 'article_quiz',
  ARTICLE_CHECK = 'article_check',
  QUIZ_V1 = 'quiz_v1',
  DRAWING = 'drawing',
  PUZZLE = 'puzzle',
  NUMBERS = 'numbers',
  WRITING = 'writing',
  WORDS = 'words',
  CHALLENGE = 'challenge'
}

export const ActivityUseTypeDescripion = {
  expedition_quiz: 'Quiz',
  final: 'Final',
  diagnostic: 'Diagnostica',
  observation: 'Observação',
  achievement: 'Conquista',
  procedural: 'Procedural',
  self_assessment: 'Auto avaliação',
  article_quiz: 'Quiz',
  article_check: 'Quiz',
  drawing: 'Desenho',
  puzzle: 'Rachando a cuca',
  words: 'Detetive das palavras',
  challenge: 'Desafios',
  writing: 'Escrever',
  numbers: 'Lendo e escrevendo números'
}

export interface IMediaResponse {
  alternativeText: string
  caption: string
  created_at: string
  ext: string
  formats: {
    large: IImageFormatResponse
    small: IImageFormatResponse
    medium: IImageFormatResponse
    thumbnail: IImageFormatResponse
  }
  hash: string
  height: number
  id: number
  mime: string
  name: string
  previewUrl?: string
  provider?: string
  provider_metadata?: string
  size: number
  updated_at: string
  url: string
  width: number
}

export type IResourceTypes = 'site' | 'text' | 'video' | 'image' | 'game' | 'audio' | 'app' | 'infographic' | 'interactive' | 'tool' | 'gallery'

export enum IResourceTypesEnum {
  site = 'site',
  text = 'text',
  video = 'video',
  image = 'image',
  game = 'game',
  audio = 'audio',
  app = 'app',
  infographic = 'infographic',
  interactive = 'interactive',
  tool = 'tool',
  gallery = 'gallery'
}

export const resourceTypesNames = {
  site: 'resource_site',
  text: 'resource_text',
  video: 'resource_video',
  image: 'resource_image',
  game: 'resource_game',
  audio: 'resource_audio',
  app: 'resource_app',
  infographic: 'resource_infographic',
  interactive: 'resource_interactive',
  tool: 'resource_tool',
  gallery: 'resource_gallery'
}

export const resourceTypesDataTestId = {
  site: 'resource_site',
  text: 'resource_text',
  video: 'resource_video',
  image: 'resource_image',
  game: 'resource_game',
  audio: 'resource_audio',
  app: 'resource_app',
  infographic: 'resource_infographic',
  interactive: 'resource_interactive',
  tool: 'resource_tool',
  gallery: 'resource_gallery',
  image_embed: 'resource_image_embed',
  video_embed: 'resource_video_embed'
}

export interface IResourceResponse {
  id: number
  title: string
  code: string
  source: string | null
  type: IResourceTypes
  is_embeded: boolean
  show_on_library: string | null
  legacy_id: number
  description: string | null
  html_content: string | null
  link: string
  locale: string
  published_at: string
  created_at: string
  updated_at: string
  medias?: IMediaResponse[]
  thumb_link: string | null
}

export interface IQuestionResponse {
  id: number
  text: string
  input_type: 'essay' | 'multi_choice' | 'record'
  weight: string | null
  skill_taxonomy: ISkillTaxonomyResponse
  legacy_id: string | null
  recommended_input: string
  level: number | null
  alternatives: IAlternativeResponse[]
  resources: IResourceResponse[]
  all_revised_or_corrected?: boolean
  origin?: string
}

export interface IClassActivityStatusesResponse {
  id: number
  user_school_profile: IUserSessionResponse
  status: ActivityControlStatusEnum
  has_answer: boolean
  has_corrected?: boolean
  has_revised?: boolean
  user: {
    name: string
    surname: string
  }
}

export enum SuggestedApplicationTypeLabelEnum {
  activity = 'Atividade',
  evaluation = 'Avaliação'
}

export interface ISchoolsClassResponse {
  schools: Array<{
    id: number
    name: string
    userSchoolProfile: number
    classes: Array<{
      grades: Array<{ id: number, name: string, code: string }>
      id: number
      subscription_id: number
      name: string
    }>
  }>
}
