import { Grid, useTheme } from '@mui/material'
import { DropdownSelector } from '../DropdownSelector'
import { useState } from 'react'
import useStyle from './style'
import { useTranslation } from 'react-i18next'
import { Text2, TextField } from 'components/design-system'

interface IFormInput {
  type: 'selector' | 'text'
  title?: string
  options?: any[]
  onChange?: (value: any) => void
  selectedOptionIndex?: number
  auxPlaceholder?: boolean
  customClassName?: string
  text?: string
  maxLength?: number
  customFormContainerClass?: string
  placeholderField?: string
  mandatory?: boolean
  warning?: boolean
  dataTestId?: string
}

export const FormInputSelector = (props: IFormInput) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)
  const classes = useStyle()

  const theme = useTheme()
  const { t } = useTranslation()

  const handleBackSelector = () => {
    setIsSelectorOpen(oldIsSelectorOpen => !oldIsSelectorOpen)
  }

  const renderOptions = () => {
    if (!props.options || props.selectedOptionIndex === undefined || props.onChange === undefined) {
      throw new Error('onSelect required')
    }

    return props.options.map((option, index) => {
      const isActive = index === props.selectedOptionIndex
      return (
        <a
          key={index}
          className={`${classes.optionContainer} ${isActive && 'active'}`}
          onClick={() => {
            if (props.onChange) {
              props.onChange(option)
            }
            setIsSelectorOpen(false)
          }}
          style={{ pointerEvents: 'auto' }}
        >
          {option.title}
        </a>
      )
    })
  }

  if (!props.options) {
    throw new Error('missing options')
  }
  if (props.selectedOptionIndex === undefined) {
    throw new Error('missing selectedOptionIndex')
  }

  return (
    <DropdownSelector
      dataTestId={props.dataTestId}
      auxPlaceholder={props.auxPlaceholder ? props.title : undefined}
      placeholder={props.options[props.selectedOptionIndex]?.[props.placeholderField ?? 'title'] ?? ''}
      colorTheme={theme.colorBrand.medium}
      isOpen={isSelectorOpen}
      className={`${classes.dropdownContainer} ${props.customClassName}`}
      setIsOpen={setIsSelectorOpen}
      backButtonLabel={t('Voltar')}
      handleSelectorClick={handleBackSelector}
      selectedTextClassName={classes.selectedText}
      openDirection={'top-down'}
    >
      <Grid className={classes.container}>
        {renderOptions()}
      </Grid>
    </DropdownSelector >
  )
}

export const FormInputText = (props: IFormInput) => {
  const classes = useStyle()

  if (props.onChange === undefined) {
    throw new Error('Onchange required')
  }

  return (
    <TextField
      data-testid={props.dataTestId}
      onChange={props.onChange}
      variant={'outlined'}
      id={props.title ?? ''}
      value={props.text ?? ''}
      warning={props.warning ?? false}
      assistiveText={props.mandatory ? 'Obrigatório' : ' '}
      showLengthCounter
      maxLength={props?.maxLength ?? 70}
      mandatory={props?.mandatory ?? false}
      className={`${classes.inputField} ${props.customClassName ?? ''}`}
    />
  )
}

export const FormInput = (props: IFormInput) => {
  const classes = useStyle()

  const components = {
    selector: <FormInputSelector {...props} />,
    text: <FormInputText {...props} />
  }

  return (
    <div className={`${classes.formInputContainer} ${props.customFormContainerClass ?? ''}`}>
      <Text2 fontSize='xs' fontWeight='bold' neutral='dark20'>
        {props.title}
      </Text2>
      {components[props.type]}
    </div>
  )
}
