
import { Text2 } from 'components/design-system'
import { IFormData } from '../..'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { CloeMaterialSearch } from 'components/common/CloeMaterialSearch'
import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { _selectedArticlesAtom, _selectedResourcesAtom, selectedArticlesAtom, selectedResourcesAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'
import { useUpdateAtom } from 'jotai/utils'
import { searchTermAtom } from '../../atoms'

interface ISelectCloeMaterialPage {
  stepIndex: number
  formData: IFormData
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>
}

export const SelectCloeMaterialPage = ({ stepIndex, formData, setFormData }: ISelectCloeMaterialPage) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)

  const [selectedResources] = useAtom(selectedResourcesAtom)
  const [selectedArticles] = useAtom(selectedArticlesAtom)

  const resetResources = useUpdateAtom(_selectedResourcesAtom)
  const resetArticles = useUpdateAtom(_selectedArticlesAtom)

  const hasSelectedMaterial = !!selectedResources.length || !!selectedArticles.length

  useEffect(() => {
    if (!hasSelectedMaterial) {
      resetResources([])
      resetArticles([])
    }
  }, [hasSelectedMaterial])

  useEffect(() => {
    updateResources()
  }, [selectedResources])

  useEffect(() => {
    updateArticles()
  }, [selectedArticles])

  const updateArticles = () => {
    setFormData(old => ({
      ...old,
      articles: selectedArticles
    }))
  }

  const updateResources = () => {
    setFormData(old => ({
      ...old,
      resources: selectedResources
    }))
  }

  return (
    <div className={classes.container}>
      <Text2 fontSize='md' fontWeight='semibold' colorbrand='medium' sx={{ textAlign: 'center' }}>
        {t('Passo 3: Seleção de materiais Cloe')}
      </Text2>

      <div className={classes.fieldsContainer}>
        <CloeMaterialSearch
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectMode
          page='class-plan'
          dataTestId={{
            clear: 'cloe_super_create_lesson_plan_step3_select_clear_search',
            submit: 'cloe_super_create_lesson_plan_step3_select_submit_search'
          }}
        />
      </div>
    </div>
  )
}
