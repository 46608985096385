import { NonEmbeddedResource, VimeoPlayer, YouTubePlayer, VideoPlayer, ExpandableImage, AudioPlayer, PdfRender, SoundCloudPlayer } from 'components/common'
import { IResourceResponse } from 'services/types'
import { useTranslation } from 'react-i18next'
import { Infogram } from '../Infogram'
import React from 'react'
import { ResourceGallery } from '../ResourceGallery'

interface IResourceProps {
  resource: IResourceResponse
}

const videoResolver = (resource: IResourceResponse) => {
  // verifica se tem medias
  const medias = mediaResolver(resource)
  if (medias) return medias

  // se não tiver medias, verifica pelo link a renderização
  if (resource.link.includes('youtu')) return <YouTubePlayer url={resource.link} source={resource.source} description={resource.description} />
  if (resource.link.includes('vimeo')) return <VimeoPlayer url={resource.link} source={resource.source} description={resource.description} />
  if (resource.link.includes('mp4')) return <VideoPlayer url={resource.link} source={resource.source} description={resource.description} />
  // fallback
  return <NonEmbeddedResource {...resource} />
}

const imageResolver = (resource: IResourceResponse) => {
  // verifica se tem medias cadastradas, se tiver, é a prioridade
  const medias = mediaResolver(resource)
  if (medias) return medias

  // se não tiver medias, verifica pelo link a renderização
  if (resource.link) return <ExpandableImage key={resource.id} src={resource.link} source={resource.source} description={resource.description} image={resource.link} />

  // fallback
  return <NonEmbeddedResource {...resource} />
}

const audioResolver = (resource: IResourceResponse) => {
  // verifica se tem medias cadastradas, se tiver, é a prioridade
  const medias = mediaResolver(resource)
  if (medias) return medias

  // se tiver link verifica se é um formato de audio compativel
  if (resource.link.includes('soundcloud')) return <SoundCloudPlayer url={resource.link} source={resource.source} description={resource.description} />

  // se não fallback
  return <NonEmbeddedResource {...resource} />
}

const mediaResolver = (resource: IResourceResponse) => {
  if (resource.medias?.length) {
    const mediaComponents: any[] = []
    resource.medias.forEach(cur => {
      if (cur.mime.includes('video/')) mediaComponents.push(<VideoPlayer url={cur.url} source={cur.caption && cur.caption !== '' ? cur.caption : resource.source} description={cur.alternativeText && cur.alternativeText !== '' ? cur.alternativeText : resource.description} />)
      if (cur.mime.includes('audio/')) mediaComponents.push(<AudioPlayer url={cur.url} source={cur.caption && cur.caption !== '' ? cur.caption : resource.source} description={cur.alternativeText && cur.alternativeText !== '' ? cur.alternativeText : resource.description} />)
      if (cur.mime.includes('image/')) mediaComponents.push(<ExpandableImage src={cur.url} source={cur.caption && cur.caption !== '' ? cur.caption : resource.source} description={cur.alternativeText && cur.alternativeText !== '' ? cur.alternativeText : resource.description} image={cur.url} />)
      if (cur.mime.includes('application/pdf')) mediaComponents.push(<PdfRender url={cur.url} source={cur.caption && cur.caption !== '' ? cur.caption : resource.source} description={cur.alternativeText && cur.alternativeText !== '' ? cur.alternativeText : resource.description} />)
    })

    return <React.Fragment key={`${resource.id}-medias`}>
      {
        mediaComponents.map((cur, index) => {
          return <React.Fragment key={`${resource.id}-${index}-media`}> {cur} {mediaComponents.length > 1 ? <br /> : ''}</React.Fragment>
        })
      }
    </React.Fragment>
  }

  return null
}

const defaultResolver = (resource: IResourceResponse) => {
  // verifica se tem medias cadastradas, se tiver, é a prioridade e vejo pelo mimetype
  const medias = mediaResolver(resource)
  if (medias) return medias

  // se não tiver medias, provavelmente erro de cadastro como fallback exibimos o NonEmbedded
  return <NonEmbeddedResource {...resource} />
}

const resourceResolver = (resource: IResourceResponse) => {
  // only embedded try to resolve
  try {
    const formattedMedias = resource?.medias?.map(media => ({ media, source: resource.source, description: resource.description }))
    // return embedded resources
    switch (resource.type) {
      case 'image':
        return imageResolver(resource)
      case 'video':
        return videoResolver(resource)
      case 'audio':
        return audioResolver(resource)
      case 'infographic':
        return <Infogram url={resource.link} />
      case 'gallery':
        return (
          <ResourceGallery
            medias={formattedMedias ?? []}
            source={resource.source}
            description={resource.description}
          />
        )
      default:
        return defaultResolver(resource)
    }
  } catch (e: any) {
    return defaultResolver(resource)
  }
}

export const resourceTypeResolver = (type: string) => {
  const { t } = useTranslation()

  switch (type) {
    case 'image':
      return t('Imagem')
    case 'interactive':
      return t('Interativo')
    case 'link':
      return t('Link')
    case 'text':
      return t('Texto')
    case 'video':
      return t('Vídeo')
    case 'audio':
      return t('Áudio')
    case 'site':
      return t('Site')
    default:
      return t('Link')
  }
}

// TODO: vamos refactorar esse cara para renderizar todos os resources
// esse componente deverá ser nosso especialista em renderizar resource
export const Resource: React.FC<IResourceProps> = ({ resource }) => {
  const render = () => {
    if (resource.is_embeded) {
      return resourceResolver(resource)
    } else {
      return <NonEmbeddedResource {...resource} />
    }
  }

  return render()
}
