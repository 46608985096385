import React, { useState } from 'react'
import { useAtom } from 'jotai'

import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import { Icon } from 'components/design-system'

/*
  Estamos reaproveitando o componente da timeline, mas seria ideal separarmos...
  afim de 'unificar' a responsabilidade da visualização dos arquivos.
  Mas para isso acontecer, precisamos refatorar a logica para centraliza-la em um componente
*/
import FilePreview from 'components/common/CustomizedTimeline/AnsweredFile'

import { tabIndexModalAtom, openModalAtom, modalContentAtom, eventListenerKeyDownCreatedAtom } from '../../atomStore'

import useStyles from './style'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

export const ModalResourceGallery: React.FC = () => {
  const classes = useStyles()
  const [showCaption, setShowCaption] = useState(true)
  const [indexSelected, setIndexSelected] = useAtom(tabIndexModalAtom)
  const [open, setOpen] = useAtom(openModalAtom)
  const [modalContent, setModalContent] = useAtom(modalContentAtom)
  const [eventListenerKeyDownCreated, setEventListenerKeyDownCreated] = useAtom(eventListenerKeyDownCreatedAtom)
  const theme = useTheme<Theme>()
  const scroll = 'paper'

  if (!modalContent) {
    return <></>
  }

  const handleKeyNavigation = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      setIndexSelected(index => {
        if (index > 0) return index - 1
        return index
      })
    }
    if (event.key === 'ArrowRight') {
      setIndexSelected(index => {
        if (index < modalContent.length - 1) return index + 1
        return index
      })
    }
  }

  // tive que usar o atom pois o useEffect estava com problemas
  // por conta dos varios rerenders que esse comnponente sofre
  if (!eventListenerKeyDownCreated) {
    setEventListenerKeyDownCreated(prev => {
      if (!prev) {
        window.addEventListener('keydown', handleKeyNavigation)
        return true
      }
      return prev
    })
  }

  const handleClose = () => {
    setShowCaption(true)
    setOpen(!open)
    setIndexSelected(0)
    setModalContent(null)
    window.removeEventListener('keydown', handleKeyNavigation)
    setEventListenerKeyDownCreated(false)
  }

  const handleShowCaption = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    setShowCaption(prev => !prev)
  }

  const { media, source } = modalContent[indexSelected]

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      sx={{
        '& .MuiPaper-root': {
          bgcolor: theme.colors.neutral.lightBase,
          // height: '100vh',
          boxShadow: 'none',
          borderRadius: '8px',
          display: 'block',
          width: 'auto',
          maxWidth: 'unset'
        },
        '& .MuiBackdrop-root': {
          background: 'rgba(20, 20, 20, 0.06)'
        }
      }}
    >
      <DialogTitle id='scroll-dialog-title' className={classes.boxContainer}>
        <button onClick={handleClose} className={classes.boxClose}>
          <Icon size='small'>
            <CloseIcon className={classes.boxIcon} />
          </Icon>
        </button>
      </DialogTitle>
      <DialogContent dividers={false} className={classes.dialogContent}>
        <div className={classes.modalTabs}>
          <div className={classes.arrow}>
            {
              indexSelected > 0 && (
                <ArrowBack onClick={() => setIndexSelected(index => index - 1)} />
              )
            }
          </div>
          <div className={classes.mediaContainer} onClick={handleShowCaption}>
            <FilePreview src={media.url} modalOpen={true} />
            <div className={showCaption ? classes.captionContainer : classes.hideCaptionContainer}>
              {
                (source ?? media.caption) && (
                  <div className={classes.caption}>
                    <b>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: media.caption ?? ''
                        }}
                      />
                    </b>
                  </div>
                )
              }
              <div className={classes.captionClose}>
                <Icon size='small' onClick={e => handleShowCaption}>
                  <CloseIcon />
                </Icon>
              </div>
            </div>
          </div>
          <div className={classes.arrow}>
            {
              indexSelected < modalContent.length - 1 && (
                <ArrowForward onClick={() => setIndexSelected(index => index + 1)} />
              )
            }
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
