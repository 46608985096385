import BaseAuthLayout from 'pages/BaseAuthLayout'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Text2, Card, CheckBox, Link, Button } from 'components/design-system'
import { useAuth } from 'navigation/ProvideAuth'
import { useAtom } from 'jotai'
import { atomTerms } from './atomStore'
import { getTerms, acceptTerms } from 'services/terms'
import { CLOE_TERMS_TYPES, IAcceptanceTermsResponse } from 'services/types/terms'
import { Storage } from 'utils/storage'
import { useProvideAuth } from 'navigation/useProvideAuth'
import { CHANGE_DEFAULT_PASSWORD } from 'navigation/CONSTANTS'
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseIcon from '@mui/icons-material/Close'

export const Terms: React.FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [terms, setTerms] = useState(false)
  const auth = useAuth()
  const storage = Storage.props.get()
  const [apiTerms, setApiTerms] = useAtom(atomTerms)
  const provideAuth = useProvideAuth()

  useEffect(() => {
    void findTerms()
  }, [])

  const findTerms = async () => {
    const response = await getTerms(CLOE_TERMS_TYPES.STANDARD)

    if (response.success) {
      setApiTerms(response.data)
    }
  }

  const callAcceptTerms = async () => {
    const data: IAcceptanceTermsResponse = {
      UserId: auth?.user?.id ?? storage.session.id,
      TermsId: apiTerms?.id ?? 0,
      Acceptance_Date: new Date()
    }

    const response = await acceptTerms(data, CLOE_TERMS_TYPES.STANDARD)

    if (response.success) {
      if (auth?.user?.isDefaultPass) {
        window.location.href = CHANGE_DEFAULT_PASSWORD
        return
      }
      await provideAuth.currentUser()
      window.location.href = '/'
    } else {
      await auth?.signout()
    }
  }

  const handleAcceptTerms = () => {
    if (terms && policy) {
      setLoading(true)
      void callAcceptTerms()
    }
  }

  const handleLogout = async () => {
    await auth?.signout()
  }

  const confirmBtnDisable = !policy || !terms || loading

  return (
    <BaseAuthLayout>
      <div className={styles.container}>
        <Grid container spacing={2}>
          <Grid container item xs={12}>
            <PreviewOutlinedIcon className={styles.relatedIcons} />
            <Text2 fontSize='sm' mobile='xs' lineHeight='xs' fontWeight='semibold' support='colorSupport01'>{t('Política de privacidade')}</Text2>
          </Grid>
          <Grid item xs={12}>
            <Card format='horizontal' selected extraClasses={`${styles.root} card-terms`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: apiTerms?.privacy_policy ?? ''
                }}
              />
            </Card>
          </Grid>
          <Grid container item xs={12}>
            <HandshakeOutlinedIcon className={styles.relatedIcons} />
            <Text2 fontSize='sm' mobile='xs' lineHeight='xs' fontWeight='semibold' support='colorSupport01'>{t('Termos de uso')}</Text2>
          </Grid>
          <Grid item xs={12}>
            <Card format='horizontal' selected extraClasses={`${styles.root} card-terms`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: apiTerms?.usage_terms ?? ''
                }}
              />
            </Card>
          </Grid>
        </Grid>
        <div className={styles.distance}>
          <Grid item xs={12}>
            <CheckBox checked={policy} size='small' onClick={() => setPolicy(!policy)} dataTestid='terms_and_policies_accept_policies'>
              <Text2 fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' neutral='dark30'>
                Declaro que li e estou ciente da
              </Text2>
            </CheckBox>
            <Text2 fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' support='colorSupport01'>
              <Link variant='primary' href='/terms/expanded?type=policy' target='_blank' className={styles.links} data-testid='terms_and_policies_access_policies_link'>
                Política de Privacidade da Cloe
              </Link>
            </Text2>
          </Grid>
          <Grid item xs={12}>
            <CheckBox checked={terms} size='small' onClick={() => setTerms(!terms)} dataTestid='terms_and_policies_accept_terms'>
              <Text2 fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' neutral='dark30'>
                Declaro que li e estou ciente dos
              </Text2>
            </CheckBox>
            <Text2 fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' support='colorSupport01'>
              <Link variant='primary' href='/terms/expanded?type=terms' target='_blank' className={styles.links} data-testid='terms_and_policies_access_terms_link'>
                Termos de Uso da Cloe
              </Link>
            </Text2>
          </Grid>
        </div>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Button
              className={`${styles.submitButton} ${!loading && !confirmBtnDisable ? 'active' : ''}`}
              variant='primary'
              type='submit'
              disabled={confirmBtnDisable}
              onClick={() => handleAcceptTerms()}
              data-testid='terms_and_policies_confirm'
            >
              {t(`${loading ? 'Enviando...' : 'Confirmar'}`)}
              <CheckOutlinedIcon sx={{ marginLeft: '14px' }} />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={styles.cancelButton}
              variant='ghost'
              type='button'
              onClick={handleLogout}
              data-testid='terms_and_policies_cancel'
            >
              <CloseIcon />
              {t('Cancelar')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </BaseAuthLayout>
  )
}

export default Terms
