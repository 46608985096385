import { BreadCrumb, Text2 } from 'components/design-system'
import useStyle from './style'
import { CLASS_PLAN_CREATE, LIBRARY_HOME } from 'navigation/CONSTANTS'
import { useTranslation } from 'react-i18next'
import { StageCounter } from 'components/common'
import { useEffect, useState } from 'react'
import { CancelModal, ClassPlanFooter, DetailsPage, ExportClassPlanModal, GeneralInfoPage, GeneratedClassPlan, GeneratingClassPlanModal, IGrades, IStrategy, PersonalMaterialsPage, SelectCloeMaterialPage } from './components'
import { IDiscipline } from 'services/types/subscription'
import { useHistory, useParams } from 'react-router-dom'
import { IArticleResponse, IResourceResponse } from 'services/types/library'
import { ReviewPage } from './components/ReviewPage'
import { toast } from 'components/design-system/Toast/manager'
import { activateClassPlan, checkClassPlanCreation, generateClassPlan, getClassPlan } from 'services/classPlan/classPlanService'
import { useValidateFields } from './validators'
import { useAtom } from 'jotai'
import { _selectedArticlesAtom, _selectedResourcesAtom, selectedResourcesAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'
import { classPlanAtom, searchTermAtom } from './atoms'

import CloeAvatar from 'assets/cloe-new-widgets.webp'
import CloeAvatarLast from 'assets/cloe-new-speaking-stars.webp'
import { useUpdateAtom } from 'jotai/utils'
import { useStore } from 'store'
import Analytics from 'utils/analytics'

export enum STATES {
  GENERAL_INFO = 0,
  DETAILS = 1,
  CLOE_MATERIALS = 2,
  PERSONAL_MATERIALS = 3,
  REVIEW = 4,
  GENERATED_CLASS_PLAN = 5
}

export interface IOption {
  [key: string]: any
  title: string
}

export interface IFormData {
  grade?: IOption & IGrades
  duration?: IOption
  discipline?: IOption & IDiscipline
  classTheme?: string
  title?: string
  classGoals?: string
  learningStrategies?: IStrategy[]
  resources: IResourceResponse[]
  articles: IArticleResponse[]
  personalMaterial?: string
  files?: any[]
}

const EMPTY_FORM_DATA = {
  grade: undefined,
  duration: undefined,
  discipline: undefined,
  classTheme: undefined,
  title: undefined,
  classGoals: undefined,
  learningStrategies: [],
  resources: [],
  articles: [],
  personalMaterial: undefined,
  files: []
}

export const ClassPlanTeacher: React.FC = () => {
  const { step } = useParams<{ step: string }>()
  const currentStep = Number(step)

  // const [_, setStateMachine] = useState<STATES>(STATES.GENERAL_INFO)
  const [formData, setFormData] = useState<IFormData>(EMPTY_FORM_DATA)
  const [threadId, setThreadId] = useState<string | undefined>(undefined)
  const [runId, setRunId] = useState<string | undefined>(undefined)

  const [isGeneratingClassPlan, setIsGeneratingClassPlan] = useState(false)
  const [isExportingClassPlan, setIsExportingClassPlan] = useState(false)
  const [classPlan, setClassPlan] = useAtom(classPlanAtom)
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false)
  const [, setSearchTerm] = useAtom(searchTermAtom)

  const [wasClassPlanSaved, setWasClassPlanSaved] = useState(false)

  const [, setSelectedResources] = useAtom(selectedResourcesAtom)

  const { profile, subscription } = useStore()

  const classes = useStyle()
  const history = useHistory()

  const { validateGeneratedClassPlanPage, validatePageMovement } = useValidateFields()

  const { t } = useTranslation()

  const stagesNumber = Object.keys(STATES).length / 2

  const resetResources = useUpdateAtom(_selectedResourcesAtom)
  const resetArticles = useUpdateAtom(_selectedArticlesAtom)

  const isStepCritical = (step: number) => {
    const isFirstPage = STATES.GENERAL_INFO === step
    const isLastPage = STATES.GENERATED_CLASS_PLAN === step
    return isFirstPage || isLastPage
  }

  useEffect(() => {
    return () => {
      cleanAtoms()
    }
  }, [])

  useEffect(() => {
    checkForClassPlanGeneration()

    return () => {
      setSelectedResources({ type: 'RESET' })
    }
  }, [isGeneratingClassPlan, runId, threadId])

  const cleanAtoms = () => {
    setClassPlan(undefined)
    resetResources([])
    resetArticles([])
    setFormData(EMPTY_FORM_DATA)
    setSearchTerm('')
  }

  const checkForClassPlanGeneration = async () => {
    if (!isGeneratingClassPlan || !threadId || !runId) return

    try {

      const response = await checkClassPlanCreation(threadId, runId)

      if (!response.success) throw new Error()

      const wasCreated = response.data.ready

      if (!wasCreated) {
        setTimeout(() => {
          checkForClassPlanGeneration()
        }, 2000)
        return
      }

      const classPlan = await getClassPlan(threadId)

      if (!classPlan.success) throw new Error()

      setClassPlan(classPlan.data)
      setIsGeneratingClassPlan(false)
      history.push(CLASS_PLAN_CREATE(STATES.GENERATED_CLASS_PLAN))
    } catch (err: any) {
      toast.handler({
        content: t('Não conseguimos localizar o plano de aula. Tente novamente mais tarde!'),
        duration: 2000,
        severity: 'error'
      })
      setIsGeneratingClassPlan(false)
    }
  }

  const handleChangeStage = (stage: STATES) => {
    const validationProps = { currentPage: currentStep, formData, classPlan, requiredStage: stage }
    try {
      validatePageMovement(validationProps)
      history.push(CLASS_PLAN_CREATE(stage))
    } catch (err: any) {
      handleFillRequiredFieldsWarning(err)
    }
  }

  const handleCancelPlanCreation = () => {
    setIsOpenCancelModal(true)
  }

  const handleConfirmCancel = () => {
    cleanAtoms()
    history.push(LIBRARY_HOME)
  }

  const handleCancelExitProcess = () => {
    setIsOpenCancelModal(false)
  }

  const handleSaveClassPlan = async () => {
    if (!classPlan) return

    try {
      const response = await activateClassPlan({ id: classPlan.id })

      if (!response.success) throw new Error()

      setWasClassPlanSaved(true)

      toast.handler({
        content: t('Plano de aula Salvo!'),
        duration: 2000,
        severity: 'success'
      })

      history.push(LIBRARY_HOME)

    } catch (err: any) {
      toast.handler({
        content: t('Não conseguimos salvar o plano de aula. Tente novamente mais tarde!'),
        duration: 2000,
        severity: 'error'
      })
    }
  }

  const handleExportClassPlan = async () => {
    if (!classPlan) return

    setIsExportingClassPlan(true)
  }

  const handleFillRequiredFieldsWarning = (err: any) => {
    toast.handler({
      content: err.message ?? t('Preencha todos os campos obrigatórios!'),
      duration: 2000,
      severity: 'warning'
    })
  }

  const handlePageTransition = (movement: 'next' | 'previous', currStep?: number) => {
    const current = currStep ?? currentStep

    if (movement === 'previous' && isStepCritical(current)) {
      handleCancelPlanCreation()
      return
    }

    try {
      if (movement === 'next' && current < STATES.GENERATED_CLASS_PLAN) {
        validatePageMovement({ currentPage: current, formData, requiredStage: current + 1 })
        history.push(CLASS_PLAN_CREATE(current + 1))
      }
      if (movement === 'previous' && current > STATES.GENERAL_INFO) {
        history.replace(CLASS_PLAN_CREATE(current - 1))
      }
    } catch (err: any) {
      handleFillRequiredFieldsWarning(err)
    }
  }

  const handleCreateClassPlan = async () => {
    try {
      setIsGeneratingClassPlan(true)
      validateGeneratedClassPlanPage({ formData, classPlan })

      const grade = Number(formData.grade?.id)

      const data = {
        grade: grade,
        duration: formData.duration?.title,
        discipline: formData.discipline?.id,
        theme: formData.classTheme,
        title: formData.title,
        objective: formData.classGoals,
        strategies: formData.learningStrategies?.map(el => el.title),
        resources: formData.resources?.map(el => el.resourceId) ?? [],
        articles: formData.articles?.map(el => el.articleId) ?? [],
        personalResources: formData.personalMaterial ? [formData.personalMaterial] : []
      }
      // @ts-expect-error
      const response = await generateClassPlan(data)

      if (!response.success) throw new Error()

      const { thread, run } = response.data

      Analytics.recordEventClick({
        name: 'cloe_super_create_lesson_plan_step5_create_success',
        attributes: {
          ...profile?.analytics,
          ...subscription?.analytics,
          request: data
        }
      })

      setThreadId(thread)
      setRunId(run)
    } catch (err: any) {
      Analytics.recordEventClick({
        name: 'cloe_super_create_lesson_plan_step5_create_error',
        attributes: {
          ...profile?.analytics,
          ...subscription?.analytics
        }
      })
      toast.handler({
        content: t('Não foi criar o plano de aula. Tente novamente mais tarde!'),
        duration: 3000,
        severity: 'error'
      })
      setIsGeneratingClassPlan(false)
    }
  }

  const stateRender = {
    [STATES.GENERAL_INFO]: <GeneralInfoPage formData={formData} setFormData={setFormData} stepIndex={STATES.GENERAL_INFO} />,
    [STATES.DETAILS]: <DetailsPage formData={formData} setFormData={setFormData} stepIndex={STATES.DETAILS} />,
    [STATES.CLOE_MATERIALS]: <SelectCloeMaterialPage formData={formData} setFormData={setFormData} stepIndex={STATES.CLOE_MATERIALS} />,
    [STATES.PERSONAL_MATERIALS]: <PersonalMaterialsPage formData={formData} setFormData={setFormData} stepIndex={STATES.PERSONAL_MATERIALS} />,
    [STATES.REVIEW]: <ReviewPage formData={formData} setFormData={setFormData} stepIndex={STATES.REVIEW} />,
    [STATES.GENERATED_CLASS_PLAN]: <GeneratedClassPlan stepIndex={STATES.GENERATED_CLASS_PLAN} />
  }

  return (
    <div className={classes.generalContainer}>
      <main className={classes.classPlanContainer}>
        <BreadCrumb
          current='Criar Plano de Aula'
          crumbs={[{ title: 'Biblioteca', link: LIBRARY_HOME }]}
        />

        <div className={classes.header}>
          <div className={classes.cloeAvatar}>
            {
              currentStep === STATES.GENERATED_CLASS_PLAN
                ? (
                  <img title='Avatar Cloe' src={CloeAvatarLast} />
                )
                : (
                  <img title='Avatar Cloe' src={CloeAvatar} />
                )
            }
          </div>
          <Text2 fontSize='xl' fontWeight='bold' support='colorSupport02'>
            {currentStep === STATES.GENERATED_CLASS_PLAN ? t('Pronto! Aqui está seu plano de aula:') : t('Vamos criar um plano de aula juntos?') }
          </Text2>
        </div>
        <section className={classes.body}>
          <StageCounter stagesNumber={stagesNumber} currentStage={currentStep} onClick={handleChangeStage} />

          <div className={classes.formMainContainer}>
            {
              stateRender[currentStep as STATES]
            }
          </div>
        </section>
      </main >
      <footer className={classes.footer}>
        <ClassPlanFooter
          stateMachine={currentStep}
          handlePageTransition={handlePageTransition}
          handleCancelPlanCreation={handleCancelPlanCreation}
          handleExportClassPlan={handleExportClassPlan}
          handleCreateClassPlan={handleCreateClassPlan}
          handleSaveClassPlan={handleSaveClassPlan}
        />
      </footer>

      {
        wasClassPlanSaved
          ? <></>
          : <CancelModal
            isOpen={isOpenCancelModal}
            handleCancel={handleCancelExitProcess}
            handleConfirm={handleConfirmCancel}
          />
      }

      <GeneratingClassPlanModal isOpen={isGeneratingClassPlan} />
      <ExportClassPlanModal isOpen={isExportingClassPlan} setIsOpen={setIsExportingClassPlan} />
    </div>
  )
}
