import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { OnlyProfile } from 'components/common'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { CONTENTS_STUDENT, DASHBOARD_TEACHER } from 'navigation/CONSTANTS'
import { NavLink, useParams } from 'react-router-dom'

import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'

export const BackLink = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { classId, gradeTypeCode, gradeCode } = useParams<{ contentId: string, activityId?: string, evaluationId: string, classId: string, gradeTypeCode?: string, gradeCode?: string }>()

  const { session } = useStore()

  const classSelected = session?.subscriptions.filter(cur => cur.class).find(sub => sub.class?.id?.toString() === classId)?.class
  const backText = `${t('Índice')} | ${classSelected?.grades[0].name ?? ''} - ${classSelected?.name ?? ''}`

  return (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <NavLink to={CONTENTS_STUDENT} data-testid='content_expedition_return' style={{ textDecoration: 'none' }}>
          <p className={classes.backLink}><ArrowBackIosNewOutlined /> {t('Índice')}</p>
        </NavLink>
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <NavLink to={DASHBOARD_TEACHER(gradeTypeCode ?? ':gradeTypeCode', gradeCode ?? ':gradeCode', classId)} data-testid='content_expedition_return' style={{ textDecoration: 'none' }}>
          <p className={classes.backLink}><ArrowBackIosNewOutlined /> {backText}</p>
        </NavLink>
      </OnlyProfile>
    </>
  )
}
