import { useEffect, useState } from 'react'
import { Box, Grid, Modal, Fade, Backdrop, useTheme, Theme } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import BackToTop from 'components/common/BackToTop'
import { Button, Text, TextField } from 'components/design-system'
import { createStyles, makeStyles } from '@mui/styles'
import wormsBg from 'assets/cloe-worms.svg'
import { CollectionCard, Loading } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { ICollectionResponse } from 'services/types/library'
import { getCollections, patchCollectionLibrary, postCollectionLibrary } from 'services/library'
import { useAtomValue } from 'jotai/utils'
import { selectedArticlesToSaveAtom, selectedResourcesToSaveAtom } from '../LibraryContentSave/atomStore'
import { COLLECTIONS } from 'navigation/CONSTANTS'
import { AddCollectionCard } from './AddCollectionCard'
import { useStore } from 'store'
import Analytics from 'utils/analytics'

const useStyles = makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '892px',
    width: '100%',
    maxHeight: 'calc(100vh - 16px)',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.md,

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'grid',
      borderRadius: '0px',
      gridTemplateRows: 'auto 10fr 1fr',
      overflowY: 'auto'
    }
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    background: 'rgba(230, 230, 230, 0.32)',
    borderRadius: `0 0 ${theme.borderRadius.md} ${theme.borderRadius.md}`
  },
  tagDefault: {
    cursor: 'pointer',
    width: 'fit-content',
    height: 'max-content',
  },
  tagSelected: {
    background: `${theme.colorBrand.medium} !important`,
    color: theme.colors.neutral.lightBase
  },
  classCardContainer: {
    width: '274px',
    padding: theme.spacingInline.xxxs,
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light10}`,
    background: theme.colors.neutral.lightBase,
    backgroundImage: `url(${wormsBg})`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
    cursor: 'pointer',
    '&.selected': {
      border: `2px solid ${theme.colorBrand.medium}`
    }
  },
  classCardHeader: {
    display: 'flex',
    color: theme.colorBrand.medium,
    gap: theme.spacingInline.nano
  },
  classCardTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: theme.colors.neutral.dark20,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    lineHeight: theme.font.lineHeight.xs,
    letterSpacing: '0.16px',
    textTransform: 'uppercase'
  },
  classCardDisciplines: {
    marginTop: theme.spacingInline.xxxs,
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacingInline.nano
  },
  classCardDisciplinesTag: {
    background: theme.colorBrand.lightest,
    color: theme.colorBrand.dark
  },
  textInput: {
    '& > div': {
      width: '100% !important'
    }
  }
}))

interface IProps {
  onClose: () => void
}

export const CollectionSelectModal = (props: IProps) => {
  const modalId = 'CollectionSelectModalId'
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  // props
  const { onClose } = props
  // states
  const [collections, setCollections] = useState<ICollectionResponse[]>()
  const [saving, setSaving] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const [selectedCollection, setSelectedCollection] = useState<ICollectionResponse>()
  const [stateMachine, setStateMachine] = useState<'loading' | 'success' | 'error' | 'saved' | 'create'>('loading')
  // atoms
  const selectedArticles = useAtomValue(selectedArticlesToSaveAtom)
  const selectedResources = useAtomValue(selectedResourcesToSaveAtom)

  const { profile, subscription } = useStore()

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollections()
      if (response.success) {
        setCollections(response.data.results)
        setStateMachine('success')
      } else {
        setStateMachine('error')
      }
    }
    setStateMachine('loading')
    fetchData()
  }, [])

  const saveCollection = async () => {
    if (selectedCollection) {
      setSaving(true)
      // using set to remove duplicate ids
      const articlesList = new Set([
        ...selectedArticles.map(s => s.id),
        ...selectedCollection.articles.map(a => a.id)
      ])
      const resourceList = new Set([
        ...selectedResources.map(s => s.id),
        ...selectedCollection.resources.map(r => r.id)
      ])
      const response = await patchCollectionLibrary(selectedCollection.collection.id, {
        title: selectedCollection.collection.title,
        items: {
          resources: Array.from(resourceList),
          articles: Array.from(articlesList)
        }
      })
      if (response.success) {
        setStateMachine('saved')
      } else {
        setStateMachine('error')
      }
    }
  }

  const createCollection = async () => {
    if (collectionName.length) {
      setSaving(true)
      const response = await postCollectionLibrary({
        title: collectionName,
        items: {
          resources: selectedResources.map(s => s.id),
          articles: selectedArticles.map(s => s.id)
        }
      })
      if (response.success) {
        setStateMachine('saved')
        setSelectedCollection({
          collection: response.data,
          articles: selectedArticles,
          resources: selectedResources,
          thumbnails: [
            ...selectedResources.map(s => s.thumbnail) ?? [],
            ...selectedArticles.map(s => s.thumbnail) ?? []
          ]
        })

        Analytics.recordEventClick({
          name: 'my_cloe_save_new_collection_created',
          attributes: {
            ...profile?.analytics,
            ...subscription?.analytics
          }
        })

      } else {
        setStateMachine('error')

        Analytics.recordEventClick({
          name: 'my_cloe_save_new_collection_created_error',
          attributes: {
            ...profile?.analytics,
            ...subscription?.analytics
          }
        })
      }
    }
  }

  const states = {
    loading: (
      <Grid container p={2} gap={2}>
        <Grid item xs={12} textAlign='right'>
          <Text type='body' size='small' color='medium'>
            <Close />
            {t('Fechar')}
          </Text>
        </Grid>
        <div style={{ width: '100%', height: '20px' }}>
          <Loading type='linear' />
        </div>
      </Grid>
    ),
    success: (
      <>
        <Grid container p={2} gap={2}>
          <Grid item xs={12}>
            <div style={{ justifyContent: 'right', display: 'flex' }}>
              <Text data-testid='my_cloe_save_choose_select_close' type='body' size='small' color='medium' style={{ display: 'flex', alignItems: 'center', gap: '14px', cursor: 'pointer' }} onClick={onClose}>
                <Close />
                {t('Fechar')}
              </Text>
            </div>
          </Grid>
          <Grid item xs={12} display='flex' flexWrap='wrap' maxHeight='60vh' overflow='auto' gap={2}>
            <AddCollectionCard
              onSelect={() => setStateMachine('create')}
              dataTestId='my_cloe_save_choose_select_new_collection'
            />
            {
              collections?.map((props, index) =>
                <CollectionCard
                  key={index}
                  collection={props}
                  selectMode
                  isSelected={props.collection.id === selectedCollection?.collection.id}
                  onSelect={c => setSelectedCollection(c)}
                  dataTestId='my_cloe_save_choose_select_collection'
                />
              )
            }
          </Grid>
        </Grid>
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_choose_select_cancel'
            onClick={onClose}
          >
            {t('Cancelar')}
          </Button>
          <Button
            disabled={!selectedCollection?.collection.id || saving}
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_choose_select_advance'
            onClick={saveCollection}
          >
            {t('Avançar')}
          </Button>
        </Grid>
        <BackToTop e={modalId} staryHeight='-47%' left={100} />
      </>
    ),
    error: (
      <>
        <BusinessError
          error={t('Erro ao buscar coleções. Tenve novamente!')}
        />
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_choose_select_error'
            onClick={onClose}
          >
            {t('Fechar')}
          </Button>
        </Grid>
      </>
    ),
    saved: (
      <>
        <Grid container p={2} gap={2}>
          <Grid item xs={12}>
            <div style={{ justifyContent: 'right', display: 'flex' }}>
              <Text data-testid='my_cloe_save_sucess_select_close' type='body' size='small' color='medium' style={{ display: 'flex', alignItems: 'center', gap: '14px', cursor: 'pointer' }} onClick={onClose}>
                <Close />
                {t('Fechar')}
              </Text>
            </div>
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <Text size='small' type='heading'>
              {t('Adicionado à coleção')}
            </Text>
          </Grid>
          <Grid item xs={12} display='flex' flexWrap='wrap' gap={2}>
            {
              selectedCollection &&
              <CollectionCard
                collection={selectedCollection}
                selectMode
              />
            }
          </Grid>
        </Grid>
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_success_select_close'
            onClick={onClose}
          >
            {t('Fechar')}
          </Button>
          <Button
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_success_select_gocollection'
            onClick={() => { window.location.href = COLLECTIONS(selectedCollection?.collection.id) }}
          >
            {t('Ir para a Coleção')}
          </Button>
        </Grid>
        <BackToTop e={modalId} staryHeight='-47%' left={100} />
      </>
    ),
    create: (
      <>
        <Grid container p={2} gap={2}>
          <Grid item xs={12}>
            <div style={{ justifyContent: 'right', display: 'flex' }}>
              <Text data-testid='my_cloe_save_new_collection_select_close' type='body' size='small' color='medium' style={{ display: 'flex', alignItems: 'center', gap: '14px', cursor: 'pointer' }} onClick={onClose}>
                <Close />
                {t('Fechar')}
              </Text>
            </div>
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <Text size='small' type='heading'>
              {t('Salvar em nova coleção:')}
            </Text>
          </Grid>
          <Grid item xs={12} className={classes.textInput}>
            <TextField
              id='input-collection-name'
              variant='filled'
              placeholder={t('Nome da coleção')}
              style={{ display: 'flex', width: '100%' }}
              onChange={e => setCollectionName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_new_collection_select_cancel'
            onClick={onClose}
          >
            {t('Cancelar')}
          </Button>
          <Button
            disabled={collectionName.length < 3 || saving}
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='my_cloe_save_new_collection_select_advance'
            onClick={createCollection}
          >
            {t('Avançar')}
          </Button>
        </Grid>
        <BackToTop e={modalId} staryHeight='-47%' left={100} />
      </>
    )
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in>
        <Box className={classes.modalWrapper}>
          {states[stateMachine]}
        </Box>
      </Fade>
    </Modal>
  )
}
