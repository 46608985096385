export const RESOURCES_LIBRARY_BY_ID = (id: number) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/resource/${id}`

export const RESOURCES_LIBRARY_SEARCH =
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/resource/search`

export const ARTICLES_LIBRARY_BY_ID = (id: number) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/article/${id}`

export const ARTICLES_LIBRARY_SEARCH =
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/article/search`

export const COLLECTIONS_LIBRARY =
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/collections`

export const COLLECTIONS_LIBRARY_BY_ID = (id: string) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/collections/${id}`

export const COLLECTIONS_CLOE_LIBRARY =
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/collections/cloe`

export const COLLECTIONS_CLOE_LIBRARY_BY_ID = (id: string) =>
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/collections/cloe/${id}`

export const COLLECTIONS_LIBRARY_SEARCH =
  `${process.env.REACT_APP_NAPI_SERVICE as string}/api/v1/collections/search`
