import { useMemo } from 'react'
import useStyle from './style'
import { Stack, Step, StepIcon, StepLabel, Stepper } from '@mui/material'
import { ColorlibConnector } from './components'

interface IStageCounter {
  stagesNumber: number
  currentStage: number
  onClick: (selectedStage: number) => void
}

export const StageCounter = ({ stagesNumber, currentStage, ...props }: IStageCounter) => {
  const classes = useStyle()

  const stageItems = useMemo(() => (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper activeStep={currentStage} connector={<ColorlibConnector />}>
        {
          new Array(stagesNumber).fill({}).map((_, index) => {
            const isActive = currentStage >= index
            return (
              <Step className={classes.stepItem} key={`stepper-${index}`}>
                <StepLabel
                  onClick={() => {
                    if (!isActive) return
                    props.onClick(index)
                  }}
                  StepIconComponent={(props) => (
                    <StepIcon
                      {...props}
                      sx={isActive ? {} : { cursor: 'not-allowed !important' }}
                      icon={props.icon}
                      active={isActive}
                      completed={false}
                    />
                  )}
                />
              </Step>
            )
          })
        }
      </Stepper>
    </Stack>
  ), [stagesNumber, currentStage, props.onClick])

  return (
    <div className={classes.container}>
      <div className={classes.itemsInternalContainer}>
        {stageItems}
      </div>
    </div>
  )
}
