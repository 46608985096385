import useStyle from './style'
import { Text2 } from 'components/design-system'
import { Link } from 'react-router-dom'

interface ExploreCardProps {
  title: string
  description: string
  iconComponent: React.ReactNode
  linkTo: string
  dataTestId?: string
}

export const ExploreCard = (props: ExploreCardProps) => {
  const classes = useStyle()

  return (
    <Link
      to={props.linkTo}
      className={classes.cardContainer}
      data-testid={props.dataTestId}
    >
      <div className={classes.titleGroup}>
        <Text2 className={classes.titleText} fontSize='md' fontWeight='bold' colorbrand='medium'>
          {props.title}
        </Text2>
        <span className={classes.iconContainer}>
          {props.iconComponent ?? null}
        </span>
      </div>
      <Text2 className={classes.description} fontSize='xs' fontWeight='medium' colorbrand='medium'>
        {props.description}
      </Text2>
    </Link>
  )
}
