import React, { Children, forwardRef, ReactNode, useRef, useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import useStyle from './style'

interface IProps {
  children: ReactNode
}

export const SwiperCarousel = forwardRef<HTMLDivElement, IProps>(({ children }, ref) => {
  const classes = useStyle()
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const checkOverflow = () => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollContainerRef.current

      setIsOverflowing(scrollWidth > clientWidth)
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth)
    }
  }

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [children])

  const handleScroll = () => {
    checkOverflow()
  }

  const smoothScrollBy = (container: HTMLElement, delta: number) => {
    const duration = 600
    const startTime = performance.now()
    const startScroll = container.scrollLeft
    const customEase = (progress: number) => {
      const overshoot = 1.3
      if (progress < 0.5) {
        return Math.pow(progress * 2, 3) / 2
      } else {
        const normalized = (progress - 0.5) * 2
        return (Math.pow(normalized, 3) / 2 + 0.5) * overshoot - (overshoot - 1)
      }
    }
    const step = (currentTime: number) => {
      const progress = Math.min((currentTime - startTime) / duration, 1)
      const easedProgress = customEase(progress)
      container.scrollLeft = startScroll + delta * easedProgress
      if (progress < 1) {
        requestAnimationFrame(step)
      }
    }
    requestAnimationFrame(step)
  }

  const scrollNext = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current
      const scrollAmount = container.clientWidth * 0.8
      smoothScrollBy(container, scrollAmount)
    }
  }

  const scrollPrev = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current
      const scrollAmount = container.clientWidth * 0.8
      smoothScrollBy(container, -scrollAmount)
    }
  }

  return (
    <div ref={containerRef} className={classes.exploreCardsContainer}>
      <div
        ref={scrollContainerRef}
        className={classes.carouselContainer}
        onScroll={handleScroll}
        style={{
          display: 'flex',
          overflowX: 'hidden',
          scrollSnapType: 'x mandatory',
          position: 'relative',
          gap: '16px',
          padding: '8px 0'
        }}
      >
        {Children.map(children, (child) => (
          <div
            style={{
              flexShrink: 0,
              scrollSnapAlign: 'start'
            }}
          >
            {child}
          </div>
        ))}
      </div>

      {isOverflowing && canScrollLeft && (
        <>
          <IconButton
            size='small'
            onClick={scrollPrev}
            className={`${classes.prevButton} ${classes.navigationCarouselButttons}`}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <div className={`${classes.cardsFadeBackgroundLeft} ${classes.cardsFadeBackground}`} />
        </>
      )}

      {isOverflowing && canScrollRight && (
        <>
          <IconButton
            size='small'
            onClick={scrollNext}
            className={`${classes.nextButton} ${classes.navigationCarouselButttons}`}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <div className={`${classes.cardsFadeBackgroundRight} ${classes.cardsFadeBackground}`} />
        </>
      )}
    </div>
  )
})

SwiperCarousel.displayName = 'SwiperCarousel'
