import { createStyles, makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

import { Theme } from '@mui/material'
import { IUploadProps } from '.'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'red',
      fontSize: '32px'
    },
    paddingInLoading: {
      paddingInline: theme.spacingInline.xxxs,
      paddingBlock: (props: IUploadProps) => props.isLoading ? theme.spacingInline.md : theme.spacingInline.xxxs
    }
  })
)

export const Text = styled('span')(({ theme }) => ({
  fontSize: theme.font.fontSize.sm,
  color: theme.colors.neutral.dark20,
  fontWeight: theme.font.fontWeight.medium
}))

export const Description = styled('div')(({ theme }) => ({
  fontSize: theme.font.fontSize.xxxs,
  color: theme.colors.neutral.dark20,
  fontWeight: theme.font.fontWeight.semibold,
  whiteSpace: 'pre-line',
  maxWidth: '335px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center'
}))

export const Center = styled('div')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacingInline.nano,
  gap: theme.spacingInline.nano
}))

export const Box = styled('div')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `${theme.spacingInline.nano} 0`,
  gap: theme.spacingInline.nano,
  width: '100%',
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B3B3B3FF' stroke-width='4' stroke-dasharray='16%2c 22' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px`
}))

export const DropArea = styled('div')(({ theme }) => ({
  width: '100%'
}))

export const IconWrapper = styled('span')(({ theme }) => ({
}))
