/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import EditorJS from '@editorjs/editorjs';

// Import tools using require to avoid ESM issues
const Header = require('@editorjs/header');
const List = require('@editorjs/list');
const Paragraph = require('@editorjs/paragraph');

export const EDITOR_TOOLS = {
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3, 4],
      defaultLevel: 2
    }
  },
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: 'unordered'
    }
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true
    }
  }
} as const;

export const DEFAULT_MIN_HEIGHT = 200;

export const ERROR_MESSAGES = {
  INIT: 'Failed to initialize editor',
  SAVE: 'Failed to save content',
  DESTROY: 'Failed to destroy editor'
} as const
