import { Skeleton } from '@mui/material'
import useStyles from './style'

export const LoadingGeneralInfoPage = () => {
  const classes = useStyles()

  const textHeight = 40
  const inputHeight = 70

  return (
    <div className={classes.fieldsContainer}>
      <div className={classes.fieldsContainerRow}>
        <div className={classes.formInputContainer}>
          <Skeleton sx={{ width: '100px' }} height={textHeight} />
          <Skeleton sx={{ width: '100%' }} height={inputHeight} />
        </div>
        <div className={classes.formInputContainer}>
          <Skeleton sx={{ width: '100px' }} height={textHeight} />
          <Skeleton sx={{ width: '100%' }} height={inputHeight} />
        </div>
        <div className={classes.formInputContainer}>
          <Skeleton sx={{ width: '100px' }} height={textHeight} />
          <Skeleton sx={{ width: '100%' }} height={inputHeight} />
        </div>
      </div>
      <div className={classes.fieldsContainerRow}>
        <div className={classes.formInputContainer}>
          <Skeleton sx={{ width: '150px' }} height={textHeight} />
          <Skeleton sx={{ width: '100%' }} height={inputHeight} />
        </div>
      </div>
      <div className={classes.fieldsContainerRow}>
        <div className={classes.formInputContainer}>
          <Skeleton sx={{ width: '150px' }} height={textHeight} />
          <Skeleton sx={{ width: '100%' }} height={inputHeight} />
        </div>
      </div>
    </div>
  )
}
