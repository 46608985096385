import React from 'react'
import { useTranslation } from 'react-i18next'
import PreviewCard from '../PreviewCard'
import { Wrapper, Title, PreviewArea } from './style'

type PreviewProps = {
  files: File[]
  handleRemoveFile: (index: number) => void
}
const Preview: React.FC<PreviewProps> = ({ files, handleRemoveFile }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Title>
        {t('Arquivos enviados')}
      </Title>

      <PreviewArea>
        {
          files.map((preview, index) =>
            <PreviewCard
              key={index}
              index={index}
              title={preview.name}
              handleRemoveFile={handleRemoveFile}
            />)
        }
      </PreviewArea>
    </Wrapper>
  )
}

export default Preview
