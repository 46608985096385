import { useEffect, useState } from 'react'
import { Box, Grid, Modal, Fade, Backdrop, useTheme, Theme } from '@mui/material'
import { SchoolOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import BackToTop from 'components/common/BackToTop'
import { Tag, Button, Text, Search } from 'components/design-system'
import { createStyles, makeStyles } from '@mui/styles'
import { useStore } from 'store'
import { IClassResponse, IDisciplineResponse } from 'services/types'
import { ISubscriptionsTaughtDisciplinesResponse } from 'services/types/taught-disciplines'
import { getSubscriptionTaughtDisciplinesBySchool } from 'services/taught-disciplines'
import wormsBg from 'assets/cloe-worms.svg'
import { ReactComponent as CloeCrossArms } from 'assets/cloe-avatar-arms-crossed.svg'
import { Loading } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { NavLink } from 'react-router-dom'
import { TAUGHT_DISCIPLINES } from 'navigation/CONSTANTS'

const useStyles = makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '892px',
    width: '100%',
    maxHeight: 'calc(100vh - 16px)',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.md,

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'grid',
      borderRadius: '0px',
      gridTemplateRows: 'auto 10fr 1fr',
      overflowY: 'auto'
    }
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    background: 'rgba(230, 230, 230, 0.32)',
    borderRadius: `0 0 ${theme.borderRadius.md} ${theme.borderRadius.md}`
  },
  tagDefault: {
    cursor: 'pointer',
    width: 'fit-content',
    height: 'max-content',
  },
  tagSelected: {
    background: `${theme.colorBrand.medium} !important`,
    color: theme.colors.neutral.lightBase
  },
  classCardContainer: {
    width: '274px',
    padding: theme.spacingInline.xxxs,
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light10}`,
    background: theme.colors.neutral.lightBase,
    backgroundImage: `url(${wormsBg})`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
    cursor: 'pointer',
    '&.selected': {
      border: `2px solid ${theme.colorBrand.medium}`
    }
  },
  classCardHeader: {
    display: 'flex',
    color: theme.colorBrand.medium,
    gap: theme.spacingInline.nano
  },
  classCardTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: theme.colors.neutral.dark20,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    lineHeight: theme.font.lineHeight.xs,
    letterSpacing: '0.16px',
    textTransform: 'uppercase'
  },
  classCardDisciplines: {
    marginTop: theme.spacingInline.xxxs,
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacingInline.nano
  },
  classCardDisciplinesTag: {
    background: theme.colorBrand.lightest,
    color: theme.colorBrand.dark
  }
}))

interface IProps {
  onClose: () => void
  onConfirm: (classSelected: IClassResponse, disciplines: IDisciplineResponse[]) => void
}

export const ClassSelectorModal = (props: IProps) => {
  const modalId = 'ClassSelectorModalId'
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  // props
  const { onClose, onConfirm } = props
  // store
  const { profile, session, schoolPeriod } = useStore()
  // states
  const [search, setSearch] = useState('')
  const [selectedGradeTypeId, setSelectedGradeTypeId] = useState<number>()
  const [stateMachine, setStateMachine] = useState<'loading' | 'success' | 'empty' | 'error'>('loading')
  const [subscription, setSubscription] = useState<ISubscriptionsTaughtDisciplinesResponse[]>([])
  const [selectedClass, setSelectedClass] = useState<IClassResponse>()
  const [selectedClassDisciplines, setSelectedClassDisciplines] = useState<IDisciplineResponse[]>([])

  useEffect(() => {
    getSubscriptionsTaughtDisciplines()
  }, [])

  const getSubscriptionsTaughtDisciplines = async () => {
    const response = await getSubscriptionTaughtDisciplinesBySchool(schoolPeriod?.id)

    if (!response.success) {
      setStateMachine('error')
      return
    }

    const searchSubscriptionFromProfile = session?.subscriptions.find(cur => cur?.user_school_profile?.id && profile?.id && cur.user_school_profile.id === profile.id)
    if (!searchSubscriptionFromProfile || !response.data?.length) {
      setStateMachine('empty')
      return
    }

    setStateMachine('success')
    setSubscription(response.data)
    setSelectedGradeTypeId(response.data[0].id)
  }

  interface IClassDisciplines {
    id: number
    name: string
    disciplines: IDisciplineResponse[]
  }

  interface IClassCardProps {
    cls: IClassDisciplines
  }

  const ClassCard: React.FC<IClassCardProps> = ({ cls }) => (
    <div
      className={`
        ${classes.classCardContainer}
        ${selectedClass?.id === cls.id && 'selected'}`}
      onClick={() => {
        setSelectedClass({
          id: cls.id,
          name: cls.name,
          grade_id: 0,
          school_id: 0,
          student_limit: 0,
          created_at: '',
          updated_at: ''
        })
        setSelectedClassDisciplines(cls.disciplines)
      }}
    >
      <div className={classes.classCardHeader}>
        <SchoolOutlined />
        <div className={classes.classCardTitle}>
          {cls.name}
        </div>
      </div>
      <div className={classes.classCardDisciplines}>
        <Tag variant={cls.disciplines[0].code}>
          {cls.disciplines[0].name}
        </Tag>
        {
          cls.disciplines.length > 1 &&
            <Tag className={classes.classCardDisciplinesTag}>
              +{cls.disciplines.length - 1}
            </Tag>
        }
      </div>
    </div>
  )

  const states = {
    loading: (
      <Grid container p={2} gap={2}>
        <Grid item xs={12}>
          <Text type='heading' size='small' color='medium'>
            {t('Destinatários')}
          </Text>
        </Grid>
        <div style={{ width: '100%', height: '20px' }}>
          <Loading type='linear' />
        </div>
      </Grid>
    ),
    success: (
      <>
        <Grid container p={2} gap={2}>
          <Grid item xs={12}>
            <Text type='heading' size='small' color='medium'>
              {t('Destinatários')}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Search
              placeholder={t('Pesquisar turma')}
              onSubmit={(pattern) => setSearch(pattern)}
              onClear={() => setSearch('')}
              hideAssistiveText
            />
          </Grid>
          <Grid item xs={12} display='flex' flexWrap='wrap' gap={2}>
            {
              subscription?.map(g =>
                <Tag
                  key={g.id}
                  className={`
                    ${classes.tagDefault} 
                    ${g.id === selectedGradeTypeId && classes.tagSelected}
                  `}
                  onClick={() => setSelectedGradeTypeId(g.id)}
                >
                  {g.name}
                </Tag>
              )
            }
          </Grid>
          <Grid item container xs={12} sx={{ maxHeight: 'calc(100vh - 375px) !important', overflowY: 'auto' }} gap={2}>
            {
              subscription?.filter(s => s.id === selectedGradeTypeId)?.map(sub =>
                sub.grades
                  .filter(g => search === '' ||
                    g.classes.some(c => c.name.toUpperCase().includes(search.toUpperCase())))
                  .map(g =>
                    <Grid key={g.id} xs={12} item container gap={2}>
                      <Grid item xs={12}>
                        <Text type='body' size='large' color='medium'>{g.name}</Text>
                      </Grid>
                      <Grid item xs={12} display='flex' flexWrap='wrap' gap={2}>
                        {g.classes
                          .filter(c => search === '' || c.name.toUpperCase().includes(search.toUpperCase()))
                          .map(c =>
                            <ClassCard
                              key={c.id}
                              cls={c}
                            />
                          )}
                      </Grid>
                    </Grid>))
            }
          </Grid>
        </Grid>
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='class_selector_modal_cancel'
            onClick={onClose}
          >
            {t('Cancelar')}
          </Button>
          <Button
            disabled={!selectedClass}
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='class_selector_modal_confirm'
            onClick={() => selectedClass && onConfirm(selectedClass, selectedClassDisciplines)}
          >
            {t('Confirmar')}
          </Button>
        </Grid>
        <BackToTop e={modalId} staryHeight='-47%' left={100} />
      </>
    ),
    empty: (
      <>
        <Grid container p={2} gap={1}>
          <Grid item xs={12}>
            <Text type='heading' size='small' color='medium'>
              {t('Destinatários')}
            </Text>
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <CloeCrossArms />
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <Text type='body' size='large' color='medium'>
              {t(`Você ainda não possui turmas configuradas em ${new Date().getFullYear()}`)}
            </Text>
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <Text type='body' size='medium'>
              {t('Para compartilhar um recurso, é necessário haver ao menos uma turma configurada.')}
            </Text>
          </Grid>
        </Grid>
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='class_selector_modal_cancel'
            onClick={onClose}
          >
            {t('Cancelar')}
          </Button>
          <NavLink to={TAUGHT_DISCIPLINES} style={{ textDecoration: 'none' }}>
            <Button
              sx={{
                padding: '8px 12px !important',
                gap: '8px',
                fontWeight: `${theme.font.fontWeight.semibold} !important`
              }}
              data-testid='class_selector_modal_confirm'
              onClick={() => selectedClass && onConfirm(selectedClass, selectedClassDisciplines)}
            >
              {t('Configurar turmas')}
            </Button>
          </NavLink>
        </Grid>
        <BackToTop e={modalId} staryHeight='-47%' left={100} />
      </>
    ),
    error: (
      <>
        <BusinessError
          error={t('Erro ao buscar turmas. Tenve novamente!')}
        />
        <Grid item p={2} gap={5} className={classes.footer}>
          <Button
            variant='ghost'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.semibold} !important`
            }}
            data-testid='class_selector_modal_cancel'
            onClick={onClose}
          >
            {t('Fechar')}
          </Button>
        </Grid>
      </>
    )
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in>
        <Box className={classes.modalWrapper}>
          {states[stateMachine]}
        </Box>
      </Fade>
    </Modal>
  )
}
