import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import ChemicalsHome from 'assets/chemicals-rotated.png'
import GlobeHome from 'assets/globe-rotated.png'

export default makeStyles((theme: Theme) => createStyles({
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    backgroundColor: theme.colors.neutral.lightBase,
    backgroundImage: `url(${GlobeHome}), url(${ChemicalsHome}) `,
    backgroundPosition: 'calc(0vw - 60px) calc(0vh - 80px), calc(100vw - 350px) calc(0vh + 85px)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '300px',
    overflowX: 'hidden',
    padding: theme.spacingInset.md,
    paddingBottom: theme.spacingStack.xl,
    borderRadius: theme.borderRadius.md,
    '@media (max-width:1200px)': {
      backgroundSize: '250px',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '200px',
      backgroundPosition: 'calc(0vw - 80px) calc(0vh - 90px), calc(100vw - 225px) calc(0vh + 130px)',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacingStack.xxxl,
      backgroundSize: '200px',
      backgroundPosition: 'calc(0vw - 80px) calc(0vh - 50px), calc(100vw - 100px) calc(0vh + 140px)',
    },
  },
  contentSections: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: `${theme.spacingInset.lg} 0`,
    gap: theme.spacingStack.xl
  },
  skelletonRounded: {
    borderRadius: `${theme.borderRadius.md}!important`
  }
}))
