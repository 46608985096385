/* eslint-disable @typescript-eslint/dot-notation */
import * as SvgIcons from '@mui/icons-material'
import { createRef } from 'react'
import useStyles from './style'

export type IndexField = {
  title: string
  iconName: keyof typeof SvgIcons
  dataTestId?: string
}

interface IIndexProps {
  indexes: IndexField[]
  activeIndex: number
  setActiveIndex: (index: number) => void
}

export const IndexComponent: React.FC<IIndexProps> = ({ indexes, activeIndex, setActiveIndex }) => {
  const classes = useStyles()

  const GenerateIcon = (variation: keyof typeof SvgIcons) => {
    const IconName = SvgIcons[variation]
    return <IconName />
  }

  const RenderOption = ({ index, indexOption }: { indexOption: IndexField, index: number }) => {
    const inputRef = createRef<HTMLDivElement>()

    return (
      <>
        <div ref={inputRef} hidden data-testid={indexOption?.dataTestId ?? ''} />
        <label className={`${classes.indexOption} ${index === activeIndex ? 'active' : ''}`} onClick={() => { inputRef?.current?.click(); setActiveIndex(index) }}>
          {GenerateIcon(indexOption.iconName)}
          <span>{indexOption.title}</span>
        </label>
      </>
    )
  }

  return (
    <div className={classes.indexContainer}>
      <div className={classes.indexContent}>
        {indexes?.map((indexOption, index) => <RenderOption key={index} index={index} indexOption={indexOption} />)}
      </div>

      <div className={classes.indexPaginationContent}>
        <span
          className={`${classes.indexPaginationIcon} ${activeIndex === 0 ? 'disabled' : 'withColor'}`}
          onClick={() => {
            if (activeIndex === 0) return
            setActiveIndex(activeIndex - 1)
          }}
        >
          <SvgIcons.KeyboardArrowLeft />
        </span>
        <div className={classes.indexBar} />
        <span
          className={`${classes.indexPaginationIcon} ${(activeIndex + 1) === indexes.length ? 'disabled' : 'withColor'}`}
          onClick={() => {
            if ((activeIndex + 1) === indexes.length) return
            setActiveIndex(activeIndex + 1)
          }}
        >
          <SvgIcons.KeyboardArrowRight />
        </span>
      </div>
    </div>
  )
}

export default IndexComponent
