import { Grid, Skeleton } from '@mui/material'
import { BusinessError } from 'navigation/BusinessError'
import { useTranslation } from 'react-i18next'

export const StateLoading = () => {
  return (
    <>
      <Grid item xs={12}>
        <Skeleton
          variant='text'
          sx={{ width: 248, height: 24 }}
        />
      </Grid>
      <Grid container item xs={12} mt={3}>
        <div>
          <Skeleton
            variant='circular'
            sx={{ width: 128, height: 128 }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 24 }}>
          <Skeleton
            variant='rectangular'
            sx={{
              width: 420,
              height: 40,
              borderRadius: '8px'
            }}
          />
          <br />
          <Skeleton
            variant='rectangular'
            sx={{
              width: 40,
              height: 16,
              borderRadius: '8px'
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} mt={3} display='flex' gap={4}>
        <Skeleton
          variant='rectangular'
          sx={{
            width: 350,
            height: 400,
            borderRadius: '8px'
          }}
        />
        <Skeleton
          variant='rectangular'
          sx={{
            width: 656,
            height: 368,
            borderRadius: '8px'
          }}
        />
      </Grid>
    </>
  )
}

export const StateError = () => {
  const { t } = useTranslation()
  return (
    <Grid xs={12} textAlign='center'>
      <BusinessError error={t('Não foi possível carregar o recurso. Tente novamente!')} />
    </Grid>
  )
}
