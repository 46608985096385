import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  '& .MuiPaginationItem-root': {
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xxs,
    letterSpacing: '0.12px',

    background: 'none',
    border: 'none',
    color: theme.colorBrand.medium,
    margin: `0 ${theme.spacingStack.xxs} 0 0`,
    '&:hover': {
      background: theme.colorBrand.lightest
    },
    '&.Mui-selected': {
      background: theme.colorBrand.lightest,
      color: theme.colorBrand.dark
    }
  }
})

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.outlined': {
      ...styleDefault(theme)
    }
  }
}))
