import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  generatingModal: {

  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxs,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cloeAvatar: {
    display: 'flex',
    width: '191.17px',
    height: '300px',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: '100%',
      objectFit: 'cover'
    }
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.lightBase,
    padding: `${theme.spacingInset.lg} ${theme.spacingInset.xs} ${theme.spacingInset.xs} !important`,
    borderRadius: theme.borderRadius.sm,

    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '460px',
    textAlign: 'center',

    '&.MuiDialogContent-root::-webkit-scrollbar': {
      width: '30px'
    },
    '&.MuiDialogContent-root::-webkit-scrollbar-thumb': {
      background: '#C4C4C4',
      borderRadius: '40px',
      backgroundClip: 'content-box',
      border: 'solid 10px transparent'
    }
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },
  loading: {
    width: '100%'
  }
}))
