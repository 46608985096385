/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'

// Dependency imports
import { useStore, useCurricularStore } from 'store'
import { IDisciplineStore, DisciplineCodeEnum } from 'services/types/discipline'
import Analytics from 'utils/analytics'

// Local imports
import { ListDisciplines } from './components/disciplines'
import { Error } from './components/error'
import { Loading } from './components/loading'
import { Idle } from './components/idle'

interface IDisciplines {
  dataTestid?: string
  showAll?: boolean
  hidden?: boolean
  disabled?: boolean
  title?: string
  showAlertOnError?: boolean
  onChange?: (discipline: IDisciplineStore) => void
}

type IStateRender = 'idle' | 'loading' | 'withDisciplines' | 'error'

export const Disciplines: React.FC<IDisciplines> = ({ dataTestid, showAll, hidden, disabled, title, showAlertOnError = false, onChange }) => {
  const [state, setState] = useState<IStateRender>('idle')
  const { subscription } = useStore()
  const { disciplines, currentDiscipline, setCurrentDiscipline, loadingDisciplines } = useCurricularStore()

  const localDisciplines = Object.assign([] as IDisciplineStore[], disciplines)

  if (showAll) {
    localDisciplines.unshift({
      id: '',
      name: 'Todos',
      code: DisciplineCodeEnum.ALL
    })
  }

  useEffect(() => {
    if (disciplines?.length || showAll) {
      init()
    } else if (state !== 'loading') {
      setState('error')
    }
  }, [disciplines])

  useEffect(() => {
    if (!showAll) return
    const availableDisciplinesIds = disciplines?.map(el => el.id)
    if (!currentDiscipline || !availableDisciplinesIds?.includes(currentDiscipline?.id)) {
      setCurrentDiscipline(localDisciplines[0])
    }
  }, [disciplines])

  useEffect(() => {
    if (loadingDisciplines) {
      setState('loading')
    }
  }, [loadingDisciplines])

  const handleEventAnalytics = (discipline: IDisciplineStore) => {
    if (subscription) {
      Analytics.recordEventClick({
        name: 'curricular_component',
        attributes: {
          ...subscription?.analytics,
          discipline_id: discipline?.id,
          discipline_title: discipline?.name
        }
      })
    }
  }

  const handleDiscipline = (discipline: IDisciplineStore): void => {
    setCurrentDiscipline(discipline)
    void onChange?.(discipline)
    void handleEventAnalytics(discipline)
  }

  const stateRender = {
    idle: <Idle />,
    loading: <Loading />, // Carrega no middleware, tem como ter loading aqui?
    error: showAlertOnError ? <Error /> : <></>,
    withDisciplines: (
      <ListDisciplines
        dataTestid={dataTestid}
        disciplines={localDisciplines}
        currentDiscipline={currentDiscipline}
        onChange={handleDiscipline}
        hidden={hidden}
        disabled={disabled}
        title={title}
      />
    )
  }

  const init = () => {
    setState('withDisciplines')
    if (!currentDiscipline) {
      handleDiscipline(localDisciplines[0])
    } else if (currentDiscipline?.code === DisciplineCodeEnum.ALL && disciplines) {
      handleDiscipline(disciplines[0])
    }
  }

  return stateRender[state]
}
