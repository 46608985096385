import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,

    [theme.breakpoints.up('md')]: {
      width: '712px'
    }
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm
  },
  inputFields: {
    display: 'flex',
    width: 'auto !important'
  },
  fieldsContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.md,

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  resourcesRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInline.xxxs
  },
  usedResources: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacingInline.xxs
  }
}))
