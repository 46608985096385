import React from 'react'
import { IContentResponse, IResourceResponse } from 'services/types'
import { useTranslation } from 'react-i18next'
import { BusinessError } from 'navigation/BusinessError'
import {
  Text,
  Glossary,
  Quote,
  ExpandableImageWrapper,
  Resource,
  Reference,
  KeyConcept
} from 'components/common'
import { ResourceGallery } from 'components/common/ResourceGallery'
import { ModalResourceGallery } from 'components/common/ResourceGallery/components/Modal'

interface IContentProps {
  content: IContentResponse
  type: string
  showGalleryFormat?: boolean
}

// TODO: by default all types are content (only paragraphs), we're just overwriting those who has different content
// TODO: refatorar pro conceito de section -> content
export const ContentTypes: { [key: string]: any } = {
  gallery: (data: IResourceResponse) => data?.medias && <ExpandableImageWrapper key={data.id} medias={data.medias} />,
  content: (data: IContentResponse) => <Text key={data.id} content={data.text ?? ''} title={data.title ?? null} />,
  glossary: (data: IContentResponse) => <Glossary key={data.id} content={data.text ?? ''} />,
  quote: (data: IContentResponse) => <Quote key={data.id} content={data.text ?? ''} />,
  reference: (data: IContentResponse) => <Reference key={data.id} content={data.text ?? ''} />,
  key_concept: (data: IContentResponse) => <KeyConcept key={data.id} content={data.text ?? ''} />
}

export const Content: React.FC<IContentProps> = ({ content, type, showGalleryFormat = false }) => {
  const { t } = useTranslation()

  if (!type) return <BusinessError error={t('Falha ao carregar tipo de conteúdo')} />

  const handleResourceMedias = (resource: IResourceResponse, index: number) => {
    if (resource.medias?.length && resource.medias?.length > 1) {
      const formattedMedias = resource.medias?.map(media => ({ media, source: resource.source, description: resource.description }))
      return (
        <React.Fragment key={index}>
          <ResourceGallery medias={formattedMedias} source={resource.source} description={resource.description} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment key={index}>
          <Resource resource={resource} />
        </React.Fragment>
      )
    }
  }

  const render = () => {
    // default is content
    const typeRender = ContentTypes[type] ? type : 'content'

    return (
      <>
        {
          ContentTypes[typeRender](content)
        }
        {
          showGalleryFormat
            ? content?.resources?.map((cur, i) => handleResourceMedias(cur, i))
            : content?.resources?.map(cur =>
              <React.Fragment key={cur.id}>
                <Resource resource={cur} />
              </React.Fragment>)
        }
        <ModalResourceGallery />
      </>
    )
  }

  return render()
}
