import useStyle from './style'
import { Text2 } from 'components/design-system'
import { useEffect, useState } from 'react'
import { CollectionCard, SwiperCarousel } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ICollectionResponse } from 'services/types/library'
import { getCollections, getCollectionsCloe } from 'services/library'
import { LibrarySearchSkeleton } from '../CloeMaterialSearch/components'
import { BusinessError } from 'navigation/BusinessError'
import { useGradeDisciplines } from 'services/segments'
import { Skeleton } from '@mui/material'

interface ICollectionsSectionProps {
  title: string
  originalCloe?: boolean
  dataTestId?: string
}

export const CollectionsSection = ({ title, originalCloe = false, dataTestId }: ICollectionsSectionProps) => {
  const classes = useStyle()

  const { t } = useTranslation()
  const [state, setState] = useState<'loading' | 'success' | 'empty' | 'error'>('loading')
  const [collectionsResponse, setCollectionsResponse] = useState<ICollectionResponse[]>()
  const { segments } = useGradeDisciplines({})

  useEffect(() => {
    const fetchData = async () => {
      const response = originalCloe ? await getCollectionsCloe(segments.map(s => s.id)) : await getCollections()
      if (response.success) {
        setCollectionsResponse(response.data.results)
        if (!response.data.results.length) setState('empty')
        else setState('success')
      } else {
        setState('error')
      }
    }

    if (segments.length) {
      setState('loading')
      fetchData()
    }
  }, [segments])

  const render = {
    loading: (
      <>
        <Skeleton variant='text' width={200} />
        <LibrarySearchSkeleton mockCount={4} />
      </>
    ),
    success: (
      <>
        <div className={classes.headerContainer}>
          <Text2 fontSize='lg' fontWeight='semibold' colorbrand='medium'>
            {title}
          </Text2>
        </div>
        <SwiperCarousel>
          {collectionsResponse?.map((props, index) => (
            <CollectionCard
              key={index}
              collection={props}
              dataTestId={dataTestId}
            />
          ))}
        </SwiperCarousel>
      </>
    ),
    empty: (<></>),
    error: (
      <BusinessError removeImage error={t('Houve um erro ao buscar as coleções. Recarregue...')} />
    )
  }

  return (
    <section className={classes.homeSection}>
      {render[state]}
    </section>
  )
}
