import CloeSuperToggle, { Enabled } from 'components/cloeSuperToggle'
import React from 'react'

export const cloeSuperFeatureOn: React.FC = ({ children }) => {
  return (
    <CloeSuperToggle>
      <Enabled>
        {children}
      </Enabled>
    </CloeSuperToggle>
  )
}
