import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import cloeAvatar from 'assets/cloe-avatar-arms-crossed.svg'

export default makeStyles((theme: Theme) => createStyles({
  image404: {
    backgroundImage: `url(${cloeAvatar})`,
    backgroundSize: '100%',
    maxWidth: '16rem',
    height: '16rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat',
    display: 'block'
  },
  titleEmptyDisciplines: {
    fontSize: theme.font.fontSize.xxxxl,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    fontFamily: theme.font.fontFamily
  },
  container: {
    textAlign: 'center',
    display: 'grid',
    width: '100%',
    height: '100%',
    justifyItems: 'center',
    alignContent: 'center',
    padding: '40px 60px 40px 60px',

    gap: theme.spacingStack.xxs
  },
  title: {
    maxWidth: '700px'
  },
  cloeAvatar: {
    display: 'flex',
    width: '220px',
    height: '224px'
  }
}))
