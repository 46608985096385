import React from 'react'
import { useAtom } from 'jotai'

import {
  Grid,
  Card,
  CardContent
} from '@mui/material'

/*
  Estamos reaproveitando o componente da timeline, mas seria ideal separarmos...
  afim de 'unificar' a responsabilidade da visualização dos arquivos.
  Mas para isso acontecer, precisamos refatorar a logica para centraliza-la em um componente
*/
import MediaCard from 'components/common/CustomizedTimeline/AnsweredFile'

import { tabIndexModalAtom, openModalAtom, modalContentAtom } from './atomStore'

import { IMediaResponse } from 'services/types'

import useStyles from './style'
import { ResourceCaption } from '../ResourceCaption'

export interface IMedia {
  media: IMediaResponse
  source?: string | null
  description?: string | null
  caption?: string | null
}

interface IResourceGalleryProps {
  medias: IMedia[]
  source?: string | null
  description?: string | null
}

export const ResourceGallery = ({ medias, source, description }: IResourceGalleryProps) => {
  const classes = useStyles()

  const [, setIndexSelected] = useAtom(tabIndexModalAtom)
  const [open, setOpen] = useAtom(openModalAtom)
  const [, setModalContent] = useAtom(modalContentAtom)

  return (
    <>
      <Grid container spacing={3}>
        <Grid container spacing={3} className={classes.itensContainer}>
          {medias.map(({ media }, index) =>
            <Grid item key={media.id}>
              <Card
                className={classes.cardItemTimeline}
                onClick={() => [setOpen(!open), setIndexSelected(index), setModalContent(medias)]}
              >
                <CardContent
                  className={classes.cardItemContentTimeline}
                  sx={{ '&.MuiCardContent-root': { paddingBottom: 0 } }}
                >
                  <MediaCard key={media.id} src={media.formats?.thumbnail?.url ? media.formats.thumbnail.url : media.url} />
                </CardContent>
              </Card>
            </Grid>
          )
          }
        </Grid>
        <Grid item xs={12} textAlign='center'>
          <ResourceCaption source={source} description={description} />
        </Grid>
      </Grid>
    </>
  )
}
