import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  tabContainer: {
    position: 'absolute',
    top: '7px',
    left: 0,
    right: 0,
    fontSize: `${theme.font.fontSize.sm} !important`,
    fontWeight: `${theme.font.fontWeight.semibold} !important`,
    lineHeight: `${theme.font.lineHeight.xxs} !important`,
    letterSpacing: '0.16px !important',
    textTransform: 'capitalize !important' as any,
    padding: theme.spacingInline.xxxs,

    background: '#F5F3F8 !important',
    color: `${theme.colorBrand.medium} !important`,
    borderRadius: '16px 16px 0 0',
    borderBottom: '0 !important',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
