import { IResourceTypesEnum } from './activities'
import { IResourceResponse as IResourceResponseStrapi } from 'services/types'

export interface ISearchRequest {
  term: string
  pageNumber: number
  pageSize: number
  grades?: number[]
  gradeTypes: number[]
  disciplines: number[]
  types?: string[]
  madeByCloe?: boolean
  printable?: boolean
}

export interface ISearchResponse<T> {
  pageNumber: number
  pageSize: number
  totalCount: number
  totalPages: number
  results: T[]
}

export interface IResourceResponse {
  id: string
  resourceId: number
  title: string
  source: string
  type: IResourceTypesEnum
  madeByCloe: boolean
  embeded: boolean
  htmlContent: string | null
  description: string | null
  link: string
  thumbnail: string
  metadata: {
    grades: number[]
    gradeTypes: number[]
    disciplines: number[]
    tags: string[]
    categories: string[]
    comment?: string
    articles: Array<{
      title: string
      context: string
      comment: string
      tags: string[]
      categories: string[]
    }>
  }
}

export const mapToStrapiResource = (resource: IResourceResponse, strapiResource?: IResourceResponseStrapi): IResourceResponseStrapi => {
  return {
    id: resource.resourceId,
    title: resource.title,
    code: resource.id,
    source: resource.source,
    type: resource.type,
    is_embeded: resource.embeded,
    show_on_library: strapiResource?.show_on_library ?? '',
    legacy_id: strapiResource?.legacy_id ?? 0,
    description: resource.description,
    html_content: resource.htmlContent,
    link: resource.link,
    locale: strapiResource?.locale ?? '',
    published_at: strapiResource?.published_at ?? '',
    created_at: strapiResource?.created_at ?? '',
    updated_at: strapiResource?.updated_at ?? '',
    medias: strapiResource?.medias,
    thumb_link: resource.thumbnail
  }
}

export interface ICoverFormats {
  ext: string
  hash: string
  height: number
  width: number
  mime: string
  name: string
  url: string
}

export interface IArticleResponse {
  id: string
  articleId: number
  title: string
  context: string
  comment: string
  tags: string[]
  grades: number[]
  gradeTypes: []
  disciplines: []
  thumbnail: string
}

export interface ICollectionResponse {
  collection: ICollectionCreatedRespone
  resources: IResourceResponse[]
  articles: IArticleResponse[]
  thumbnails: string[] | null
}

export interface ICollectionCreatedRespone {
  id: string
  title: string
  description: string
  author: {
    type: string
    identifier: number
    name: string
  }
  gradeTypes: number[],
  tags: string[],
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  status: string
  totalItems: number
}

export interface ICollectionRequest {
  title: string
  items: {
    resources: string[]
    articles: string[]
  }
}
