import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { addTransparencyToColor } from 'utils/colors'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,

    [theme.breakpoints.up('md')]: {
      width: '712px'
    }
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,
    backgroundColor: addTransparencyToColor(theme.colors.neutral.light10, 0.32)
  },
  text: {
    lineHeight: theme.font.lineHeight.xs,
    flexWrap: 'wrap',
    width: '100%',
    margin: 0,
    color: theme.colors.neutral.darkBase,
    padding: theme.spacingInset.xs,
    borderRadius: theme.borderRadius.sm,
    '& > p': {
      marginBlock: '0px'
    },
    '& .codex-editor__redactor': {
      paddingBottom: '50px !important'
    }
  },
}))
