import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemsInternalContainer: {
    display: 'flex',
    width: '85%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepItem: {
    padding: '0 !important',

    '& .MuiStepLabel-iconContainer': {
      padding: '0 !important',
      cursor: 'pointer'
    },
    '& .MuiStepIcon-root': {
      width: '32px',
      height: '32px',
      color: 'transparent',
      borderRadius: theme.borderRadius.circular,
      border: `2px solid ${theme.colorBrand.lightest}`,
      '& .MuiStepIcon-text': {
        fill: `${theme.colorBrand.lightest} !important`,
        fontFamily: 'Montserrat !important'
      },

      '&.Mui-active, &.Mui-completed': {
        color: `${theme.colorBrand.medium} !important`,
        border: 'none !important',

        '& .MuiStepIcon-text': {
          fill: `${theme.colors.neutral.lightBase} !important`
        }
      }
    }
  }
}))
