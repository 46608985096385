import { Dialog } from '@mui/material'
import useStyles from './style'
import { Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { Loading } from 'components/common'
import CloeAvatar from 'assets/cloe-new-builder.webp'

interface IGeneratingClassPlanModalProps {
  isOpen: boolean
}

export const GeneratingClassPlanModal = ({ isOpen = false }: IGeneratingClassPlanModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      scroll='paper'
      open={isOpen}
      className={classes.generatingModal}
    >
      <div className={classes.modalBody}>
        <div className={classes.infoContainer}>

          <div className={classes.cloeAvatar}>
            <img title='Avatar Cloe' src={CloeAvatar} />
          </div>

          <Text2 fontSize='md' fontWeight='medium' colorbrand='medium'>
            {t('Seu plano de aula personalizado está sendo preparado. Só mais um instante!')}
          </Text2>
        </div>
        <div className={classes.loadingContainer}>
          <Loading type='linear' className={classes.loading} />
        </div>
      </div>
    </Dialog>
  )
}
