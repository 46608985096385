import { Button } from 'components/design-system'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import { useMediaQuery, useTheme } from '@mui/material'

import { STATES } from '../..'

import useStyle from '../../style'
import { useTranslation } from 'react-i18next'

interface IClassPlanFooterProps {
  stateMachine: STATES
  handlePageTransition: (movement: "next" | "previous") => void
  handleCancelPlanCreation: () => void,
  handleExportClassPlan: () => Promise<void>
  handleCreateClassPlan: () => Promise<void>
  handleSaveClassPlan: () => Promise<void>
}

export const ClassPlanFooter = ({
  stateMachine,
  handlePageTransition,
  handleCancelPlanCreation,
  handleExportClassPlan,
  handleCreateClassPlan,
  handleSaveClassPlan
}: IClassPlanFooterProps) => {
  const classes = useStyle()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const footerStateRender = {
    [STATES.GENERAL_INFO]: (
      <>
        <Button
          variant='ghost'
          className={classes.cancelButton}
          onClick={() => handlePageTransition('previous')}
          data-testid='cloe_super_create_lesson_plan_step1_select_exit'
        >
          {t('Voltar')}
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={() => handlePageTransition('next')}
          data-testid='cloe_super_create_lesson_plan_step1_select_next_step'
        >
          {t('Continuar')}
        </Button>
      </>
    ),
    [STATES.DETAILS]: (
      <>
        <Button
          variant='ghost'
          className={classes.cancelButton}
          onClick={() => handlePageTransition('previous')}
          data-testid='cloe_super_create_lesson_plan_step2_select_back_step'
        >
          {t('Voltar')}
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={() => handlePageTransition('next')}
          data-testid='cloe_super_create_lesson_plan_step2_select_next_step'
        >
          {t('Continuar')}
        </Button>
      </>
    ),
    [STATES.CLOE_MATERIALS]: (
      <>
        <Button
          variant='ghost'
          className={classes.cancelButton}
          onClick={() => handlePageTransition('previous')}
          data-testid='cloe_super_create_lesson_plan_step3_select_back_step'
        >
          {t('Voltar')}
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={() => handlePageTransition('next')}
          data-testid='cloe_super_create_lesson_plan_step3_select_next_step'
        >
          {t('Continuar')}
        </Button>
      </>
    ),
    [STATES.PERSONAL_MATERIALS]: (
      <>
        <Button
          variant='ghost'
          className={classes.cancelButton}
          onClick={() => handlePageTransition('previous')}
          data-testid='cloe_super_create_lesson_plan_step4_select_back_step'
        >
          {t('Voltar')}
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={() => handlePageTransition('next')}
          data-testid='cloe_super_create_lesson_plan_step4_select_next_step'
        >
          {t('Continuar')}
        </Button>
      </>
    ),
    [STATES.REVIEW]: (
      <>
        <Button
          variant='ghost'
          className={classes.cancelButton}
          onClick={() => handlePageTransition('previous')}
          data-testid='cloe_super_create_lesson_plan_step5_select_back_step'
        >
          {t('Voltar')}
        </Button>
        <Button
          endIcon={<AutoAwesomeOutlinedIcon />}
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={handleCreateClassPlan}
          data-testid='cloe_super_create_lesson_plan_step5_select_create_plan'
        >
          {isMobile ? t('Criar') : t('Concluir e criar meu plano de aula')}
        </Button>
      </>
    ),
    [STATES.GENERATED_CLASS_PLAN]: (
      <>
        <Button
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={handleSaveClassPlan}
          data-testid='cloe_super_create_lesson_plan_step6_select_save'
        >
          {isMobile ? t('Salvar') : t('Salvar na Biblioteca')}
        </Button>
        <Button
          variant='primary'
          className={classes.backNextPreviousButton}
          onClick={handleExportClassPlan}
          data-testid='cloe_super_create_lesson_plan_step6_select_export'
        >
          {t('Exportar')}
        </Button>
      </>
    )
  }

  return footerStateRender[stateMachine]
}
