import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  formInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.nano
  }
}))
