import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  libraryTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start !important',
      gap: theme.spacingStack.xxs,
      '& button': {
        textTransform: 'unset !important',
        '& svg': {
          width: 'auto',
          height: '32px'
        }
      }
    },
    borderBottom: '1px solid',
    borderBottomColor: theme.colors.neutral.light10,

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      '& .MuiTabs-flexContainer': {
        gap: 0,
        '& button': {
          textTransform: 'unset !important',
          padding: '16px 8px !important',
          minHeight: '40px',
          '& svg': {
            width: 'auto',
            height: '30px !important'
          }
        }
      }
    }
  },
  skelletonRounded: {
    borderRadius: `${theme.borderRadius.md}!important`
  },
  libraryTab: {
    maxWidth: '200px !important',
    display: 'flex !important',
    justifyContent: 'flex-center !important',
    fontSize: `${theme.font.fontSize.sm}!important`,
    fontWeight: `${theme.font.fontWeight.semibold}!important`,
    paddingBottom: '16px !important',

    [theme.breakpoints.down('md')]: {
      fontSize: `${theme.font.fontSize.xs}!important`
    }
  },
  contentSections: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: `${theme.spacingInset.sm} 0`,
    gap: theme.spacingStack.xxs,
    width: '100%'
  },
  libraryFilterTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.xs,
    border: `2px solid ${theme.colors.neutral.light10}`
  },
  libraryFilterTagClose: {
    borderRadius: theme.borderRadius.circular,
    background: theme.colors.neutral.light40,
    color: theme.colors.neutral.lightBase,
    fontSize: '16px !important',
    cursor: 'pointer'
  },
  libraryClearFilterTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    border: 'none',
    background: theme.colors.neutral.lightBase,
    color: theme.colorBrand.medium,
    cursor: 'pointer',

    fontSize: '12px !important',
    fontWeight: 600,
    lineHeight: '16px', /* 133.333% */
    letterSpacing: '0.12px',
    padding: '0 16px !important',

    '& svg': {
      fontSize: '16px !important',
      color: theme.colors.neutral.darkBase
    }
  },
  libraryFilterButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacingInset.xxs,
    '& svg': {
      height: '24px !important'
    }
  },
  libraryFilterContainer: {
    [theme.breakpoints.down('md')]: {
      opacity: 0,
      position: 'fixed',
      top: '-1000vh',
      width: 'auto !important',
      maxWidth: 'unset !important',
      // display: 'none !important',
      transition: 'opacity  0.3s ease-in-out',

      '&.selected': {
        top: 0,
        left: 0,
        height: '100vh',
        zIndex: 9999,
        opacity: 1,
        background: theme.colorBrand.lightest,
        overflow: 'auto',
        padding: `${theme.spacingInline.xxxs} !important`
      }
    }
  }
}))
