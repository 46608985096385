import React, { forwardRef, useEffect, useState } from 'react'
import { EditorProps, EditorRef } from './types/editor.types'
import { EditorErrorBoundary } from './components/EditorErrorBoundary'
import { useEditor } from './hooks/useEditor'
import { DEFAULT_MIN_HEIGHT } from './constants/editor.constants'

export const Editor = forwardRef<EditorRef, EditorProps>((
  {
    className = '',
    initialHtml,
    onBlur,
    onError,
    readOnly = false,
    placeholder = 'Comece a digitar...',
    customFallback,
    minHeight = DEFAULT_MIN_HEIGHT,
    maxHeight
  },
  ref) => {
  const [initialHtmlMem, setInitialHtmlMem] = useState<string | undefined>()

  useEffect(() => {
    setInitialHtmlMem(initialHtml)
  }, [])

  const [containerRef, editorApi] = useEditor({
    initialHtml: initialHtmlMem,
    onBlur,
    onError,
    readOnly,
    placeholder
  })

  // Expose editor API through ref
  React.useImperativeHandle(ref, () => editorApi, [editorApi])

  return (
    <EditorErrorBoundary onError={onError} fallback={customFallback}>
      <div
        ref={containerRef}
        className={`editor-js-container ${className}`}
        style={{
          minHeight: `${minHeight}px`,
          maxHeight: maxHeight ? `${maxHeight}px` : undefined
        }}
      />
    </EditorErrorBoundary>
  )
})

Editor.displayName = 'Editor';
