import { atom } from 'jotai'
import { ISearchRequest } from 'services/types/library'
import { TAB_INDEX_ENUM } from '..'

const initialRequest: ISearchRequest = {
  disciplines: [],
  gradeTypes: [],
  types: [],
  madeByCloe: false,
  printable: false,
  pageNumber: 1,
  pageSize: 0,
  term: ''
}

const searchRequestAtom = atom<ISearchRequest>(initialRequest)

type UpdateSearchRequestAtomActions = {
  type: 'UPDATE' | 'RESET'
  payload?: ISearchRequest | undefined
}

export const updateSearchRequestAtom = atom(
  (get) => get(searchRequestAtom),
  (get, set, action: UpdateSearchRequestAtomActions) => {
    switch (action.type) {
      case 'RESET':
        // used to reset when leave library search
        set(searchRequestAtom, {
          ...initialRequest
        })
        break
      case 'UPDATE':
        if (action.payload) {
          set(searchRequestAtom, {
            ...action.payload
          })
        }
        break
    }
  }
)

export const selectedLibraryTabAtom = atom<TAB_INDEX_ENUM>(TAB_INDEX_ENUM.RECURSOS)
