import { Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

import cloeWorm from 'assets/cloe-worm-collection-card.svg'
import { ResourceThumb } from 'components/common/ResourceThumb'

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardContainer: {
    width: '248px',
    minWidth: '248px',
    height: '248px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.borderRadius.md,
    border: `1px dashed ${theme.colors.neutral.light10}`,
    backgroundColor: theme.colors.neutral.lightBase,
    backgroundImage: `url(${cloeWorm})`,
    padding: theme.spacingInset.xs,
    gap: theme.spacingStack.nano,
    textDecoration: 'none !important',
    cursor: 'pointer'
  },
  containerBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: theme.spacingStack.nano

  },
  containerThumbnails: {
    minWidth: '246px',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    '& > div': {
      position: 'absolute'
    },
    '& > div:first-child': {
      left: '0px',
      zIndex: 3
    },
    '& > div:nth-child(2)': {
      left: '32px',
      zIndex: 2
    },
    '& > div:nth-child(3)': {
      left: '64px',
      zIndex: 1
    }
  },
  iconContainer: {
    color: theme.colors.support.colorSupport02
  },
  titleGroup: {
    justifyContent: 'space-between',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    flexWrap: 'wrap'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  mediaCounter: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorBrand.medium,
    gap: theme.spacingInline.quarck
  },
  svgIconContainer: {
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export interface IProps {
  onSelect: () => void
  dataTestId?: string
}

export const AddCollectionCard = (props: IProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onSelect } = props

  return (
    <div
      className={classes.cardContainer}
      onClick={onSelect}
      data-testid={props?.dataTestId}
    >
      <div className={classes.containerBody}>
        <div className={classes.containerThumbnails}>
          <ResourceThumb
            type='add'
            size='small'
            originalCloe={false}
          />
        </div>
        <div className={classes.titleGroup}>
          <Text2 fontSize='lg' fontWeight='semibold' colorbrand='dark'>
            {t('Nova coleção')}
          </Text2>
        </div>
      </div>
    </div>
  )
}
