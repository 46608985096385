import { IApiResponse } from 'services/types'
import { ACCEPT_TERMS, GET_TERMS } from './CONSTANTS'
import { request } from 'services/base'
import { ITermsResponse, IAcceptanceTermsResponse, CLOE_TERMS_TYPES } from '../types/terms'

export const getTerms = async (type: CLOE_TERMS_TYPES): Promise<IApiResponse<ITermsResponse>> =>
  await request({ url: GET_TERMS(type) })

export const acceptTerms = async (dataRaw: IAcceptanceTermsResponse, type: CLOE_TERMS_TYPES): Promise<IApiResponse<IAcceptanceTermsResponse>> =>
  await request({
    url: ACCEPT_TERMS(type),
    method: 'POST',
    data: {
      ...dataRaw
    }
  })
