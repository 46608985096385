import { useTranslation } from 'react-i18next'
// lib external components
import { Grid } from '@mui/material'
// global components
import { Header } from './Header'
import { Text2 } from 'components/design-system'
import { OnlyProfile } from 'components/common'
import { IUserSchoolProfileTypeEnum } from 'services/types'

interface IEmptyStateTypes {
  dropDown: boolean
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmptyState = ({ dropDown, setDropDown }: IEmptyStateTypes) => {
  const { t } = useTranslation()

  return (
    <>
      <Header dropDown={dropDown} setDropDown={setDropDown} />
      <Grid
        flexDirection='row'
        display='flex'
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: 'inherit'
        }}
      >
        {dropDown && (
          <Text2
            sx={{
              width: '100%',
              margin: '16px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            fontSize='sm'
            mobile='sm'
            fontWeight='medium'
            lineHeight='xxs'
            neutral='dark30'
          >
            <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
              {t('Nada para responder no momento.')}
            </OnlyProfile>
            <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
              {t('Nada para corrigir no momento.')}
            </OnlyProfile>
          </Text2>
        )}
      </Grid>
    </>
  )
}
