import { Grid } from '@mui/material'
import { Fragment } from 'react'
import { IResourceResponse } from 'services/types'
import { Resource } from '../Resource'
import useStyles from './styles'

interface IEnunciateProps {
  description: string
  resources: IResourceResponse[]
}

export const Enunciate: React.FC<IEnunciateProps> = ({ description, resources }) => {
  const classes = useStyles()
  if (description ?? resources?.length) {
    return (
      <Grid container gap={'24px'} className={classes.enunciate}>
        <div
          className={classes.utteranceText}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {resources?.map(resource =>
          <Fragment key={resource.id}>
            <Resource resource={resource} />
            <br />
          </Fragment>
        )}
      </Grid>
    )
  }
  return <Fragment />
}
