import React, { useState, MouseEvent, ReactNode } from 'react'
import { Popover as PopoverMUI, Button, Theme } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTheme } from '@mui/styles'

import useStyles from './style'

interface IPopoverProps {
  title: string
  children?: ReactNode
}

export const Popover: React.FC<IPopoverProps> = ({ children, title }: IPopoverProps) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button className={classes.button} variant='contained' onClick={handleClick}>
        {title}
      </Button>
      <PopoverMUI
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: document.body.clientWidth < theme.breakpoints.values.sm ? 'left' : 'center'
        }}
        transformOrigin={{
          vertical: document.body.clientWidth < theme.breakpoints.values.sm ? 'center' : 'bottom',
          horizontal: document.body.clientWidth < theme.breakpoints.values.sm ? 'right' : 'left'
        }}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <span className={classes.title}>{title}</span>
            <span className={classes.iconClose} onClick={handleClose}><Close fontSize='large' /></span>
          </div>
          {children}
        </div>
      </PopoverMUI>
    </>
  )
}

export default Popover
