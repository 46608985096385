import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LibrarySearchSkeleton, LibrarySearchError, LibrarySearchEmpty } from './LibrarySearchStates'
import LibraryCard from 'components/common/LibraryCard'
import { IResourceResponse, ISearchResponse } from 'services/types/library'
import { Pagination, CheckBox, Switch, Text2, Text } from 'components/design-system'
import { SimpleCollapse } from 'components/common'
import { IDisciplineResponse } from 'services/types'
import { useEffect, useState } from 'react'
import { searchResourceLibrary } from 'services/library'
import { LibrarySearchFilters } from './LibrarySearchFilters'
import { useAtom } from 'jotai'
import { selectedResourcesAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'
import { selectedLibraryTabAtom, updateSearchRequestAtom } from '../atomStore'
import { IGradeTypesResponse } from 'services/types/taught-disciplines'
import useStyles from '../styles'
import { RenderWhenCondition } from 'utils/wrappers'
import { Close } from '@mui/icons-material'
import { TAB_INDEX_ENUM } from '..'
import { useStore } from 'store'
import Analytics from 'utils/analytics'

interface IProps {
  pageSize: number
  searchQuery: string
  filters: {
    gradeTypes: IGradeTypesResponse[]
    types: Array<{
      key: string
      name: any
    }>
    taughtDisciplines: IDisciplineResponse[]
  }
  selectMode?: boolean
  isFilterSelected?: boolean
  setIsFilterSelected?: () => void
  page: 'class-plan' | 'library'
}

export const LibrarySearchResources = (props: IProps) => {
  // props
  const {
    searchQuery,
    pageSize,
    filters,
    isFilterSelected,
    setIsFilterSelected,
    selectMode = false,
    page
  } = props
  // store
  const { profile, subscription } = useStore()
  // atoms
  const [selectedResources] = useAtom(selectedResourcesAtom)
  const [tabIndex] = useAtom(selectedLibraryTabAtom)
  // states
  const [state, setState] = useState<'loading' | 'success' | 'error' | 'notFound'>('loading')
  const [searchResponse, setSearchResponse] = useState<ISearchResponse<IResourceResponse>>()
  const [searchRequest, setSearchRequest] = useAtom(updateSearchRequestAtom)
  // translation
  const { t } = useTranslation()
  // mediaQuery
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // styles
  const classes = useStyles({ isMobile })

  const dataTestIds = {
    // page
    'library': {
      [TAB_INDEX_ENUM.RECURSOS]: {
        madeByCloe: 'cloe_super_library_filter_cloe_content',
        printable: 'cloe_super_library_filter_print',
        curricularComponents: 'cloe_super_library_resource_select_curricular_component',
        segments: 'cloe_super_library_resource_select_year_grade',
        resourceType: 'cloe_super_library_resource_select_resource_type',
      },
      [TAB_INDEX_ENUM.CAPITULOS]: {
        madeByCloe: 'cloe_super_library_chapter_filter_cloe_content',
        printable: 'cloe_super_library_chapter_filter_cloe_content',
        curricularComponents: 'cloe_super_library_chapter_select_curricular_component',
        segments: 'cloe_super_library_chapter_select_year_grade',
        resourceType: 'cloe_super_library_chapter_filter_cloe_content',
      },
      [TAB_INDEX_ENUM.COLECOES]: {
        madeByCloe: 'cloe_super_library_collection_filter_cloe_content',
        printable: 'cloe_super_library_collection_filter_cloe_content',
        curricularComponents: 'cloe_super_library_collection_select_curricular_component',
        segments: 'cloe_super_library_collection_select_segment',
        resourceType: 'cloe_super_library_collection_filter_cloe_content',
      },
    },
    'class-plan': {
      [TAB_INDEX_ENUM.RECURSOS]: {
        madeByCloe: 'cloe_super_create_lesson_plan_step3_resource_filter_cloe_content',
        printable: 'cloe_super_create_lesson_plan_step3_resource_filter_print',
        curricularComponents: 'cloe_super_create_lesson_plan_step3_resource_curricular_component',
        segments: 'cloe_super_create_lesson_plan_step3_resource_year_grade',
        resourceType: 'cloe_super_create_lesson_plan_step3_resource_resource_type',
      },
      [TAB_INDEX_ENUM.CAPITULOS]: {
        madeByCloe: 'cloe_super_create_lesson_plan_step3_chapter_filter_cloe_content',
        printable: 'cloe_super_create_lesson_plan_step3_chapter_filter_print',
        curricularComponents: 'cloe_super_create_lesson_plan_step3_chapter_curricular_component',
        segments: 'cloe_super_create_lesson_plan_step3_chapter_year_grade',
        resourceType: 'cloe_super_create_lesson_plan_step3_chapter_resource_type',
      },
      [TAB_INDEX_ENUM.COLECOES]: {
        madeByCloe: 'cloe_super_create_lesson_plan_step3_collection_filter_cloe_content',
        printable: 'cloe_super_create_lesson_plan_step3_collection_filter_print',
        curricularComponents: 'cloe_super_create_lesson_plan_step3_collection_curricular_component',
        segments: 'cloe_super_create_lesson_plan_step3_collection_year_grade',
        resourceType: 'cloe_super_create_lesson_plan_step3_collection_resource_type',
      },
    }
  }

  const dataTest = dataTestIds[page][tabIndex]

  const fetchSearch = async () => {
    const request = { ...searchRequest }
    if (!searchRequest.disciplines.length) {
      request.disciplines = filters.taughtDisciplines.map(t => t.id) ?? []
    }
    if (!searchRequest.gradeTypes.length) {
      request.gradeTypes = filters.gradeTypes.map(g => g.id) ?? []
    }
    const response = await searchResourceLibrary(request)
    let eventId = 'cloe_super_library_search_success'
    if (response.success) {
      setSearchResponse(response.data)
      if (response.data?.results?.length) {
        setState('success')
      } else {
        setState('notFound')
        eventId = 'cloe_super_library_search_not_found'
      }
    } else {
      setState('error')
      eventId = 'cloe_super_library_search_error'
    }

    Analytics.recordEventClick({
      name: eventId,
      attributes: {
        ...profile?.analytics,
        ...subscription?.analytics,
        request: { ...searchRequest }
      }
    })
  }

  useEffect(() => {
    const hasFilterDisciplines = !!filters.taughtDisciplines?.length
    const hasFilterGradeTypes = !!filters.gradeTypes?.length
    if (searchRequest.term && hasFilterDisciplines && hasFilterGradeTypes) {
      setState('loading')
      fetchSearch()
    }
  }, [searchRequest, filters])

  useEffect(() => {
    setSearchRequest({
      type: 'UPDATE',
      payload: {
        ...searchRequest,
        term: searchQuery,
        pageSize,
        pageNumber: 1
      }
    })
  }, [searchQuery])

  const onCheckFilter = (filter: 'disciplines' | 'gradeTypes' | 'types', value: number | string) => {
    const checked = !!searchRequest[filter]?.find(rd => rd === value)
    const newFilter = checked
      ? searchRequest[filter]?.filter(v => v !== value)
      : searchRequest[filter]?.concat(value)

    if (newFilter) {
      setSearchRequest({
        type: 'UPDATE',
        payload: {
          ...searchRequest,
          pageNumber: 1,
          [filter]: [...newFilter]
        }
      })
    }
  }

  const isExcedingSelects = selectedResources.length > 3

  const render = {
    loading: <LibrarySearchSkeleton mockCount={pageSize} />,
    success: <>
      {
        selectMode
          ? (<Grid>
            <Text2 fontSize='xs' fontWeight='medium'
              {...(isExcedingSelects ? { negative: 'feedbackMedium' } : { neutral: 'dark10' })}
            >
              {`${selectedResources.length}/3 `}{t('Recursos selecionados')}
            </Text2>
          </Grid>)
          : <></>
      }
      <Grid item xs={12} display='flex' mt={2} gap={2} flexWrap='wrap' justifyContent={isMobile ? 'center' : 'left'}>
        {searchResponse?.results?.map((resource, index) => {
          const isSelected = selectedResources.map(el => el.resourceId).includes(resource.resourceId)
          return <LibraryCard
            page={page}
            key={index}
            resource={resource}
            selectMode={selectMode}
            isSelected={isSelected}
            searchTerm={searchQuery}
            selectedFor='share'
          />
        })
        }
      </Grid>
      <Grid item xs={12} mt={2} display='flex' justifyContent='center'>
        <Pagination
          count={searchResponse?.totalPages}
          defaultPage={searchRequest.pageNumber}
          boundaryCount={1}
          siblingCount={1}
          variant='outlined'
          onChange={(e, value) => setSearchRequest({
            type: 'UPDATE',
            payload: {
              ...searchRequest,
              pageNumber: value
            }
          })}
        />
      </Grid>
    </>,
    error: <LibrarySearchError />,
    notFound: <LibrarySearchEmpty />
  }

  return (
    <Grid container spacing={3} alignItems='flex-start'>
      {/* Filter */}
      <Grid container item xs={3} gap={3} className={`${classes.libraryFilterContainer} ${isFilterSelected && 'selected'}`}>
        <RenderWhenCondition condition={isMobile}>
          <Grid item xs={12} display='flex' justifyContent='space-between'>
            <Text size='small' type='heading' color='medium'>
              {t('Filtrar recursos')}
            </Text>
            <div onClick={setIsFilterSelected}>
              <Close />
            </div>
          </Grid>
        </RenderWhenCondition>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Switch
              id='filter-content-by-cloe'
              label={t('Feito pela Cloe')}
              onChange={(e, checked) => setSearchRequest({
                type: 'UPDATE',
                payload: {
                  ...searchRequest,
                  madeByCloe: checked,
                  pageNumber: 1
                }
              })}
              checked={searchRequest.madeByCloe}
              dataTestId={dataTest.madeByCloe}
            />
          </Grid>
          <Grid item xs={12}>
            <Switch
              id='filter-content-printable'
              label={t('Para impressão')}
              onChange={(e, checked) => setSearchRequest({
                type: 'UPDATE',
                payload: {
                  ...searchRequest,
                  printable: checked,
                  pageNumber: 1
                }
              })}
              checked={searchRequest.printable}
              dataTestId={dataTest.printable}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SimpleCollapse title={t('Componente curricular')} isInitialExpanded>
            {filters.taughtDisciplines.map((d, i) => (
              <CheckBox
                key={i}
                checked={!!searchRequest.disciplines.find(rd => rd === d.id)}
                onClick={() => onCheckFilter('disciplines', d.id)}
                value={d.id}
                size='small'
                dataTestid={dataTest.curricularComponents}
              >
                {d.name}
              </CheckBox>
            ))}
          </SimpleCollapse>
        </Grid>
        <Grid item xs={12}>
          <SimpleCollapse title={t('Segmento')} isInitialExpanded>
            {filters.gradeTypes.map((sg, i) => (
              <CheckBox
                key={i}
                checked={!!searchRequest.gradeTypes?.find(gt => gt === sg.id)}
                onClick={() => onCheckFilter('gradeTypes', Number(sg.id))}
                value={sg.id}
                size='small'
                dataTestid={dataTest.segments}
              >
                {sg.name}
              </CheckBox>
            ))}
          </SimpleCollapse>
        </Grid>
        <Grid item xs={12}>
          <SimpleCollapse title={t('Tipo de recurso')} isInitialExpanded>
            {filters.types.map((recType, i) => (
              <CheckBox
                key={i}
                checked={!!searchRequest.types?.find(rd => rd === recType.key)}
                onClick={() => onCheckFilter('types', recType.key)}
                size='small'
                dataTestid={dataTest.resourceType}
              >
                {t(recType.name)}
              </CheckBox>
            ))}
          </SimpleCollapse>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={9} gap={2}>
        <LibrarySearchFilters
          filters={filters}
          request={searchRequest}
          page='resources'
          onClear={onCheckFilter}
          onClearAll={() => setSearchRequest({
            type: 'UPDATE',
            payload: {
              ...searchRequest,
              pageNumber: 1,
              gradeTypes: [],
              disciplines: [],
              types: []
            }
          })}
        />
        {render[state]}
      </Grid>
    </Grid>
  )
}
