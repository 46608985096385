import { IRevisionAllAnswerRequest, IAnswerRecordRequestCacheQueryParams } from 'services/types'

export const ANSWER = (id?: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/answers${!id ? '' : `/${id}`}`

export const USER_ANSWER = (profileId: string | number, activityId: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/answers?user_profile.id=${profileId}&activity.id=${activityId}`

export const USER_QUESTION_ANSWER = (userId: string | number, questionId: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/answers?user_profile.user_id=${userId}&question_id=${questionId}`

export const ANSWER_RECORD = () =>
  `${process.env.REACT_APP_BACKEND_URL as string}/answers-records`

export const ANSWER_RECORD_CACHE = ({ isEditing }: IAnswerRecordRequestCacheQueryParams) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/answers-records/cache?isEditing=${isEditing}`

export const RECORD = (sourceId?: string | number, sourceTypeId?: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/records${!sourceId ? '' : `/${sourceId}`}`

export const TEXT_RECORD = (id?: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/text-records${!id ? '' : `/${id}`}`

export const CHOICE_RECORD = (id?: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/choice-records${!id ? '' : `/${id}`}`

export const FILE_RECORD = (id?: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/file-records${!id ? '' : `/${id}`}`

export const GET_FILE_RECORD_BY_UPLOAD_ID = (uploadId: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/file-records?upload_id=${uploadId}`

export const GET_EVALUATIONS_ANSWERED = (id?: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/answers${!id ? '' : `/${id}`}`

export const GET_STUDENTS_ANSWER_BY_QUESTION = (classId: string | number, activityId: string | number, questionId: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/${classId}/activities/${activityId}/question/${questionId}/answers`

export const GET_STUDENTS_ANSWER_BY_ACTIVITY = (classId: string | number, activityId: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/${classId}/activities/${activityId}/answers`

export const REVISION_ALL_ANSWER = (data: IRevisionAllAnswerRequest) => `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/${data.classId}/activities/${data.activityId}/revise-all`
