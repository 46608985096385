import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modalContainer: {
    '& .MuiDialog-paper': {
      minHeight: '350px !important'
    },
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    }
  }
}))
