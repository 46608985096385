import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode
  onError?: (error: Error) => void
  fallback?: ReactNode
}

interface State {
  hasError: boolean
}

export class EditorErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Editor error:', error, errorInfo);
    this.props.onError?.(error);
  }

  public render() {
    if (this.state.hasError) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return this.props.fallback || (
        <div className='editor-error-fallback'>
          Algo deu errado com o editor. Por favor, tente atualizar a página.
        </div>
      );
    }

    return this.props.children;
  }
}
