import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiSwitch-root': {
      '& .Mui-checked': {
        color: `${theme.colorBrand.medium} !important`,
        '& + .MuiSwitch-track': {
          backgroundColor: `${theme.colorBrand.light} !important`
        }
      }
    },
    '& .MuiTypography-root': {
      fontFamily: theme.font.fontFamily,
      color: `${theme.colors.neutral.dark10} !important`,
      fontSize: `${theme.font.fontSize.xs} !important`,
      fontWeight: `${theme.font.fontWeight.medium} !important`,
      lineHeight: '24px !important',
      letterSpacing: '0.14px !important'
    }
  }
}))
