export enum DisciplineCodeEnum {
  MAT = 'MAT',
  CIE = 'CIE',
  EI = 'EI',
  ING = 'ING',
  IT = 'IT',
  BIO = 'BIO',
  BI = 'BI',
  BI_V2 = 'BI_V2',
  IT_CUF = 'IT_CUF',
  SOCIO = 'SOCIO',
  QUI = 'QUI',
  G21 = 'G21',
  LP = 'LP',
  FILO = 'FILO',
  GEO = 'GEO',
  HIST = 'HIST',
  FIS = 'FIS',
  ART = 'ART',
  NA_SC = 'NA_SC',
  SO_ST = 'SO_ST',
  LANG = 'LANG',
  MATH = 'MATH',
  ALL = 'ALL',
  DEFAULT = 'DEFAULT',
}

export type DisciplineCodeEnumKeys = keyof typeof DisciplineCodeEnum

export interface IDisciplineResponse {
  id: number
  name: string
  locale?: string
  created_at: string
  updated_at: string
  updated_by: number
  code: DisciplineCodeEnum
  content_units: number[]
  type: string
  legacy_id: number
  taught_discipline: boolean
  title?: string
}

export interface IDisciplineStore {
  id: string | number
  name: string
  code: DisciplineCodeEnumKeys
  content_units?: number[]
}

export enum DisciplineTypeEnum {
  DISCIPLINE = 'discipline',
  ITINERARY = 'itinerary'
}
