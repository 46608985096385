import MuiSwitch, { SwitchProps } from '@mui/material/Switch'
import useStyles from './style'
import MUIFormControlLabel from '@mui/material/FormControlLabel'

interface ISwitchProps extends SwitchProps {
  label?: string
  dataTestId?: string
}
export const Switch: React.FC<ISwitchProps> = ({ ...props }) => {
  const classes = useStyles()

  return (
    props.label
      ? <MUIFormControlLabel
        className={`${classes.root} ${props.className as string}`}
        control={
          <MuiSwitch
            {...props}
            data-testid={props.dataTestId}
          />
        }
        label={props.label}
      />
      : <MuiSwitch
        {...props}
        className={`${classes.root} ${props.className as string}`}
        data-testid={props.dataTestId}
      />
  )
}
