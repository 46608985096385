import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ICollectionResponse } from 'services/types/library'
import { getCollectionById, getCollectionCloeById } from 'services/library'
import { useParams } from 'react-router-dom'
import { CollectionsTeacherLoading } from './loading'
import { CollectionsTeacherDetails } from './details'
import { BusinessError } from 'navigation/BusinessError'
import { useQuery } from 'utils/query'

export const CollectionsTeacher: React.FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()
  const madeByCloe = useQuery().get('madeByCloe')

  const [state, setState] = useState<'loading' | 'success' | 'error'>('loading')
  const [collection, setCollection] = useState<ICollectionResponse>()

  useEffect(() => {
    const fetchData = async () => {
      setState('loading')
      const response = madeByCloe === 'true' ? await getCollectionCloeById(id) : await getCollectionById(id)
      if (response.success) {
        setCollection(response.data)
        setState('success')
      } else {
        setState('error')
      }
    }
    fetchData()
  }, [])

  const render = {
    loading: <CollectionsTeacherLoading />,
    success: collection ? <CollectionsTeacherDetails madeByCloe={madeByCloe === 'true'} {...collection} /> : <></>,
    error: <BusinessError error={t('Erro ao buscar detalhes da coleção')} />
  }

  return render[state]
}
