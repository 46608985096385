import { Text } from 'components/design-system'
import LibraryCard from 'components/common/LibraryCard'
import { ReactElement } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IResourceResponse, IArticleResponse } from 'services/types/library'

const useStyle = makeStyles((theme: Theme) => createStyles({
  resourcesSelectedContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacingInline.nano,
    paddingBlock: theme.spacingInline.xxxs
  },
  emptyStateMessage: {
    textAlign: 'center',
    paddingInline: theme.spacingInline.xxs,
    '& svg': {
      fontSize: '16px !important',
      marginBottom: '-3px !important'
    }
  }
}))

interface IProps {
  emptyStateMessage: string
  icon: ReactElement
  selectedResources: IResourceResponse[]
  selectedArticles: IArticleResponse[]
  selectedFor: 'share' | 'save'
}

export const LibraryContentSelected: React.FC<IProps> = (props) => {
  const { emptyStateMessage, icon, selectedResources, selectedArticles, selectedFor } = props
  const classes = useStyle()

  const state = !selectedResources.length && !selectedArticles.length ? 'empty' : 'list'

  const EmptyState = () => (
    <div className={classes.emptyStateMessage}>
      <Text type='body' size='medium' color='medium'>
        {emptyStateMessage} ({icon})
      </Text>
    </div>
  )

  const ResourcesList = () => (
    <>
      {
        selectedResources.map((r, i) =>
          <LibraryCard
            key={i}
            resource={r}
            isSelected
            selectedFor={selectedFor}
            page='library'
          />
        )
      }
      {
        selectedArticles.map((a, i) =>
          <LibraryCard
            key={i}
            article={a}
            isSelected
            selectedFor={selectedFor}
            page='library'
          />
        )
      }
    </>
  )

  const render = {
    empty: <EmptyState />,
    list: <ResourcesList />
  }

  return (
    <div className={classes.resourcesSelectedContainer}>
      {render[state]}
    </div>
  )
}
