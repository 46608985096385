import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import useStyle from '../styles'
import { ReactComponent as Avateacher } from 'assets/avatar-with-crossed-arms.svg'
import { Text } from 'components/design-system'
import { useTranslation } from 'react-i18next'

interface IProps {
  mockCount: number
}

export const LibrarySearchSkeleton = (props: IProps) => {
  // mediaQuery
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyle()

  const { mockCount } = props

  const mocks = []
  for (let i = 0; i < mockCount; i++) {
    mocks.push(i)
  }
  return (
    <Grid item xs={12} display='flex' mt={2} gap={2} flexWrap='wrap' justifyContent={isMobile ? 'center' : 'left'}>
      {mocks.map(i =>
        <div key={i} style={{ width: 248, height: 248 }}>
          <Skeleton
            className={classes.skelletonRounded}
            variant='rectangular'
            sx={{ width: 248, height: 248 }}
            animation='wave'
          />
        </div>)
      }
    </Grid>
  )
}

export const LibrarySearchError = () => {
  return (
    <Grid>
      Houve um erro ao buscar
    </Grid>
  )
}

export const LibrarySearchEmpty = () => {
  const { t } = useTranslation()
  return (
    <Grid container display='flex' gap={3} textAlign='center' mt={4}>
      <Grid item xs={12}>
        <Avateacher />
      </Grid>
      <Grid item xs={12}>
        <Text type='body' size='large' color='medium'>{t('Esta busca não trouxe resultados. Vamos tentar de novo?')}</Text>
      </Grid>
      <Grid item xs={12}>
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <Text type='body' size='medium'>
            {t('Revise a palavra digitada, remova filtros aplicados ou busque por outros termos parecidos.')}
          </Text>
        </div>
      </Grid>
    </Grid>
  )
}
