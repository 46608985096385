import { Avatar, Tag, Text } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { getFileExtension } from 'utils/files'

import useStyles from './style'

interface IPersonalFileCardProps {
  file: File
}

export const PersonalFileCard = ({ file }: IPersonalFileCardProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const fileExtension = getFileExtension(file.name)
  const fileName = file.name.replace(`.${fileExtension}`, '')

  return (
    <div className={classes.cardLink}>
      <div className={classes.cardContainer}>
        <div className={classes.headerContainer}>
          <Avatar userName={fileName} sx={{ width: '72px !important', height: '72px !important' }} />
        </div>
        <div className={classes.title}>
          <span>{fileName}</span>
        </div>
        <div className={classes.subTitle}>
          <Text size='small' type='body'>
            {t('Arquivo {{fileType}}', { fileType: fileExtension })}
          </Text>
        </div>
        <div className={classes.cardFooter}>
          <Tag className={classes.tag}>
            {t('Material autoral')}
          </Tag>
        </div>
      </div>
    </div>
  )
}
