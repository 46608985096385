/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import { useAtom } from 'jotai'

import { IStudentsAnswerByQuestionResponse } from 'services/types'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import CardStudentAnswerButtonContentActivity from './components/Activity'
import CardStudentAnswerButtonContentEvaluation from './components/Evaluation'

interface ICardStudentAnswerButtonContentProps {
  studentAnswer?: IStudentsAnswerByQuestionResponse
}

export const CardStudentAnswerButtonContent: React.FC<ICardStudentAnswerButtonContentProps> = ({ studentAnswer }) => {
  // atom
  const [activity] = useAtom(activityCurrentAtom)

  return activity?.suggested_application_type === 'activity'
    ? <CardStudentAnswerButtonContentActivity studentAnswer={studentAnswer} />
    : <CardStudentAnswerButtonContentEvaluation studentAnswer={studentAnswer} />
}

export default CardStudentAnswerButtonContent
