import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import cardBg from './assets/card_bg.svg'

export default makeStyles((theme: Theme) => createStyles({
  cardLink: {
    textDecoration: 'none'
  },
  cardContainer: {
    backgroundImage: `url(${cardBg})`,
    backgroundSize: '100%',

    width: '272px',
    minWidth: '272px',
    height: '272px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: theme.borderRadius.md,
    border: `1px solid ${theme.colors.neutral.light20}`,
    background: theme.colors.neutral.lightBase,
    padding: theme.spacingInset.xs,
    gap: theme.spacingStack.nano,
    textDecoration: 'none !important',
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      background: theme.colorBrand.lightest,
      borderColor: theme.colorBrand.lightest,
      boxShadow: '0px 8px 24px 0px rgba(20, 20, 20, 0.16)',
      '& span': {
        color: `${theme.colorBrand.dark} !important`
      }
    }
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerOption: {
    display: 'flex',
    gap: 8,
    '& svg': {
      fontSize: '16px !important',
      cursor: 'pointer',
      color: theme.colorBrand.medium
    }
  },
  headerRemove: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    cursor: 'pointer',

    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xxs,
    letterSpacing: ' 0.12px',
    color: theme.colorBrand.medium,

    '& svg': {
      fontSize: '12px !important',
      color: theme.colors.neutral.darkBase
    },

    '&:hover': {
      color: theme.colorBrand.darkest
    }
  },
  title: {
    marginTop: '8px',
    maxWidth: '100%',
    height: '65px',

    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',

    '& span': {
      color: theme.colors.neutral.dark10,
      textTransform: 'uppercase',
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.bold,
      lineHeight: '24px',
      letterSpacing: '0.01em'
    }
  },
  subTitle: {
    maxWidth: '100%',
    height: '60px',

    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    '& span': {
      color: theme.colors.neutral.dark10
    }
  },
  cardFooter: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  tag: {
    background: theme.colors.support.colorSupport02,
    color: theme.colors.neutral.lightBase
  },
  volumeUpContainer: {
    width: '40px',
    height: '40px',
    borderRadius: theme.borderRadius.circular,
    background: theme.colorBrand.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
