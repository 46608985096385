import { Button, Text } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { LibraryContentSelected } from '../common'
import useStyles from '../style'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { selectedArticlesToSaveAtom, selectedResourcesToSaveAtom, updateScheduleRequestAtom } from './atomStore'
import { BookmarkBorderOutlined } from '@mui/icons-material'
import { Grid, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import { CollectionSelectModal } from '../common/CollectionSelectModal'
import { useState } from 'react'

export const LibraryContentSave: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  // states
  const [showModal, setShowModal] = useState(false)
  // atoms
  const selectedArticles = useAtomValue(selectedArticlesToSaveAtom)
  const selectedResources = useAtomValue(selectedResourcesToSaveAtom)
  const updateRequest = useUpdateAtom(updateScheduleRequestAtom)

  return (
    <div className={classes.resourceShareContainer}>
      <Text type='heading' size='small' color='darkest'>
        {t('Salvar')}
      </Text>
      <LibraryContentSelected
        selectedArticles={selectedArticles}
        selectedResources={selectedResources}
        emptyStateMessage={t('Use a barra de pesquisa para encontrar materiais e selecione-os clicando no botão Salvar')}
        icon={<BookmarkBorderOutlined />}
        selectedFor='save'
      />
      <Divider />
      <Grid
        container
        spacing={2}
        p={2}
      >
        <Grid item xs={6} justifySelf='flex-start'>
          <Button
            variant='outlined'
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.medium} !important`,
              width: '100%'
            }}
            onClick={() => updateRequest({ type: 'RESET' })}
            data-testid='my_cloe_save_select_cancel_save'
          >
            {t('Cancelar')}
          </Button>
        </Grid>
        <Grid item xs={6} justifySelf='flex-end'>
          <Button
            sx={{
              padding: '8px 12px !important',
              gap: '8px',
              fontWeight: `${theme.font.fontWeight.medium} !important`,
              width: '100%'
            }}
            onClick={() => setShowModal(true)}
            disabled={!selectedArticles.length && !selectedResources.length}
            data-testid='my_cloe_save_select_save'
          >
            {t('Salvar...')}
          </Button>
        </Grid>
      </Grid>
      {
        showModal &&
        <CollectionSelectModal
          onClose={() => setShowModal(!showModal)}
        />
      }
    </div>
  )
}
