import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  exploreCardsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: theme.spacingInline.xxs,
    flexWrap: 'wrap',
    '& span': {
      cursor: 'pointer !important'
    }
  },
  homeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: theme.spacingStack.xxxs,
    padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.xxs} `,
    borderRadius: theme.borderRadius.md,
    background: '#E9E5F1'
  }
}))
