import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  resourceScheduleContainer: {
    minHeight: '100%',
    background: '#F5F3F8',
    borderRadius: '16px 0 0 0',
    position: 'relative',
    '&.hideParentBorderRadius': {
      borderRadius: '0'
    },

    [theme.breakpoints.down('sm')]: {
      borderRadius: '0'
    }
  },
  tabContainer: {
    position: 'absolute',
    top: '7px',
    background: 'none !important',
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },
  tab: {
    fontSize: `${theme.font.fontSize.sm} !important`,
    fontWeight: `${theme.font.fontWeight.semibold} !important`,
    lineHeight: `${theme.font.lineHeight.xxs} !important`,
    letterSpacing: '0.16px !important',
    textTransform: 'capitalize !important' as any,
    padding: theme.spacingInline.xxxs,

    background: `${theme.colorBrand.medium} !important`,
    color: `${theme.colors.neutral.lightBase} !important`,
    '&.isSelected': {
      background: '#F5F3F8 !important',
      color: `${theme.colorBrand.medium} !important`,
      borderRadius: '16px 16px 0 0 !important',
      borderBottom: '0 !important'
    }
  },
  tabPanelContainer: {
    padding: '14px'
  },
  resourceShareContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInline.xxxs
  }
}))
