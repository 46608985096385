import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { addTransparencyToColor } from 'utils/colors'
import wormLeft from './assets/worm-left.svg'
import wormRight from './assets/worm-right.svg'
import wormCentral from './assets/worm-central.svg'

export default makeStyles((theme: Theme) => createStyles({
  collectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    width: '100%',
    height: 'auto',
    minHeight: '100%',

    gap: theme.spacingStack.xxs,

    overflowX: 'hidden',

    paddingTop: theme.spacingInset.sm,
    padding: theme.spacingInset.lg,
    paddingBottom: theme.spacingStack.lg,

    [theme.breakpoints.down('sm')]: {
      marginTop: '50px'
    }
  },
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    background: addTransparencyToColor(theme.colorBrand.lightest, 0.5),
    alignItems: 'center',

    gap: theme.spacingInline.xxs,

    width: '100%',
    height: '192px',
    borderRadius: theme.borderRadius.md,
    padding: `${theme.spacingInset.md} ${theme.spacingInset.sm}`,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      backgroundImage: `url(${wormCentral})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      gap: theme.spacingInline.xxxs,
      padding: theme.spacingInset.xs
    }
  },
  headerThumbs: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '130px'
    }
  },
  headerBackgroundLeft: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: '50%',
    backgroundImage: `url(${wormLeft})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    borderRadius: theme.borderRadius.md,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headerBackgroundRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    left: '50%',
    backgroundImage: `url(${wormRight})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.nano
  },
  headerInfoTitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  skelletonRounded: {
    borderRadius: `${theme.borderRadius.md}!important`
  },
  cardImage: {
    minWidth: '246px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    '& > div': {
      position: 'absolute'
    },
    '& > div:first-child': {
      left: '0px',
      zIndex: 3
    },
    '& > div:nth-child(2)': {
      left: '60px',
      zIndex: 2
    },
    '& > div:nth-child(3)': {
      left: '120px',
      zIndex: 1
    }
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacingStack.xxxs,
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacingInset.sm} !important`,
      justifyContent: 'center'
    }
  },
  descriptionTitle: {
    '& svg': {
      display: 'none !important',

      [theme.breakpoints.down('sm')]: {
        display: 'inline-flex !important'
      }
    }
  },
  descriptionMobile: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'max-height 0.2s ease-in-out',
      '&.open': {
        maxHeight: '10000px'
      }
    }
  }
}))
