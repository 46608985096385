import { Grid, useMediaQuery, useTheme } from '@mui/material'

import { Card } from './components'

import useStyles, { IStyleProps } from './style'
import { subMenuTabsAtom, currentSubMenuTabAtom, subMenuTabsLoadingAtom } from '../atomStore'
import { useAtomValue } from 'jotai/utils'
import { useAtom } from 'jotai'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useEffect, useRef, useState } from 'react'
import { activityIndexSelectedAtom, allActivities } from 'pages/ContentUnit/atomStore'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { CONTENT_UNIT_STUDENT_CONTENT, CONTENT_UNIT_STUDENT_EXPEDITION, CONTENT_UNIT_TEACHER_CONTENT } from 'navigation/CONSTANTS'
import { ActivityControlStatusEnum } from 'services/types'
import LockOutlined from '@mui/icons-material/LockOutlined'
import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { useToggleSideMenuAtom } from 'pages/ContentUnit/components/atomStore'

export const ContentUnitSubmenu = () => {
  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabsLoading] = useAtom(subMenuTabsLoadingAtom)
  const subMenuTabs = useAtomValue(subMenuTabsAtom)

  const history = useHistory()
  const { contentId } = useParams<{ contentId?: string }>()
  const [activityIndexSelected, setActivityIndexSelected] = useAtom(activityIndexSelectedAtom)
  const [activities] = useAtom(allActivities)
  const { toggleSideMenuAtom } = useToggleSideMenuAtom()
  const [, setIsOpenSideMenu] = useAtom(toggleSideMenuAtom)

  const [isScrollable, setIsScrollable] = useState(false)

  const scrollContainerRef = useRef<HTMLDivElement | null>()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isContentViewTeacher = useRouteMatch({
    path: CONTENT_UNIT_TEACHER_CONTENT(':gradeTypeCode', ':gradeCode'),
    exact: true
  })
  const isContentViewStudent = useRouteMatch({
    path: CONTENT_UNIT_STUDENT_CONTENT(),
    exact: true
  })
  const stylesProps: IStyleProps = {
    hasNavigationTabs: !!isContentViewTeacher || !!isContentViewStudent
  }

  const classes = useStyles(stylesProps)

  const scrollToSelected = (element: HTMLDivElement | null, index: number | null = null) => {
    setTimeout(() => {
      const selectedElement = index !== null ? document.getElementById(`card-${index}`) : document.getElementById('card-selected')
      if (element && selectedElement) {
        const scrollContainerRect = element.getBoundingClientRect()
        const selectedElementRect = selectedElement.getBoundingClientRect()
        const offset = selectedElementRect.top - scrollContainerRect.top
        element.scrollTo({
          top: element.scrollTop + offset,
          behavior: 'smooth'
        })
      }
    }, 100)
  }

  const handleScrollToElement = (direction: 'prev' | 'next') => {
    const container = scrollContainerRef.current
    if (container) {
      const cardHeight = (container.firstElementChild?.firstElementChild?.clientHeight ?? 0) * 2
      const offset = direction === 'prev' ? -cardHeight : cardHeight
      container.scrollBy({
        top: offset,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    scrollToSelected(scrollContainerRef.current as HTMLDivElement)
  }, [currentSubMenuTab, activityIndexSelected])

  useEffect(() => {
    checkScrollable()
    setSubMenuTabsLoading(false)
  }, [activities, subMenuTabs])

  useEffect(() => {
    const handleResize = () => {
      checkScrollable()
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const checkScrollable = () => {
    const container = scrollContainerRef.current
    if (container) {
      setIsScrollable(container.scrollHeight > container.clientHeight)
    }
  }

  const handleSetActivityIndexSelected = (index: number) => {
    setActivityIndexSelected(index)
    if (activities) {
      history.push(
        CONTENT_UNIT_STUDENT_EXPEDITION(
          contentId,
          activities[index].id?.toString()
        )
      )
    }
  }

  const SectionTabs = () => (<>{
    subMenuTabs?.map(({ onClick, ...tabProps }, index) => (
      <Card
        {...tabProps}
        key={`Card-${index}`}
        isActive={index === currentSubMenuTab}
        onClick={() => {
          setCurrentSubMenuTab(index)
          if (isMobile) setIsOpenSideMenu(false)
        }}
        dataTestid={tabProps.dataTestid}
        id={`card-${index}`}
      />
    ))
  }</>)

  const ActivityTabs = () => (<>{
    activities?.map((activity, index) => (
      <Card
        key={activity.id}
        iconType={activity.suggested_application_type}
        idActivity={activity.id}
        title={activity.title ?? activity.activity_name ?? ''}
        dataTestid='content_sections_select_item'
        onClick={() => {
          if (isMobile) setIsOpenSideMenu(false)
        }}
        endAdornment={
          activity.activity_status?.status === ActivityControlStatusEnum.AVAILABLE
            ? (
              <LockOutlined />)
            : (
              <LockOpenOutlined />
            )
        }
        id={`card-${index}`}
      />
    ))
  }</>)

  const paginationProps = {
    indexSelected: subMenuTabs ? currentSubMenuTab : activityIndexSelected,
    maxLength: subMenuTabs ? subMenuTabs?.length : activities?.length ?? 0,
    setIndexSelected: subMenuTabs ? setCurrentSubMenuTab : handleSetActivityIndexSelected,
    dataTestid: {
      before: 'content_sections_paginate',
      next: 'content_sections_paginate'
    }
  }

  const TopNavigationButton = () => {
    if (!isScrollable) return <></>
    return (
      <button
        className={classes.paginationButton}
        onClick={() => handleScrollToElement('prev')}
        data-testid={paginationProps.dataTestid?.before}
      >
        <ExpandLessOutlinedIcon />
      </button>
    )
  }

  const BottomNavigationButton = () => {
    if (!isScrollable) return <></>
    return (
      <button
        className={classes.paginationButton}
        onClick={() => handleScrollToElement('next')}
        data-testid={paginationProps.dataTestid?.next}
      >
        <ExpandMoreOutlinedIcon />
      </button>
    )
  }

  return (
    <Grid container className={classes.selectorWrapper}>
      <TopNavigationButton />
      <ScrollContainer
        style={{ scrollBehavior: 'smooth', maxHeight: '100%', overflowY: 'auto' }}
        innerRef={el => {
          scrollToSelected(el as HTMLDivElement)
          scrollContainerRef.current = el as HTMLDivElement
        }}
        className='scroll-container'
      >
        <div className={classes.cards}>
          {
            subMenuTabs
              ? <SectionTabs />
              : <ActivityTabs />
          }
        </div>
      </ScrollContainer>
      <BottomNavigationButton />
    </Grid>
  )
}
