import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IContentResponse, IResourceResponse, ISectionResponse } from 'services/types'
import { Content, Resource, Text } from 'components/common'
import { ResourceGallery } from 'components/common/ResourceGallery'

const useStyles = makeStyles((theme: Theme) => createStyles({
  readingContainer: {
    gap: theme.spacingInline.xxs,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacingInline.xxs,
    paddingTop: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowWrap: 'anywhere',
      padding: 0
    }
  }
}))

interface IProps {
  section: ISectionResponse
}

export const DidacticContent: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { section } = props

  const handleResourceMedias = (resource: IResourceResponse) => {
    if (resource.medias?.length && resource.medias?.length > 1) {
      const formattedMedias = resource.medias.map(media => ({ media, source: resource.source, description: resource.description }))
      return <ResourceGallery medias={formattedMedias} source={resource.source} description={resource.description} />
    } else {
      return <Resource resource={resource} />
    }
  }

  console.log('DidacticContentSection', section)

  return (
    <div className={classes.readingContainer}>
      {section.text !== null && section.text && <Text content={section.text ?? ''} />}
      {
        section?.resources?.length
          ? section.resources.map(resource => handleResourceMedias(resource))
          : ''
      }
      {
        section.contents.map((content: IContentResponse) => {
          return <Content content={content} key={content.id} type={content.content_type} showGalleryFormat />
        })
      }
    </div>
  )
}
