import useStyle from './style'
import { Avatar, Text2 } from 'components/design-system'
import { Link } from 'react-router-dom'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { Skeleton } from '@mui/material'
import { CLASS_PLAN_ITEM } from 'navigation/CONSTANTS'

interface MyClassPlanCardProps {
  id: string
  title: string
  userName: string
  dataTestId?: string
}

export const MyClassPlanCard = (props: MyClassPlanCardProps) => {
  const classes = useStyle()

  return (
    <Link
      to={CLASS_PLAN_ITEM(props.id)}
      className={classes.cardContainer}
      data-testid={props?.dataTestId}
    >
      <span className={classes.iconContainer}>
        <AssignmentOutlinedIcon />
      </span>
      <Text2 fontSize='md' fontWeight='bold' colorbrand='medium' className={classes.text}>
        {props.title}
      </Text2>
      <div className={classes.footer}>
        <Avatar userName={props.userName} className={classes.customAvatar} />
      </div>
    </Link>
  )
}

export const MyClassPlanCardSkeleton = () => {
  const classes = useStyle()

  return (
    <div className={classes.cardContainer}>
      <span className={classes.iconContainer}>
        <Skeleton variant='circular' width={70} height={70} animation='wave' className={classes.customAvatar} />
      </span>
      <Skeleton variant='text' sx={{ width: '100%' }} height={45} animation='wave' className={classes.text} />
      <div className={classes.footer}>
        <Skeleton variant='circular' width={70} height={70} animation='wave' className={classes.customAvatar} />
      </div>
    </div>
  )
}
