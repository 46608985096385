import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  exploreCardsContainer: {
    display: 'flex',
    gap: theme.spacingInline.xxs,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center'
  },
  homeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: theme.spacingStack.xxxs
  },
  createClassPlanButton: {
    background: `${theme.colorBrand.medium} !important`,
    color: theme.colors.neutral.lightBase,
    padding: `${theme.spacingSquish.xs}`,
    height: '48px !important',
    border: 'none !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
