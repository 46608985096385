import { useEffect, useState } from 'react'

import { useRouter } from 'navigation/hooks/useRouter'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { ROOT } from 'navigation/CONSTANTS'
import { useStore } from 'store'

import BarInfoPreviousSchool from './BarInfoPreviousSchool'
import { MessageInfo } from './types'
import { useMediaQuery, useTheme } from '@mui/material'

const BarInfoPreviousSchoolContainer = () => {
  const theme = useTheme()
  const { location, history } = useRouter()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isContentUnitPage, setIsContentUnitPage] = useState(false)
  const [message, setMessage] = useState<MessageInfo>({ title: '', subTitle: '', description: '', showLink: false })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isGreaterThanXl = useMediaQuery('(min-width:1537px)')

  const { schoolPeriod, subscription, profile } = useStore()

  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const validateLocation = () => {
    const pathname = location?.pathname ?? ''
    setIsContentUnitPage(pathname.includes('content-unit'))

    // demais telas
    // setIsShowMarginBottom(true)
    // if (isExpanded) {
    //   if (isMobile) {
    //     setMarginBottom('36%')
    //   } else if (isTablet) {
    //     setMarginBottom('15%')
    //   } else {
    //     setMarginBottom('10%')
    //   }
    // } else {
    //   if (isMobile) {
    //     setMarginBottom('9%')
    //   } else {
    //     setMarginBottom('4%')
    //   }
    // }
  }

  const validateMessage = () => {
    if (profileType === IUserSchoolProfileTypeEnum.student) {
      setMessage({
        title: 'Turma anterior',
        subTitle: 'esse modo permite que você visualize seu histórico.',
        description: 'Apenas suas turmas atuais permitem edição.'
      })
      return
    }

    if (location?.pathname === '/' || location?.pathname === '/taught-disciplines') {
      setMessage({
        title: 'Ano escolar anterior',
        subTitle: 'esse modo permite que você visualize suas turmas antigas.',
        description: 'Apenas anos escolares atuais permitem edição.'
      })
      return
    }

    if (location?.pathname?.includes('class')) {
      setMessage({
        title: 'Turma anterior',
        subTitle: 'esse modo permite que você visualize o histórico das turmas.',
        description: 'Para editar suas turmas atuais, retorne à seção',
        showLink: true
      })
    }
  }

  useEffect(() => {
    if (schoolPeriod?.current) return

    if ([null, 'false', undefined].includes(localStorage.getItem('bar-info-previous-school-is-expanded-has-already-opened'))) {
      setIsExpanded(true)
      localStorage.setItem('bar-info-previous-school-is-expanded-has-already-opened', 'true')
    }
  }, [schoolPeriod])

  useEffect(() => {
    validateLocation()
    validateMessage()
  }, [location, isExpanded, isMobile, isTablet, isGreaterThanXl])

  const props = {
    message, isContentUnitPage, isExpanded, isMobile, history, rootRouter: ROOT, setIsExpanded
  }

  // Quando for liberar para o professor, será necessário remover esse código verificando o tipo de perfil somente para o estudante
  if (schoolPeriod?.current ?? profileType !== IUserSchoolProfileTypeEnum.student) {
    return <></>
  }

  return <BarInfoPreviousSchool {...props} />
}

export default BarInfoPreviousSchoolContainer
