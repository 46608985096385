
import { Tag, Text2 } from 'components/design-system'
import { IFormData } from '../..'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { FormInput } from 'components/common'
import { useEffect } from 'react'
import { useValidateFields } from '../../validators'

interface IDetailsPage {
  stepIndex: number
  formData: IFormData
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>
}

export interface IStrategy {
  id: number
  title: string
}

const AVAILABLE_STRATEGIES: IStrategy[] = [
  { id: 1, title: 'Aprendizagem por Pares' },
  { id: 2, title: 'Aprendizagem por Projeto' },
  { id: 3, title: 'Debate regrado' },
  { id: 4, title: 'Experiências' },
  { id: 5, title: 'Gamificação' },
  { id: 6, title: 'Mapa mental' },
  { id: 7, title: 'Pesquisa' },
  { id: 8, title: 'Seminário' }
]

export const DetailsPage = ({ stepIndex, formData, setFormData }: IDetailsPage) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isFieldHighlighted } = useValidateFields()

  useEffect(() => {
    handlePreSelectStrategy()
  }, [formData])

  const handlePreSelectStrategy = () => {
    const hasSelectedStrategy = formData.learningStrategies?.length
    if (hasSelectedStrategy) return

    setFormData(old => ({
      ...old,
      learningStrategies: [{ id: 1, title: 'Aprendizagem por Pares' }]
    }))

  }

  const handleUpdateField = (field: keyof IFormData, value: any) => {
    setFormData(old => ({
      ...old,
      [field]: value
    }))
  }

  const handleSelectStrategy = (strategy: IStrategy) => {
    const isIncluded = formData?.learningStrategies?.map(el => el.id).includes(strategy.id)

    // should remove
    if (isIncluded) {
      setFormData(old => ({
        ...old,
        learningStrategies: formData?.learningStrategies?.filter(el => el.id !== strategy.id)
      }))
      return
    }

    // setFormData(old => ({
    //   ...old,
    //   learningStrategies: [...(formData?.learningStrategies ?? []), strategy]
    // }))

    setFormData(old => ({
      ...old,
      learningStrategies: [strategy]
    }))
  }

  const isClassGoalsFieldHighlighted = isFieldHighlighted('classGoals')

  return (
    <div className={classes.container}>
      <Text2 fontSize='md' fontWeight='semibold' colorbrand='medium'>
        {t('Passo 2: Detalhamento')}
      </Text2>

      <div className={classes.fieldsContainer}>
        <div className={classes.fieldsContainerRow}>
          <FormInput
            type='text'
            title={t('Descreva em algumas palavras os objetivos de aprendizagem')}
            onChange={(event) => handleUpdateField('classGoals', event.target.value)}
            customClassName={classes.inputFields}
            text={formData.classGoals}
            maxLength={255}
            mandatory
            warning={isClassGoalsFieldHighlighted}
          />
        </div>
        <div className={classes.strategyBoxContainer}>
          <Text2 fontSize='xs' fontWeight='bold' neutral='dark20'>
            {t('Escolha uma estratégia de aprendizagem')}
          </Text2>

          <div className={classes.strategyContainer}>
            {
              AVAILABLE_STRATEGIES.map(({ id, title }) => {
                const isSelected = formData?.learningStrategies?.map(el => el.id).includes(id)

                return (
                  <Tag
                    key={`strategy-${id}-${title}`}
                    className={`${classes.strategy} ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleSelectStrategy({ id, title })}
                    dataTestid='cloe_super_create_lesson_plan_step2_select_learning_strategy'
                  >
                    {title}
                  </Tag>
                )
              })
            }
          </div>
        </div>

      </div>
    </div>
  )
}
