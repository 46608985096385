import * as React from 'react'

export const RenderWhenCondition: React.FC<{ condition: boolean }> = ({ condition, children }) => {
  if (condition) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  } else {
    return <React.Fragment />
  }
}

export default RenderWhenCondition
