import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  carouselContainer: {
    '& ul.alice-carousel__stage': {
      display: 'flex',
      gap: theme.spacingInline.xxxs
    }
  },
  prevButton: {
    left: 0
  },
  nextButton: {
    right: 0
  },
  navigationCarouselButttons: {
    width: '32px',
    height: '32px',
    position: 'absolute !important' as 'absolute',
    zIndex: 2,
    backgroundColor: `${theme.colors.neutral.lightBase} !important`,
    color: theme.colorBrand.medium,
    border: `1px solid ${theme.colorBrand.medium} !important`,

    '& svg': {
      fontSize: `${theme.font.fontSize.sm} !important`
    }
  },
  cardsFadeBackground: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      position: 'absolute',
      width: '70px',
      height: '100%',
      bottom: 0
    }
  },
  cardsFadeBackgroundLeft: {
    [theme.breakpoints.up('sm')]: {
      left: `-${theme.spacingStack.xxs}`,
      background: 'linear-gradient(90deg, #FFF 31.5%, rgba(255, 255, 255, 0.00) 100%)'
    }
  },
  cardsFadeBackgroundRight: {
    [theme.breakpoints.up('sm')]: {
      right: `-${theme.spacingStack.xxs}`,
      background: 'linear-gradient(270deg, #FFF 31.5%, rgba(255, 255, 255, 0.00) 100%)'
    }
  },
  exploreCardsContainer: {
    display: 'flex',
    gap: theme.spacingInline.xxs,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center'
  }
}))
