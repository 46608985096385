import { useTranslation } from 'react-i18next'

import useStyles from './style'
import { Text2, Avatar } from 'components/design-system'
import { IActivityResponse } from 'services/types'

interface IActivityDescription {
  activity?: IActivityResponse | null
}

export const ActivityDescription = ({ activity }: IActivityDescription) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const scheduleOwner = activity?.schedule?.user_school_profile?.user
  const fullName = `${scheduleOwner?.name ?? 'Usuário'} ${scheduleOwner?.surname ?? 'Professor'}`

  if (!activity?.schedule?.content) return <></>

  return (
    <div className={classes.scheduleDescription}>
      <div className={classes.teacherDescriptionInfo}>
        <Avatar userName={fullName} sx={{ width: 24, height: 24 }} />
        <Text2
          fontSize='xs'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='xs'
          neutral='dark20'
        >
          {fullName}
        </Text2>
      </div>

      {
        activity?.schedule?.content
          ? (
            <Text2
              fontSize='xs'
              fontWeight='medium'
              lineHeight='xs'
              mobile='xs'
              neutral='dark30'
              className={classes.descriptionField}
            >
              {activity?.schedule?.content}
            </Text2>
          )
          : (
            <span className={classes.noDescriptionText}>{t('(Sem descrição)')}</span>
          )
      }
    </div>
  )
}
