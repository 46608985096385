import { IApiResponse } from 'services/types'
import { ARTICLES_LIBRARY_BY_ID, ARTICLES_LIBRARY_SEARCH, COLLECTIONS_CLOE_LIBRARY, COLLECTIONS_CLOE_LIBRARY_BY_ID, COLLECTIONS_LIBRARY, COLLECTIONS_LIBRARY_BY_ID, COLLECTIONS_LIBRARY_SEARCH, RESOURCES_LIBRARY_BY_ID, RESOURCES_LIBRARY_SEARCH } from './CONSTANTS'
import { request } from 'services/base'
import { ISearchRequest, ISearchResponse, IResourceResponse, IArticleResponse, ICollectionResponse, ICollectionRequest, ICollectionCreatedRespone } from 'services/types/library'

export const getResource = async (id: number): Promise<IApiResponse<IResourceResponse>> =>
  await request({ url: RESOURCES_LIBRARY_BY_ID(id) })

export const searchResourceLibrary = async (data: ISearchRequest): Promise<IApiResponse<ISearchResponse<IResourceResponse>>> =>
  await request({
    url: RESOURCES_LIBRARY_SEARCH,
    method: 'POST',
    data: data
  })

export const getArticle = async (id: number): Promise<IApiResponse<IArticleResponse>> =>
  await request({ url: ARTICLES_LIBRARY_BY_ID(id) })

export const searchArticleLibrary = async (data: ISearchRequest): Promise<IApiResponse<ISearchResponse<IArticleResponse>>> =>
  await request({
    url: ARTICLES_LIBRARY_SEARCH,
    method: 'POST',
    data: data
  })

export const getCollections = async (): Promise<IApiResponse<ISearchResponse<ICollectionResponse>>> =>
  await request({
    url: COLLECTIONS_LIBRARY,
    method: 'GET'
  })

export const getCollectionById = async (id: string): Promise<IApiResponse<ICollectionResponse>> =>
  await request({
    url: COLLECTIONS_LIBRARY_BY_ID(id),
    method: 'GET'
  })

export const getCollectionsCloe = async (gradeTypes?: number[]): Promise<IApiResponse<ISearchResponse<ICollectionResponse>>> => {
  let segmentIds = ''
  gradeTypes?.forEach((g, i) => {
    segmentIds += i === 0 ? `${g}` : `,${g}`
  })
  return await request({
    url: COLLECTIONS_CLOE_LIBRARY,
    method: 'GET',
    params: {
      gradeTypes: segmentIds
    }
  })
}

export const getCollectionCloeById = async (id: string): Promise<IApiResponse<ICollectionResponse>> =>
  await request({
    url: COLLECTIONS_CLOE_LIBRARY_BY_ID(id),
    method: 'GET'
  })

export const searchCollectionLibrary = async (data: ISearchRequest): Promise<IApiResponse<ISearchResponse<ICollectionResponse>>> =>
  await request({
    url: COLLECTIONS_LIBRARY_SEARCH,
    method: 'POST',
    data: data
  })

export const postCollectionLibrary = async (data: ICollectionRequest): Promise<IApiResponse<ICollectionCreatedRespone>> =>
  await request({
    url: COLLECTIONS_LIBRARY,
    method: 'POST',
    data: data
  })

export const patchCollectionLibrary = async (id: string, data: ICollectionRequest): Promise<IApiResponse<ICollectionCreatedRespone>> =>
  await request({
    url: COLLECTIONS_LIBRARY_BY_ID(id),
    method: 'PATCH',
    data: data
  })
