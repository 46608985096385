import { getAnswerOffline } from './getAnswerOffline'
import { IAnswerRecordRequest } from 'services/types'
import { makeDatabaseInstanceOffline, IAnswerCachedStatus } from 'contentCacheManager'
import { updateAnswerOffline } from './updateAnswerOffline'
import { getProfile } from 'contentCacheManager/utils'

export const addAnswerOffline = async (params: IAnswerRecordRequest & { isEditing: boolean }) => {
  const database = makeDatabaseInstanceOffline()
  const found = await getAnswerOffline(params)
  const { id: profileId } = getProfile()

  if (!found) {
    await database.answersCached.add({
      requestAnswer: params,
      filesOffline: [],
      status: IAnswerCachedStatus.INITIAL,
      userId: String(profileId),
      isEditing: params.isEditing
    })
    return await getAnswerOffline(params)
  }

  await updateAnswerOffline(params, {
    requestAnswer: {
      ...params,
      answers: params.answers
    },
    isEditing: params.isEditing,
    status: IAnswerCachedStatus.INITIAL

  })
  return await getAnswerOffline(params)
}
